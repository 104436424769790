import { minutesBetween } from "./DateTimeUtil";

export const calculateHoursWorking = (hours: Date[]) => {

	if(hours.length > 0 && hours.length %2 === 0){
		let minutes = 0;
		for (let index = 0; index <= hours.length/2; index+=2) {
			minutes += minutesBetween(hours[index], hours[index+1]);
			
		}
		return minutes;
	}else{
		return 0;
	}
}