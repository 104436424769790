import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, PageTitle, Row, Table, Tag, Time, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import { convertMinutesToTimeStr, formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { PointRegistrationCalculate } from '../../../../models/PointRegistrationCalculate';
import { postMassUpdate } from '../../../../services/pointRegistrations.services';
import TimeOrdering from '../../../molecules/TimeOrdering/TimeOrdering';

import styles from './Edit.module.scss';
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';
import { colors } from '@optimuminterfaces/revex-react-components/dist/utils/ColorUtil';
import { translateType } from '../../../../utils/PointIncidentUtil';

import moment from 'moment';
import { calculateHoursWorking } from '../../../../utils/PointRegisterUtil';


interface EditProps{
	employeeId: string,
	month: string,
	year: string,
	pointRegistrations: PointRegistrationCalculate[],
	pointRegistrationsLastDayInLastPeriod: any[],
	pointRegistrationsFirstDayInNextPeriod: any[],
	showTitle?: boolean,
	fixedActions?: boolean,
	handleClose?: Function,
	handleChange?: Function,
	Container?: any
}

interface PointModel {
	id?: string, 
	time: string, 
	deleted: boolean,
	change: boolean,
	pointOrder: number,
	datePoint: string,
	referenceDate: string,
	idx?: string
}

const Edit = ({
	employeeId, 
	month, 
	year, 
	pointRegistrations,
	pointRegistrationsLastDayInLastPeriod,
	pointRegistrationsFirstDayInNextPeriod,
	showTitle=false, 
	fixedActions= false, 
	handleClose, 
	handleChange,
	Container='div'
}: EditProps) => {

	let navigate = useNavigate();

	const [points, setPoints] = useState<{date: string, datePointWeekDay: string, points:PointModel[], scale: any[], pointsRaw: any[], haveIncidentsOpen: boolean}[]>();
	const [loadingButtonSavePoint, setLoadingButtonSavePoint] = useState<boolean>(false);
	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false})
	const [showConfirmationClose, setShowConfirmationClose] = useState<boolean>(false);
	useEffect(() => {

		let pointsBuilder: {date: string, datePointWeekDay: string, points:PointModel[], scale: any[], pointsRaw: any[],haveIncidentsOpen:boolean,pointIncident: any|null}[] = [];

		//Adiciona marcações do último dia do ciclo anterior
		let dayPoints:PointModel[] = [];
		pointRegistrationsLastDayInLastPeriod?.filter((p:any) => p.isDeleted==false).forEach( (p:any) => {
			dayPoints.push({
				id: p.id, 
				time: p.time, 
				deleted: false, 
				change: false, 
				pointOrder: 
				p.pointOrder, 
				idx: p.id, 
				datePoint: p.datePoint, 
				referenceDate: p.referenceDate});
		});
		pointsBuilder.push({
			date: moment(pointRegistrations[0].datePoint).subtract(1, 'days').format(), 
			points: dayPoints, 
			datePointWeekDay:'', 
			scale: [], 
			pointsRaw:pointRegistrationsLastDayInLastPeriod, 
			haveIncidentsOpen: false, 
			pointIncident: null
		});

		pointRegistrations.forEach(pointRegistration => {
			let dayPoints:PointModel[] = [];
            const validPoints = pointRegistration.points?.filter(p => p.isDeleted===false);
            //Adiciona pontos já existe em banco
			validPoints.forEach( p => {
				dayPoints.push({
					id: p.id, 
					time: p.time, 
					deleted: false, 
					change: false, 
					pointOrder: 
					p.pointOrder, 
					idx: p.id, 
					datePoint: p.datePoint, 
					referenceDate: p.referenceDate});
			});
            //Adiciona campos para serem preenchidos para completar a jornada planejada
            for (let index = validPoints.length; index < pointRegistration.scales.times?.length; index++) {
                dayPoints.push({
					time:'', 
					deleted: false, 
					change: true, 
					pointOrder: index, 
					idx: (Math.random() + 1).toString(36).substring(7), 
					datePoint:validPoints[0] !== undefined? validPoints[0].datePoint: pointRegistration.datePoint, 
					referenceDate: validPoints[0] !== undefined? validPoints[0].referenceDate: pointRegistration.datePoint
				});
            }

			pointsBuilder.push({
				date: pointRegistration.datePoint, 
				datePointWeekDay: pointRegistration.datePointWeekDay,
				points: dayPoints, 
				scale: pointRegistration.scales.times, 
				pointsRaw:  validPoints,
				haveIncidentsOpen: pointRegistration.pointIncidents? pointRegistration.pointIncidents?.filter(incidents => incidents.wasSolved !== true).length > 0 : false,
				pointIncident: pointRegistration.pointIncidents? pointRegistration.pointIncidents?.filter(incidents => incidents.wasSolved !== true)[0] : null,
			});
		});

		//Adiciona marcações do primeiro dia do próximo período
		dayPoints = [];
		pointRegistrationsFirstDayInNextPeriod?.filter((p:any) => p.isDeleted==false).forEach( (p:any) => {
			dayPoints.push({
				id: p.id, 
				time: p.time, 
				deleted: false, 
				change: false, 
				pointOrder: 
				p.pointOrder, 
				idx: p.id, 
				datePoint: p.datePoint, 
				referenceDate: p.referenceDate});
		});
		pointsBuilder.push({
			date: moment(pointRegistrations[pointRegistrations.length-1].datePoint).add(1, 'days').format(), 
			points: dayPoints,
			datePointWeekDay:'', 
			scale: [], 
			pointsRaw: pointRegistrationsFirstDayInNextPeriod, 
			haveIncidentsOpen: false, 
			pointIncident: null
		});

		setPoints(pointsBuilder);

	},[pointRegistrations]);

	const addPoint = (index: number) => {
		console.log('add '+index);
		let pointsState = [...points!];
		let pointOrder = pointsState[index].points?.length;
		if(pointOrder=== undefined || pointOrder< 0){
			pointOrder = 0;
		}
		pointsState[index].points?.push({
			time:'', 
			deleted: false, 
			change: true, 
			pointOrder: pointOrder, 
			idx: (Math.random() + 1).toString(36).substring(7), 
			datePoint: pointsState[index].date, 
			referenceDate: pointsState[index].date
		});

		setPoints([...pointsState]);
	}

	const changePoint = (event: any, index: number) =>{
		const eventName = event.name;
		const eventValue = event.value;
		
		let pointsState = [...points!];
		if(eventName === 'element remove'){
			if(pointsState[index].points.indexOf(eventValue)){
				if(!pointsState[index].points[eventValue].id){
					pointsState[index].points?.splice(eventValue, 1);
				}else{
					//pointsState[index].points?.splice(eventValue, 1);
					pointsState[index].points?.splice(eventValue,1,{...pointsState[index].points[eventValue], deleted: true});
				}
				
				setPoints([...pointsState]);
			}
		}
		if(eventName === 'time change'){
			if(pointsState[index].points.indexOf(eventValue.order)){
				pointsState[index].points?.splice(eventValue.order,1,{...pointsState[index].points[eventValue.order], time: eventValue.time});
				setPoints([...pointsState]);
			}
			
		}
		if(eventName ==='changeReferenceDate'){
			if(pointsState[index].points.indexOf(eventValue.order)){
				//Pega ponto
				var point = (pointsState[index].points[eventValue.order]);
				point.change=true;

				//remove ponto do dia corrent
				pointsState[index].points?.splice(eventValue.order,1);

				if(eventValue.type==='UP'){
					//Busca pontos do dia para saber a ordem
					point.pointOrder=(pointsState[(index+1)].points?.length);
					point.referenceDate= moment(point.referenceDate).add(1, 'days').format('YYYY-MM-DD');
					pointsState[(index+1)].points?.push(point);
				}else{
					//Busca pontos do dia para saber a ordem
					point.pointOrder=(pointsState[(index-1)].points?.length);
					point.referenceDate= moment(point.referenceDate).subtract(1, 'days').format('YYYY-MM-DD');
					pointsState[(index-1)].points?.push(point);
				}
				setPoints([...pointsState]);
			}
		}
	};

	const handleOrder = (event:any, index:number) => {
		let pointsState = [...points!];
		let newPointOrder: PointModel[] = []
		pointsState[index].points.forEach(point => {
			event.value.forEach((v:string, idx: number) => {
				let [id,time] = v.split('|');
				if(time === point.time && id === point.idx){
					if(point.pointOrder !== idx){
						point.pointOrder = idx;
						point.change= true;
					}
					newPointOrder.push(point);
				}
			});
		});

		pointsState[index].points.sort((point1, point2) => {
			if(point1.pointOrder < point2.pointOrder){
				return -1;
			}else{
				return 1;
			}
		})

		//pointsState[index].points = newPointOrder;
		setPoints([...pointsState]);
	}

	const savePointEdit = async() => {
		setLoadingButtonSavePoint(true);
		let pointSave: { id?: string, datePoint: string, time: string, pointOrder: number, isDeleted: boolean, referenceDate: string }[] = [];

		points?.forEach(p => {
			let pointOrder = 0;
			let date = formatLocalDate(p.date,false, 'YYYY-MM-DD', undefined);
			p.points?.forEach((day, index) => {
				if(day.deleted || (day.change && day.time !== '')){
					console.log(day, p.date);
					pointSave.push({id: day.id, 
									datePoint: day.datePoint!==''?day.datePoint: date, 
									time: day.time, 
									pointOrder: pointOrder, 
									isDeleted: day.deleted, 
									referenceDate: day.referenceDate !== ''?day.referenceDate: date
								});
				}
				if(!day.deleted){
					pointOrder++;
				}
			})

			
		});
		if(pointSave){
			const jsonReturned =  await postMassUpdate(pointSave, { employeeId, month, year });
			if(jsonReturned){
				if(jsonReturned.status==='ERROR'){
					setToastAlert({...toastAlert, danger: true, message:jsonReturned.message,show: true});
				}else{
					setToastAlert({...toastAlert, danger: false, message:jsonReturned.message,show: true});

					setTimeout(() => { 
						if(handleChange){
							handleChange({name:'operation success'})
						}

						if(handleClose){
							handleClose()
						}else{
							navigate('/srepsMF/pointRegistrations')
						}
					}, 3000);
				}
				//setLoadingButtonSavePoint(false);
			}
		}
		//console.log(pointSave);
		//setLoadingButtonSavePoint(false);
	}

	const renderStatus = (point: any) => {

		const dateIsBefore = new Date(point.date) < new Date();
		const isOk = point.pointsRaw.length === point.scale.length && !point.haveIncidentsOpen;
		return (
			<>
				
				{(dateIsBefore && isOk) && <FontAwesomeIcon icon='check-circle' color={colors['green-color-light']} size='lg' />}
				{(dateIsBefore && !isOk) && <>
						<FontAwesomeIcon icon='exclamation-circle' color={colors['yellow-color-light']} size='lg' /> 
				</>}
			</>
		);
	}

	const renderStatusMessage = (point: any) => {
		const dateIsBefore = new Date(point.date) < new Date();
		const isOk = point.pointsRaw.length === point.scale.length && !point.haveIncidentsOpen;
		return (
			<>
				{(dateIsBefore && !isOk) && <>
				
					
					{point.pointsRaw.length !== point.scale.length?<> <Tag title='Marcações diferentes do planejado' warn /> <br/></>:''}
					{point.haveIncidentsOpen? <Tag title={translateType(point.pointIncident.pointIncidentType,
																	point.pointIncident.actualPointsNumber,
																	point.pointIncident.expectedPointsNumber,)} 
													warn  />:''}
					
				</>}
			</>
		);
	}

	const calculateRawHours = (points: any) => {
		//console.log(points, points[0].datePoint, points[0].time, new Date(points[0].datePoint+'T'+points[0].time));
		if(points.length > 0){
			let dates:Date[] = [];
			dates = points.filter((d:any) => d.time !=='' && !d.deleted).map((d:any) => new Date(d.referenceDate+'T'+d.time));
			console.log(dates);
			return calculateHoursWorking(dates);
		}else{
			return 0;
		}

	}

	const renderTablePointEdit = () => {
		return(
			<Table className={styles['table-edit-points']} striped primary>
				<Table.Header>
                    <Table.HeaderItem>
                    	<Table.HeaderItem.Item colSpan={6}>
                            <Container className={` ${styles['point-edit-actions']} ${fixedActions ? styles['fixed'] : ''}`}>
                                {!showConfirmationClose &&
                                    <>
                                        <Button lg
                                            danger
                                            title={t('CABLE.POINT_REGISTRATION.TEXT.CLOSE')}
                                            icon="undo"
                                            outlined
                                            className={styles['point-edit-action']}
                                            action={() => { setShowConfirmationClose(true) }} />
                                        <Button success lg
                                            outlined
                                            title={t('GENERIC.BUTTON.SAVE.TEXT')}
                                            icon="save"
                                            className={styles['point-edit-action']}
                                            loading={loadingButtonSavePoint}
                                            action={() => { savePointEdit() }} />
                                    </>
                                }
                                {showConfirmationClose &&
                                    <Container>
                                        {t('CABLE.POINT_REGISTRATION.TEXT.CLOSE_WHITOUT_SAVE')}
                                        <Container>
                                            <Button primary title={t('GENERIC.TEXT.YES')} action={() => { handleClose!() }} />
                                            <Button title={t('GENERIC.TEXT.NO')} action={() => { setShowConfirmationClose(false) }} />
                                        </Container>
                                    </Container>
                                }
                            </Container>
                        </Table.HeaderItem.Item>
                    </Table.HeaderItem>
					<Table.HeaderItem>
						<Table.HeaderItem.Item>{t('GENERIC.TEXT.DATE')}</Table.HeaderItem.Item>
						<Table.HeaderItem.Item>{t('GENERIC.TEXT.STATUS')}</Table.HeaderItem.Item>
						<Table.HeaderItem.Item>{t('CABLE.POINT_REGISTRATION.TEXT.OBSERVATION')}</Table.HeaderItem.Item>
						<Table.HeaderItem.Item>{t('CABLE.POINT_REGISTRATION.TEXT.RECORDS')}</Table.HeaderItem.Item>
						<Table.HeaderItem.Item>{t('CABLE.POINT_REGISTRATION.TEXT.RAW_HOURS')}</Table.HeaderItem.Item>
						<Table.HeaderItem.Item>{t('CABLE.POINT_REGISTRATION.TEXT.DELETEDS')}</Table.HeaderItem.Item>
					</Table.HeaderItem>
				</Table.Header>
				<Table.Body>
					{points?.map((point,index) => (
						<Table.BodyItem key={point.date+index}>
							<Table.BodyItem.Item className={styles['date']}>
                                <div>{formatLocalDate(point.date, true, 'YYYY-MM-DD', 'DD/MM/YY')}</div>
								<div>{point.datePointWeekDay.toUpperCase()}</div>
							</Table.BodyItem.Item>
							<Table.BodyItem.Item className={styles['status']}>
								{renderStatus(point)}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item className={styles['observations']}>
								{renderStatusMessage(point)}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item key={index+'points'}>
								<TimeOrdering horizontal handleOrder={(e:any)=>{handleOrder(e, index)}}>
								{point.points?.map((day, indexPoint) => (
									!day.deleted &&<TimeOrdering.Item key={point.date+day.time} time={day.time} 
														disabled={day.id !== undefined}  
														order={indexPoint}
														idx = {day.idx!}
														referenceDate={day.referenceDate}
														date={day.datePoint}
														deleted
														changeReference
														handleChange={(event: any) => changePoint(event, index)}/>
								))}
								</TimeOrdering>
								<Container className={styles['point-edit-action']}>
									<Button className={styles['add-point']} primary icon='plus' action ={ () => {addPoint(index)}} />
								</Container>
							</Table.BodyItem.Item>
							<Table.BodyItem.Item key={index+'points-hours'}>
									{convertMinutesToTimeStr(calculateRawHours(point.points))}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item key={index+'points-deleted'}>
								{point.points?.filter(d => d.deleted===true).map((day, indexPoint) => (
									<Time time={`${day.time} - D`} danger key={point.date+day.time+'D'}/>
								))}
							</Table.BodyItem.Item>
						</Table.BodyItem>
					))}
				</Table.Body>
			</Table>
		);
	}
	return(
		<Container className={styles['point-registration-edit']}>
			{showTitle && 
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<PageTitle.Small title={t('CABLE.POINT_REGISTRATION.TEXT.RECORDS_EDITION')} />
					</Row.Item>
				</Row>
			}

			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					{renderTablePointEdit()}
				</Row.Item>
			</Row>
			
			{/* {showModalConfirmationClose && 
					<Modal sm title='Confirmação' >
						<Container>
							Tem certeza que deseja fechar sem salvar?
							<Container>
								<Button primary title="Sim" action={() => {handleClose!()}} />
								<Button title="Não" action={() => {handleClose!()}} />
							</Container>
							
						</Container>
					</Modal>
			} */}
			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION')}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}
		</Container>
	);
}

export default Edit;