import { Button, Input, Row, Select, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import React, { useState } from 'react';
import { postReport } from '../../../../services/Report.services';
import { calculateSelectMonth, calculateSelectYear } from '../../../../utils/SelectUtil';

import styles from './New.module.scss';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

interface NewProps{
	Container?: any,
	cancelAction?: Function
	saveBeforeAction?: Function
}
const New = ({Container='div', cancelAction, saveBeforeAction}: NewProps) => {
	const years = calculateSelectYear();
	const months = calculateSelectMonth();

	let currentMonth = months.filter(m => m.selected==true)[0];

	const [name, setName] = useState<string>('');
	const [listYears, setListYears] = useState<any[]>(years);
	const [listMonths, setListMonths] = useState<any[]>(months);
	const [monthValue, setMonthValue] = useState<any>(currentMonth);
	const [yearValue, setYearValue] = useState<any>(years[3]);
	const [loadingButtonSave, setLoadingButtonSave] = useState(false);

	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false})


	const changeSelectMonth = (key: string) => {
        let months = [...listMonths];

        for (var i = 0; i < months.length; i++) {
            if (months[i].key === key) {
                months[i].selected = true;
                setMonthValue(months[i]);
            } else {
                months[i].selected = false;
            }
        }

        setListMonths(months);

    }

	const changeSelectYear = (key: string) => {
        let years = [...listYears];

        for (var i = 0; i < years.length; i++) {
            if (years[i].key === key) {
                years[i].selected = true;
                setYearValue(years[i]);
            } else {
                years[i].selected = false;
            }
        }

        setListYears(years);

    }

	const saveReport = async () => {
		console.log('valida', name)
        if(!name || name.trim()===''){
			setToastAlert({...toastAlert, danger: true, message:t('CABLE.AREA.NEW.INFORM_NAME'),show: true});
			return;
		}
		if(!yearValue){
			setToastAlert({...toastAlert, danger: true, message:t('CABLE.REPORT.TEXT.YEAR_INFORMED'),show: true});
			return;
		}
		if(!monthValue){
			setToastAlert({...toastAlert, danger: true, message:t('CABLE.REPORT.TEXT.PERIOD_INFORMED'),show: true});
			return;
		}
		setLoadingButtonSave(true);

		try{
			const jsonReturned =  await postReport({name: name, 
													year: yearValue.key,
													month: monthValue.key,
													type: 'point_events'});
			if(jsonReturned){
				if(jsonReturned.status==='ERROR'){
					setToastAlert({...toastAlert, danger: true, message:jsonReturned.message,show: true});
					setLoadingButtonSave(false);
				}else{
					setToastAlert({...toastAlert, danger: false, message:jsonReturned.message,show: true});
					setTimeout(() => { 
						setLoadingButtonSave(false);
						if(typeof saveBeforeAction === 'function'){
							saveBeforeAction();
						}
					}, 3000);
				}
			}
		}catch(error){
			console.log(error);
		}
        
    }

	return(
		<Container className={styles['report-new-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Input type="text" fullWidth
							title={t('CABLE.REPORT.TEXT.REPORT_NAME')}
							name="reportName"
							value={name}
							handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {setName(event.target.value)}} /> 
				</Row.Item>
				<Row.Item sm={6} md={6} lg={6}>
					<Select medium 
							fullWidth 
							name="selectMonth" 
							value={monthValue.value} 
							title={t('GENERIC.TEXT.PERIOD')}
							options={listMonths} 
							handleChange={changeSelectMonth} />
				</Row.Item>
				<Row.Item sm={6} md={6} lg={6}>
					<Select medium 
							fullWidth 
							name="selectYear" 
							value={yearValue.value} 
							title={t('GENERIC.TEXT.YEAR')}
							options={listYears} 
							handleChange={changeSelectYear} />
				</Row.Item>
			</Row>

			<Container className={styles['actions']}>
					<Button md
							title={t('GENERIC.BUTTON.CANCEL.TEXT')}
							icon="times"
							className={styles['action']}
							action={(e) => { 
								e.preventDefault();
								if(typeof cancelAction === 'function'){
									cancelAction();	
								}
							}} />
					<Button primary md
							title={t('GENERIC.BUTTON.SAVE.TEXT')}
							icon="save"
							loading={loadingButtonSave}
							className={styles['action']}
							action={() => saveReport()} />
					
			</Container>

			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION')}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}

		</Container>
		
	)
}

export default New;