import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './NewEdit.module.scss';
import { uuidv4 } from '@optimuminterfaces/revex-react-components/dist/utils/DeveloperUtil';
import { fetchAllPointEventTypes, fetchPointEvent, postPointEvent, putPointEvent } from '../../../../services/pointEvent.services';

import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index';
import { faWifiSlash as fadWifiSlash } from '@fortawesome/pro-duotone-svg-icons';

import { PointEvent as PointEventModel } from '../../../../models/PointEvent';
import { Loading, PageTitle, Panel, Row, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import PointEvent from '../../../organism/PointEvent/PointEvent';

interface NewEditProps{
    Container?: any,
    className?: string, 
    pointEventId?: string, 
    backUrl?: string,  
}

const NewEdit = ({className, 
                    pointEventId, 
                    backUrl='/srepsMF/pointEvents/',
                    Container='div'}: NewEditProps) => {

    let navigate = useNavigate();

    const [pointEventTypes, setPointEventTypes] = useState();
    const [pointEvent, setPointEvent] = useState<PointEventModel>();
    const [status, setStatus] = useState('waiting');
    const [loading, setLoading] = useState<{pointEventFetch: boolean, pointEventTypeFetch: boolean, pointEventSave: boolean}>
                                ({ pointEventFetch: false, pointEventTypeFetch: true, pointEventSave: false });
    const [toastData, setToastData] = useState<any>([]);
    const [formErrors, setFormErrors] = useState(null);

    //Parametros da URL
	const [params, setParams] = useState(useParams());

    const internalAddToast = (toastDataParam:any, handleCloseCallback?:Function) => {
        toastDataParam.key = `toast-${uuidv4()}`;
        toastDataParam.closeChange = () => {
            setToastData((toastData: any[]) => toastData.filter(({ key }) => (key !== toastDataParam.key)));
            handleCloseCallback?.();
        };

        setToastData((toastData: any) => [ ...toastData, toastDataParam ]);
    };

    const loadPointEventTypes = () => {

        (async () => {
            setLoading({...loading, pointEventTypeFetch: true});

            try{
                const jsonReturned =  await fetchAllPointEventTypes();
                if(jsonReturned){
                        setPointEventTypes(jsonReturned.pointEventTypes);
                        setLoading({...loading, pointEventTypeFetch: false});

                }else{
                    setStatus('error');
                    setLoading({...loading, pointEventTypeFetch: false});
                }
            }catch(error){
                console.log(error);
                setStatus('error');
            }
        })();

    }
    const loadPointEvent = (id:string) => {
        if(id){
            (async () => {
                setLoading({...loading, pointEventFetch: true});
                setStatus('waiting');

                try{
                    const jsonReturned =  await fetchPointEvent(id);
                    if(jsonReturned){
                            let event = {...jsonReturned};
                            setPointEvent(event.pointEvent);
                            setStatus('success');
                            setLoading({...loading, pointEventFetch: false});

                    }else{
                        setStatus('error');
                        setLoading({...loading, pointEventFetch: false});
                    }
                }catch(error){
                    console.log(error);
                    setStatus('error');
                }
            })();
        }else{
            setStatus('success');
        }
    }


    useEffect(() => {
        // load the scaleName e holiday data
        loadPointEventTypes();
        loadPointEvent(pointEventId?pointEventId!:params.id!);

    }, [pointEventId, params]);

    const buildPageTitleText = (pointEventId?: string) => {

        return `${t((!!pointEventId ? 'GENERIC.TEXT.EDIT' : 'GENERIC.TEXT.NEW'))} ${t('CABLE.POINT_EVENT.NEW.TITLE')}`
    };

    const back = () => {
        console.log('backkkkkkk')
        if(!!backUrl){
            navigate(backUrl);
        }
    };


    const internalHandleChanges = ({ name, value }:{name: string, value: any}) => {
        console.log(name);
        switch(name){

            case 'PointEvent.cancel':
                {
                    back();
                }
                break;

            case 'PointEvent.save':
                {
                    setFormErrors(null);
                    setLoading({ ... loading, pointEventFetch: false, pointEventSave: true });

                    const callbackSuccess = (response: any) => {
                        console.log(response)
                        if(response !== null && response.status === 'SUCCESS'){
                            internalAddToast({
                                show: true,
                                title: t('COMPONENTS.TOAST.TITLE.DATA_CHANGES_SAVE_SUCCESS'),
                                icon: 'save',
                                message: t('CABLE.TOAST.SUCCESS_POINT_EVENT_CHANGE', undefined, response.message),
                                success: true,
                                positionTopRight: true,
                                showTime: 5000
                            }, () => back());
                        }else{

                            internalAddToast({
                                show: true,
                                title: t('COMPONENTS.TOAST.TITLE.SERVER_INTERNAL_ERROR'),
                                icon: 'exclamation-circle',
                                message: t('CABLE.TOAST.ERROR_POINT_EVENT_UPDATE'),
                                warning: true,
                                positionTopRight: true,
                                showTime: 5000
                            });

                            if(!!response?.data.data?.errors){
                                //callbackErrors(response.data.data?.errors)
                                setFormErrors(response.data.data.errors);
                            }
                            setLoading({...loading, pointEventFetch: false, pointEventSave: false });
                        }
                    };

                    const callbackErrors = (errors: any) => {
                        console.log(errors);
                        internalAddToast({
                            show: true,
                            title: t('COMPONENTS.TOAST.TITLE.SERVER_INTERNAL_ERROR'),
                            icon: 'exclamation-circle',
                            message: t('CABLE.TOAST.ERROR_POINT_EVENT_UPDATE'),
                            warning: true,
                            positionTopRight: true,
                            showTime: 5000
                        });
                        setLoading({ ...loading, pointEventFetch: false, pointEventSave: false });
                    };

                    // save/update the user 
                    value = {...value}

                    console.log(value)

                    if(value.id){
                        putPointEvent(value.id, value).then(callbackSuccess, callbackErrors);
                    }else{
                        postPointEvent(value).then(callbackSuccess, callbackErrors);
                    }
                }

                break;

        }
    }; 

    return (
        <Container className={styles['point-event-new-edit-rt']}>
            <PageTitle.Small title={buildPageTitleText(pointEventId)}/>
            {status === 'waiting' &&
                <Row>
                    <Row.Item lg={12} md={12} sm={12}>
                        <Panel>
                            <Loading text={t('GENERIC.TEXT.LOADING')}/>
                        </Panel>
                    </Row.Item>
                </Row>
            }
            {(status === 'error') &&
                <Row>
                    <Row.Item lg={12} md={12} sm={12}>
                        <Panel>
                            <TextInformation
                                icon='exclamation-circle'
                                text={t('GENERIC.TEXT.UNABLE_RETURN')} />
                        </Panel>
                    </Row.Item>
                </Row>
            }
            {(status === 'success' && !loading.pointEventFetch) &&
                <Panel>
                    <PointEvent className='teste' 
                            pointEventRaw={pointEvent?pointEvent:undefined}
                            loading={loading.pointEventSave}
                            errors={formErrors}
                            pointEventTypesRaw={pointEventTypes}
                            buttonSaveText={pointEvent && pointEvent.id? t('GENERIC.BUTTON.UPDATE.TEXT'): t('GENERIC.BUTTON.SAVE.TEXT')}
                            handleChanges={internalHandleChanges}/>
                    
                </Panel>
            }

            {toastData?.map?.((toastDataE:any) => (
                <Toast {...toastDataE} />
            ))}
        </Container>
    );
}

export default NewEdit;