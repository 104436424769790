import React from 'react';
import { HashHistory } from 'history';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/templates/App/App';

declare global {
    interface Window { 
        renderCable: any;
        unmountCable: any;
    }
}

window.renderCable = (containerId: string, history: HashHistory, store: any): Boolean => {
    ReactDOM.render(
        <App history={history} />,
        document.getElementById(containerId),
    );
    return true
};

window.unmountCable = (containerId: string):Boolean => {
    const domElement =  document.getElementById(containerId) as Element;
    ReactDOM.unmountComponentAtNode(domElement);
    return true
};

if (document.getElementById('cable-container') == null) {
    ReactDOM.render(
        <React.StrictMode>
            <App showMenu/>
        </React.StrictMode>,
        document.getElementById('root')
    );
}
