import React, { useEffect, useState } from 'react';

import { AlertTiny, AvatarName, Button, Input, Loading, Message, Modal, PageTitle, Panel, Row, Select, TextInformation, Time, Toast } from '@optimuminterfaces/revex-react-components/dist/components';

// Internal components
import TimeOrdering from "../../../molecules/TimeOrdering/TimeOrdering";
import DailyScaleHours from "../../../molecules/DailyScaleHours/DailyScaleHours";

import { PointIncident } from '../../../../models/PointIncident';
import { useNavigate } from 'react-router-dom';
import { calculateSelectMonth, calculateSelectYear } from '../../../../utils/SelectUtil';
import { fetchAllPointIncidents, fetchExportFileCSV, postPointIncidentApproveOrReject } from '../../../../services/pointIncident.services';
import TableListPagination from '../../../organism/TableListPagination/TableListPagination';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { translateStatus, translateType } from '../../../../utils/PointIncidentUtil';
import imageUser from '../../../../assets/images/avatar.png';
import { downloadCsv } from '@optimuminterfaces/revex-react-components/dist/utils/FileUtil';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

import styles from './List.module.scss';

interface ListProps{
	Container?: any
};

interface PointModel {
	time: string, 
	pointOrder: number,
	idx: string
};

interface TimeOrderingEditProps {
	className?: string, 
	rawTimes?: string, 
	times: string, 
	handleChanges: Function, 
	Container?: any
};

const generateRandonString = () => {
	return (Math.random() + 1).toString(36).substring(7);
};

const convertToPointModel = (times: string) => {
	if(!!times){
		return times.split('-').map((point: string, index: number) => {
			return ({
				time: point,
				pointOrder: index,
				idx: generateRandonString()
			});
		})
	}

	return [];
};

const convertToDailyScale = (points: PointModel[]) => {
	if(!!points){
		return points.map(({ time }) => (time)).join('-');
	}

	return null;
};

const TimeOrderingEdit = ({ 
	rawTimes,
	times, 
	handleChanges, 
	Container = 'div' 
} : TimeOrderingEditProps ) => {
	const pointsJustification = convertToPointModel(times);

	const pointIncidentJustificationHandleChange = (event: any) => {
        const eventName = event.name;
        const eventValue = event.value;
        if (eventName === 'element remove') {
            let points = [...pointsJustification!];
            if (points.indexOf(eventValue)) {
                points.splice(eventValue, 1);
                // setPointsJustification(points);
				handleChanges?.({ name: 'TimeOrderingEdit.newTimes', value: convertToDailyScale(points) });
            }
        }

        if (eventName === 'time change') {
            let points = [...pointsJustification!];
            points?.splice(eventValue.order, 1, { ...points[eventValue.order], time: eventValue.time });
            // setPointsJustification([...points]);
			handleChanges?.({ name: 'TimeOrderingEdit.newTimes', value: convertToDailyScale(points) });
        }
    };

    const pointIncidentJustificationOrderHandleChange = (event: any) => {
        let pointsState = [...pointsJustification!];

        pointsState.forEach(point => {
            event.value.forEach((v: string, idx: number) => {
                let [id, time] = v.split('|');
                if (time === point.time && id === point.idx) {
                    if (point.pointOrder !== idx) {
                        point.pointOrder = idx;
                    }
                }
            });
        });

        pointsState.sort((point1, point2) => {
            if (point1.pointOrder < point2.pointOrder) {
                return -1;
            } else {
                return 1;
            }
        })

        // setPointsJustification([...pointsState]);
		handleChanges?.({ name: 'TimeOrderingEdit.newTimes', value: convertToDailyScale(pointsState) });
    };

    const renderInputJustification = (defaultValues?: string) => {
        return (
            <>
                <Container className={styles['point-justification-container']} >
                    <Container className={styles['points-justification']}>
                        <TimeOrdering horizontal handleOrder={(e: any) => { pointIncidentJustificationOrderHandleChange(e) }}>
							{pointsJustification?.map((value, index) => {
								return (
									<TimeOrdering.Item key={`${value.idx}${index}`} time={value.time}
										order={index}
										idx={value.idx}
										disabled={!!defaultValues && defaultValues?.includes(value.time) && value.time !== ''}
										deleted={true}
										handleChange={pointIncidentJustificationHandleChange} />
								);
							})}
                        </TimeOrdering>
                    </Container>
                </Container>

                <Container className={styles['add-point-action']}>
                    <Button className={styles['add-point']}
                        title={t('CABLE.POINT_INCIDENT.ADD_RECORD')} 
						style={{ 'marginRight': '15px' }}
						icon='plus'
                        primary 
						md
                        action={(event) => {
							handleChanges?.({
								name: 'TimeOrderingEdit.newTimes',
								value: convertToDailyScale([
									...pointsJustification!,
									{
										time: '',
										pointOrder: pointsJustification!.length,
										idx: generateRandonString()
									}
								])
							});
						}} />
                </Container>
            </>
        );
    };

	return renderInputJustification(rawTimes);
};

const List = ({Container='div'}: ListProps) => {

	let navigate = useNavigate();

	const years = calculateSelectYear();
	const months = calculateSelectMonth();
	const URL_MICRO_FRONT = process.env.REACT_APP_URL;

	let currentDate = new Date()
	
	let currentMonth = months.filter(m => m.selected==true)[0];


	const statuses = [
		{ key: '', value: t('CABLE.POINT_INCIDENT.ALL'), selected: true },
		{ key: 'OPENED', value: t('CABLE.POINT_INCIDENT.AWAITING_JUSTIFICATION'), selected: false },
		{ key: 'WAITING_FOR_REVIEW', value: t('CABLE.POINT_INCIDENT.WAITING_FOR_REVIEW'), selected: false },
		{ key: 'WAITING', value: t('CABLE.POINT_INCIDENT.WAITING_APROOVAL'), selected: false },
		{ key: 'SOLVED_AUTO', value: t('CABLE.POINT_INCIDENT.SOLVED_AUTO'), selected: false },
		{ key: 'SOLVED', value: t('CABLE.POINT_INCIDENT.SOLVED'), selected: false },
		{ key: 'DISAPPROVED', value: t('CABLE.POINT_INCIDENT.REFUSED'), selected: false }
	];

	const [incidents, setIncidents] = useState<PointIncident[]>();
	const [incidentStatus, setIncidentStatus] = useState<string>();
	const [filterIncidentes, setFilterIncidentes] = useState<PointIncident[]>();
	const [yearValue, setYearValue] = useState<string>(currentDate.getFullYear().toString() );
	const [monthValue, setMonthValue] = useState<any>(currentMonth);
	const [statusValue, setStatusValue] = useState<any>(statuses[0]);
    const [listYears, setListYears] = useState(years);
    const [listMonths, setListMonths] = useState(months);
	const [listStatuses, setListStatuses] = useState(statuses);
	const [detailIncident, setDetailIncident] = useState<PointIncident>();
	const [showModalDetail, setShowModalDetail] = useState(false);
	const [showModalConfirmationDisapprove, setShowModalConfirmationDisapprove] = useState<boolean>(false);
	const [loadingButtonModalDetail, setLoadingButtonModalDetail] = useState(false);
	const [leaderObservation,setLeaderObservation] = useState('');
	const [employeeValue,setEmployeeValue] = useState('');
	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false});
	const [loadingButtonExportCSV, setLoadingButtonExportCSV] = useState(false);
	const [modalDetailEditResolutionHours, setModalDetailEditResolutionHours] = useState(false);

	const [filters, setFilters] = useState<{year: string, month: string, name?: string, status?: string}>({year: yearValue, month: monthValue.key});

	const [modalConfirmation, setModalConfirmation] = useState<{show: boolean, body?: any}>({ show: false, body: null });

	useEffect(() => {
		(async () => {
			setIncidentStatus('waiting');
			try{
				const jsonReturned =  await fetchAllPointIncidents(filters);
				if(jsonReturned){
					setIncidents(jsonReturned.pointIncidents);
					//setFilterIncidentes(jsonReturned.pointIncidents);
					filterList(jsonReturned.pointIncidents, employeeValue, statusValue.key)
					setIncidentStatus('success');
				}else{
					setIncidentStatus('error');
				}
			}catch(error){
				console.log(error);
			}

		})();
    }, [filters]);

	const handleColumnRender = ( columnValue:any, columnName:string, columnIndex:number, row: PointIncident ) => {
		
		switch(columnName){
			case 'employeeId':
				return <AvatarName size='s' 
									color=''
									image={`${URL_MICRO_FRONT}${imageUser}`}
									text={row.employee?.name} 
									subText={row.employee?.occupation?.name} />
			case 'incidentDate':
				return formatLocalDate(columnValue, true, 'YYYY-MM-DD', undefined);
			case 'pointIncidentStatus':
				return renderStatus(columnValue);
			case 'pointIncidentType':
				return(
					<> 
						<Message message={translateType(columnValue,row.actualPointsNumber, row.expectedPointsNumber)}
								icon='exclamation-circle'
								warning />
						{row.pointIncidentType !== 'AREA' && 
							<Container style={{fontSize: '12px', marginTop: '10px'}}>
								<Container><strong>{t('CABLE.POINT_INCIDENT.PLANNED')}:</strong> {row.expectedDailyHours}</Container>
								<Container><strong>{t('CABLE.POINT_INCIDENT.DONE')}:</strong> {row.actualDailyHours}</Container>
								<Container><strong>{t('CABLE.POINT_REGISTRATION.TEXT.REASON')}:</strong> {row.resolutionDailyHours}</Container>
							</Container>
						}
					</>
				)
			case 'actions':
				return (
					<>
						<Button primary sm
							title={t('GENERIC.BUTTON.SHOW.TEXT')}
							icon="eye"
							className={styles['table-item-action']}
							action={() => { setDetailIncident(row);  showOrHideModalDetail();}} />
						
					</>
				)
			default:
				return columnValue;
		}
	};

	const renderStatus = (status:string) => {
		return(
			<Message message={translateStatus(status)}
				danger={status === 'DISAPPROVED'}
				primary={status === 'OPENED' || status === 'WAITING_FOR_REVIEW'}
				success={status === 'SOLVED' || status === 'SOLVED_AUTO'}
				warning={status === 'WAITING'}
							icon={(status==='OPENED' || status === 'WAITING_FOR_REVIEW')?'signature':(status==='SOLVED' || status==='SOLVED_AUTO')?'check':status==='WAITING'?'clock':''}/>
		);
	};

	const showOrHideModalDetail = () =>{
		setLeaderObservation('');
		setShowModalDetail(!showModalDetail)
	};

	const closeModalConfirmationDisapprove = () => {
		setShowModalConfirmationDisapprove(false);
		setShowModalDetail(true);
	};

	const closeModalConfirmation = () => {
		setModalConfirmation({ show: false, body: null });
	};

	const changeSelectMonth = (key: string) => {
        let months = [...listMonths];

        for (var i = 0; i < months.length; i++) {
            if (months[i].key === key) {
                months[i].selected = true;
                setMonthValue(months[i]);
				setFilters({...filters, month: months[i].key})
            } else {
                months[i].selected = false;
            }
        }

        setListMonths(months);
    };

	const changeSelectYear = (key: string) => {
        let years = [...listYears];

        for (var i = 0; i < years.length; i++) {
            if (years[i].key === key) {
                years[i].selected = true;
                setYearValue(years[i].key);
				setFilters({...filters, year: years[i].key})
            } else {
                years[i].selected = false;
            }
        }

        setListYears(years);

    }

	const changeSelectStatus = (key: string) => {
        let statuses = [...listStatuses];

        for (var i = 0; i < statuses.length; i++) {
            if (statuses[i].key === key) {
                statuses[i].selected = true;
                setStatusValue(statuses[i]);
				filterList(incidents! ,employeeValue, statuses[i].key);
            } else {
                statuses[i].selected = false;
            }
        }

        setListStatuses(statuses);

    }

	const filterList = (listIncidents: PointIncident[],employeeName: string | null, status: string | null) => {
        

		let list: PointIncident[] = [...listIncidents!];

		if (status !== null && status !== '' && list!.length > 0) {
            list = list!.filter(e => 
                e.pointIncidentStatus.toUpperCase() === (status.toUpperCase()));
        }

        if (employeeName !== null && employeeName !== '' && list!.length > 0) {
            list = list!.filter(e => 
                e.employee?.name?.toUpperCase().includes(employeeName.toUpperCase()));
        }
        setFilterIncidentes([...list]);
    };

	const approveOrRejectJustificationValidation = () => {
		if(leaderObservation===''){
			setToastAlert({...toastAlert, danger: true, message:t('CABLE.POINT_INCIDENT.OBSERVATION_INFORMED'),show: true});
			return false;
		}

		return true;
	};

	const approveOrRejectJustification = async (justification: PointIncident, operationType: string) => {

		if(!approveOrRejectJustificationValidation()){
			return;
		}

		let status = '';
		if (operationType === 'approve') {
			status = 'SOLVED';

		} else if (operationType === 'reject') {
			setShowModalConfirmationDisapprove(false);
			setShowModalDetail(true);
			status = 'DISAPPROVED';

		} else if (operationType === 'review') {
			if (justification.approvalAttempts === justification.numberJustificationsAllowed) {
				setShowModalDetail(false);
				setShowModalConfirmationDisapprove(true);
				return;
			}

			status = 'WAITING_FOR_REVIEW';
		}

		setLoadingButtonModalDetail(true);

		try{
			const jsonReturned = await postPointIncidentApproveOrReject({
				id: justification.id,
				resolutionDailyHours: justification.resolutionDailyHours,
				leaderObservation: leaderObservation,
				status: status
			});

			if(jsonReturned){
				if(jsonReturned.status==='ERROR'){
					setToastAlert({...toastAlert, danger: true, message:jsonReturned.message,show: true});

				}else{
					setToastAlert({...toastAlert, danger: false, message:jsonReturned.message,show: true});

					//Atualiza lista sem necessidade de request
					let inci = [...incidents!];
					inci.forEach(i => {
						if(i.id===justification.id){
							i.pointIncidentStatus = status;
						}
					});

					setIncidents([...inci]);

					setTimeout(() => { 
						showOrHideModalDetail();
						
					}, 3000);
				}

				setLoadingButtonModalDetail(false);
			}

		}catch(error){
			console.log(error);
		}
	}

	const exportCSV = async () => {
        setLoadingButtonExportCSV(true);
		let formatter = new Intl.DateTimeFormat('pt-BR');
        let fileName = `${t('CABLE.POINT_INCIDENT.INCIDENT_REPORT')}_${employeeValue}_${formatter.format(Date.now()).replaceAll('/','-')}.csv`;
		console.log(fileName);
		try{
			const jsonReturned =  await fetchExportFileCSV({...filters, employeeName: employeeValue, status: statusValue.key});
			if(jsonReturned){
				downloadCsv(jsonReturned, fileName);
			}
			setLoadingButtonExportCSV(false);
		}catch(error){
			console.log(error);
		}
    };
	

	return (
		<Container className={styles['point-incident-list-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CABLE.POINT_INCIDENT.POINT_INCIDENT_LISTING')} />
				</Row.Item>
			</Row>

			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel title={t('GENERIC.TEXT.FILTERS')} noTitleBorder>
						<Row>
							<Row.Item lg={3} md={3} sm={6}>
								<Input type="text" 
									fullWidth
									title={t('GENERIC.TEXT.EMPLOYEE')} 
									name="inputColaborador"
									value={employeeValue}
									//status={inputStatus}
									handleChange={(event) => {
										setEmployeeValue(event.target.value);
										filterList(incidents!, event.target.value, statusValue.key)
									}} /> 
							</Row.Item>
							<Row.Item lg={3} md={3} sm={6}>
								<Select medium 
										fullWidth 
										name="selectStatus" 
										value={statusValue.value} 
										title={t('CABLE.POINT_REGISTRATION.TEXT.SITUATION')} 
										options={listStatuses} 
										handleChange={changeSelectStatus} />
							</Row.Item>
							<Row.Item lg={3} md={3} sm={6}>
								<Select medium 
										fullWidth 
										name="selectMonth" 
										value={monthValue.value} 
										title={t('GENERIC.TEXT.PERIOD')}
										options={listMonths} 
										handleChange={changeSelectMonth} />
							</Row.Item>
							<Row.Item lg={3} md={3} sm={6}>
								<Select medium 
										fullWidth 
										name="selectYear" 
										value={yearValue.toString()} 
										title={t('GENERIC.TEXT.YEAR')}
										options={listYears} 
										handleChange={changeSelectYear} />
							</Row.Item>
						</Row>
					</Panel>
				</Row.Item>
			</Row>

			{(incidentStatus === 'waiting') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING')}/>
						</Panel>
					</Row.Item>
				</Row>
			}

			{(incidentStatus === 'error') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation 
								icon='exclamation-circle'
								text={t('GENERIC.TEXT.UNABLE_RETURN')} />
						</Panel>
					</Row.Item>
				</Row>
			}

			{incidents &&  incidentStatus === 'success' && incidents.length === 0 &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel noPadding>
							<TextInformation 
								icon="search"
								text={t('CABLE.POINT_INCIDENT.NO_INCIDENTS_FOUND')}/>
						</Panel>
					</Row.Item>
				</Row>
			}

			{(incidents && incidentStatus === 'success') && incidents.length > 0 && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
					
						<Panel noPadding>
							<>
								<Container className={styles['actions']}>
									<Button primary md
										title={t('CABLE.POINT_INCIDENT.EXPORT_CSV')}
										icon="file-csv"
										loading={loadingButtonExportCSV}
										className={styles['action']}
										action={() => { exportCSV() }} />
								</Container>
								<Container className={styles['list']}>
									<TableListPagination list={filterIncidentes!}
										headers={['Colaborador', 'Data Incidente', 'Situação', 'Problema', 'Opções']}
										columns={['employeeId', 'incidentDate', 'pointIncidentStatus', 'pointIncidentType', 'actions']}
										handleColumnRender={handleColumnRender} />
								</Container>
							</>
						</Panel>
					</Row.Item>
				</Row>
			}

			{showModalDetail &&
                <Modal title={t('CABLE.POINT_REGISTRATION.TEXT.REASON')} 
					showClose 
					md 
					handleClose={() => { showOrHideModalDetail() }}>
                    <Container className={styles['modal-detail']}>
                        <Container>
							<dl>
								<dt>{t('GENERIC.TEXT.EMPLOYEE')}: </dt><dd>{detailIncident?.employee.name}</dd>
								<dt>{t('CABLE.POINT_REGISTRATION.TEXT.SITUATION')}: </dt><dd>{renderStatus(detailIncident?.pointIncidentStatus!)}</dd>
								<dt>{t('CABLE.POINT_INCIDENT.INCIDENT_DATE')}: </dt><dd>{formatLocalDate(detailIncident?.incidentDate, true, 'YYYY-MM-DD', undefined)}</dd>
								<dt>{t('CABLE.POINT_INCIDENT.OCCURRENCE')}: </dt><dd>{translateType(detailIncident?.pointIncidentType!, detailIncident?.actualPointsNumber, detailIncident?.expectedPointsNumber)}</dd>
								{detailIncident?.pointIncidentType !== 'AREA' &&
									<>
										<dt>{t('CABLE.POINT_INCIDENT.PLANNED')}: </dt><dd><DailyScaleHours dailyScaleDay={detailIncident?.expectedDailyHours} warning /></dd>
										<dt>{t('CABLE.POINT_INCIDENT.DONE')}: </dt><dd><DailyScaleHours dailyScaleDay={detailIncident?.actualDailyHours} warning /></dd>
										<dt>{t('CABLE.POINT_INCIDENT.ADJUSTMENT')}: </dt>
										<dd className={styles['dd-list-edit-points']}>
											<Container className={styles['list-points']}>
												<>
												{!modalDetailEditResolutionHours &&
													<DailyScaleHours dailyScaleDay={detailIncident?.resolutionDailyHours} warning />
													||
													<TimeOrderingEdit 
														rawTimes={detailIncident?.actualDailyHours}
														times={detailIncident?.resolutionDailyHours || ''}
														handleChanges={(ev: { name: string, value: string }) => {
															if (!!detailIncident) {
																const cp = { ...detailIncident };
																cp.resolutionDailyHours = ev['value'];
																setDetailIncident(cp);
															}
														}} />
												}
												</>
											</Container>

											{detailIncident?.pointIncidentStatus === 'WAITING' &&
												<Button className={styles['table-item-action']}
													title={modalDetailEditResolutionHours ? t('GENERIC.BUTTON.CLOSE.TEXT') : t('GENERIC.BUTTON.EDIT.TEXT')}
													icon={modalDetailEditResolutionHours ? "times" : 'edit'}
													outlined
													sm={!modalDetailEditResolutionHours}
													md={modalDetailEditResolutionHours}
													action={(ev) => { 
														ev.preventDefault();
														ev.stopPropagation();
														if(modalDetailEditResolutionHours && detailIncident){
															const cp = { ...detailIncident };
															cp.resolutionDailyHours = detailIncident?.resolutionDailyHours?.split('-')
																.filter(time => time !== '')
																.join('-');

															setDetailIncident(cp);
														}
														setModalDetailEditResolutionHours(modalDetailEditResolutionHours => (!modalDetailEditResolutionHours));
													}} />
											}
										</dd>
									</>
								}
								
								<dt>{t('GENERIC.TEXT.REASON')}: </dt><dd>{!!detailIncident?.pointComment ? detailIncident?.pointComment.name : '-'}</dd>
								<dt>{t('CABLE.POINT_REGISTRATION.TEXT.REASON')}: </dt><dd>{detailIncident?.descriptionResolution?detailIncident?.descriptionResolution:'-'}</dd>
								<dt>{t('CABLE.POINT_INCIDENT.MANAGER_OBSERVATION')}: </dt><dd>{detailIncident?.leaderObservation?detailIncident?.leaderObservation:'-'}</dd>
								<dt>{t('CABLE.POINT_INCIDENT.APPROVAL_ATTEMPTS')}: </dt><dd>{`${detailIncident?.approvalAttempts} ${t('GENERIC.TEXT.OF')} ${detailIncident?.numberJustificationsAllowed}`}</dd>
								{detailIncident?.incidentData?.['validationErrors'].length > 0 &&
									<>
										<dt style={{color: 'red'}}>
											{t('CABLE.POINT_INCIDENT.VALIDATION_ERRORS')}:
										</dt>
										<dd>
											<ul style={{ 'paddingLeft': '15px' }}>
												{detailIncident?.incidentData?.['validationErrors'].map((error: object | any, i: number) => (
													<li key={`li-error-${i}`}>{error['defaultMessage']}</li>
												))}
											</ul>
										</dd>
									</>
								}
							</dl>
						</Container>
						
						{detailIncident?.pointIncidentStatus ==='WAITING' && 
							<Container>
								<Input type="text" fullWidth
									title={t('CABLE.POINT_REGISTRATION.TEXT.OBSERVATION')}
									placeholder={t('CABLE.POINT_REGISTRATION.TEXT.OBSERVATION')}
									name="closedPeriodName"
									required 
									value={leaderObservation}
									//status={inputStatus}
									handleChange={(event) => setLeaderObservation(event.target.value)} /> 
							</Container> 
						}

						<Container className={styles['modal-detail-actions']}>
							<Button primary md
								title={t('CABLE.POINT_REGISTRATION.TEXT.CLOSE')}
								icon="undo"
								className={styles['modal-detail-action']}
								action={() => { showOrHideModalDetail() }} />

							{detailIncident?.pointIncidentStatus === 'WAITING' &&
								<>
									{detailIncident?.pointIncidentType !== 'AREA' &&
										<>
											<Button
												className={styles['modal-detail-action']}
												title={t('CABLE.POINT_INCIDENT.REQUEST_REVIEW')}
												icon="edit"
												warn
												md
												disabled={modalDetailEditResolutionHours} 
												loading={loadingButtonModalDetail}
												action={() => approveOrRejectJustification(detailIncident, 'review')} />

											<Button 
												className={styles['modal-detail-action']}
												title={t('CABLE.POINT_INCIDENT.REJECT')}
												icon="times"
												danger 
												md
												disabled={modalDetailEditResolutionHours} 
												loading={loadingButtonModalDetail}
												action={() => { approveOrRejectJustification(detailIncident, 'reject') }} />
										</>
									}

									<Button 
										className={styles['modal-detail-action']}
										title={t('CABLE.POINT_INCIDENT.APPROVE')}
										icon="check"
										success 
										md
										disabled={modalDetailEditResolutionHours} 
										loading={loadingButtonModalDetail}
										action={(event) => { 
											event.preventDefault();
											if(detailIncident?.incidentData?.['validationErrors'].length > 0){
												if(approveOrRejectJustificationValidation()){
													setModalConfirmation({
														show: true, 
														body: 
															<>
																<span>{t('CABLE.POINT_INCIDENT.MODAL_JUSTIFICATION_WITH_INCIDENTS_CONTINUE_TEXT')}</span>
																<ul style={{ 'paddingLeft': '15px' }}>
																	{detailIncident?.incidentData?.['validationErrors'].map((error: object | any, i: number) => (
																		<li key={`li-error-${i}`}>{error['defaultMessage']}</li>
																	))}
																</ul>
															</>
													});
												}
											}else{
												approveOrRejectJustification(detailIncident, 'approve'); 
											}
										}} />
								</>
							}
						</Container>
                    </Container> 

					{toastAlert.show &&
						<Toast 
							success={!toastAlert.danger}
							danger={toastAlert.danger}
							title={t('GENERIC.TEXT.OPERATION')}
							icon={toastAlert.danger?'exclamation-circle':'check'}
							positionTopRight
							closeChange={() => setToastAlert({...toastAlert, show: false})}
							message={toastAlert.message} 
							showTime={5000}/>
					}                  
                </Modal>
            }

			{showModalConfirmationDisapprove &&
				<Modal title={t('GENERIC.TEXT.CONFIRMATION')} 
					xs 
					showClose 
					handleClose={() => closeModalConfirmationDisapprove()}>
					<Container className={styles['modal-detail']}>
						<span>{t('CABLE.POINT_INCIDENT.LAST_TRY', undefined, detailIncident?.employee.name)}</span>

						<AlertTiny
							warn
							icon="exclamation-circle"
							title={t('GENERIC.TEXT.ATTENTION')}
							text={t('GENERIC.TEXT.OPERATION_CANNOT_BE_UNDONE')} />

						<Container className={styles['modal-detail-actions']}>
							<Button primary md
								title={t('CABLE.POINT_REGISTRATION.TEXT.CLOSE')}
								icon="undo"
								className={styles['modal-detail-action']}
								action={() => { closeModalConfirmationDisapprove() }} />

							<Button danger md
								title={t('CABLE.POINT_INCIDENT.REJECT')}
								icon="times"
								className={styles['modal-detail-action']}
								loading={loadingButtonModalDetail}
								action={() => { 
									approveOrRejectJustification(detailIncident!, 'reject') 
								}} />
						</Container>
					</Container>
				</Modal>
			}

			{modalConfirmation.show &&
				<Modal title={t('GENERIC.TEXT.CONFIRMATION')} 
					xs 
					showClose 
					handleClose={() => closeModalConfirmation()}>
					<Container className={styles['modal-detail']}>
						<AlertTiny
							warn
							icon="exclamation-circle"
							title={t('GENERIC.TEXT.ATTENTION')}
							text={t('GENERIC.TEXT.OPERATION_CANNOT_BE_UNDONE')} />

						{modalConfirmation.body}

						<Container className={styles['modal-detail-actions']}>
							<Button primary md
								title={t('CABLE.POINT_REGISTRATION.TEXT.CLOSE')}
								icon="undo"
								className={styles['modal-detail-action']}
								action={() => { closeModalConfirmation() }} />

							<Button 
								className={styles['modal-detail-action']}
								title={t('CABLE.POINT_INCIDENT.APPROVE')}
								icon="check"
								success 
								md
								loading={loadingButtonModalDetail}
								action={(event) => { 
									event.preventDefault();
									approveOrRejectJustification(detailIncident!, 'approve'); 
									closeModalConfirmation();
								}} />
						</Container>
					</Container>
				</Modal>
			}

		</Container>
	)
}

export default List;