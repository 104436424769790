import React, { useEffect, useState } from 'react';

import { PointEvent } from '../../../../models/PointEvent';

import { Button, Input, Loading, PageTitle, Panel, Row, Select, TextInformation } from '@optimuminterfaces/revex-react-components/dist/components';
import TableListPagination from '../../../organism/TableListPagination/TableListPagination';
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';


import styles from './List.module.scss';
import { downloadReportCsv, fetchAllPointEventTypes, fetchAllPointEvents } from '../../../../services/pointEvent.services';
import { useNavigate } from 'react-router-dom';
import { downloadCsv } from '@optimuminterfaces/revex-react-components/dist/utils/FileUtil';

interface ListProps{
    Container?: any
}

const List = ({Container ='div'}: ListProps) => {
    let navigate = useNavigate();

    const [pointEventStatus, setPointEventStatus] = useState('waiting');
    const [pointEvents, setPointEvents] = useState<PointEvent[]>();
	const [pointEventTypes, setPointEventTypes] = useState<any[]>();
    const [filterPointEvents, setFilterPointEvents] = useState<PointEvent[]>();
	const [filters, setFilters] = useState<{systemDefault: string, name: string, type: string}>({systemDefault: 'false', name: '', type: ''});
	const [loadingButtonExportCSV, setLoadingButtonExportCSV] = useState<boolean>(false);
	const [pointEventTypesOptions, setPointEventTypesOptions] = useState<[{key: string, value: string, selected: boolean}]>();
	const [systemDefaultOptions, setSystemDefaultOptions] = useState<{key: string, value: string, selected: boolean}[]>
		([{key:'false',value:'Não', selected: true},{key:'true',value:'Sim', selected: false}]);

	const [typeValue, setTypeValue] = useState<any>();
	const [systemDefaultValue, setSystemDefaultValue] = useState<any>({key:false,value:'Não', selected: true});

    useEffect(() => {
		(async () => {
			const jsonReturned =  await fetchAllPointEventTypes();
				if(jsonReturned){
					setPointEventTypes(jsonReturned.pointEventTypes);
				}
		})();

		(async () => {
			setPointEventStatus('waiting');
			try{
				const jsonReturned =  await fetchAllPointEvents();
				if(jsonReturned){
					setPointEvents(jsonReturned.pointEvents);
					setFilterPointEvents(jsonReturned.pointEvents);
					//filterList(jsonReturned.pointIncidents, employeeValue, statusValue.key)
					setFilters({...filters, systemDefault: 'false'});
					setPointEventStatus('success');
				}else{
					setPointEventStatus('error');
				}
			}catch(error){
				console.log(error);
			}

		})();
    }, []);

	useEffect( () => {
        if(pointEventTypes){
            buildSelectOptions();
        }
    }, [pointEventTypes]);

	const buildSelectOptions = () => {

        let options: any = [];
        options = pointEventTypes?.filter((s:any) => !s.system).map((s: any) => ({
            key: s.code, 
            value:s.name, 
            selected: false
        }));

        setPointEventTypesOptions(options);
            
    }

	useEffect(()=>{
		console.log((filters.name && filters.name !== '') || (filters.type && filters.type !== '') || (filters.systemDefault !== undefined));
		if((filters.name && filters.name !== '') || (filters.type && filters.type !== '') || (filters.systemDefault !== undefined)){
			setFilterPointEvents(pointEvents?.filter(p => 
				{
					return (filters.name !== ''? p.name.toLowerCase().includes(filters.name.toLowerCase()): true) && 
					(filters.type !== ''? p.pointEventType.toLowerCase().includes(filters.type.toLowerCase()): true) &&
					(filters.systemDefault !== undefined ? (p.systemDefault.toString())===filters.systemDefault: true)
				}
			))
		}else{
			setFilterPointEvents(pointEvents)
		}
	},[filters])

	const exportCSV = async () => {
		try{
			setLoadingButtonExportCSV(true);
			const jsonReturned = await downloadReportCsv({...filters}, undefined);

			if (jsonReturned) {
				downloadCsv(jsonReturned, 'eventos.csv');
				setLoadingButtonExportCSV(false);
			}
		} catch (error) {
			console.log(error);
		}

	}

	const internalHandleChanges = ({ name, value }:{name: string, value: any}) => {
        switch(name){

            case 'eventName':
				setFilters({...filters, name: value});
                break;

			case 'systemDefault':
				setFilters({...filters, systemDefault: value});

				systemDefaultOptions.map(t =>{
					console.log(t.key, value)
					if(t.key===value){
						t.selected=true;
						setSystemDefaultValue(t);
					}else{
						t.selected = false;
					}
				});
                break;

			case 'eventType':
				setFilters({...filters, type: value});
				let types = pointEventTypesOptions!;

				types.map(t =>{
					if(t.key===value){
						t.selected=true;
						setTypeValue(t);
					}else{
						t.selected = false;
					}
				});
                break;

        }
    }; 


    const handleColumnRender = ( columnValue:any, columnName:string, columnIndex:number, row: PointEvent ) => {
		switch(columnName){
            case 'systemDefault': 
                return columnValue===true? 'Sim': 'Não';
                break;
            case 'pointEventType':
                return t('CABLE.POINT_EVENT.POINT_EVENT_TYPE.'+ columnValue.toLocaleUpperCase());
                break;
            case 'actions':
				return (
					<>
						<Button primary sm
							title={t('GENERIC.BUTTON.EDIT.TEXT')}
							icon="edit"
							className={styles['table-item-action']}
							action={() => { navigate('/srepsMF/pointEvents/'+row.id)}} />
						
					</>
				)
			default:
				return columnValue;
		}
	};

	const cleanFilters = () => {
		setFilters({name: '', type: '', systemDefault: 'false'});
		setTypeValue(undefined);
		setSystemDefaultValue({key: 'false', value:'Não', selected: true});
	}

    return (
        <Container className={styles['point-event-list-rt']}>
            <Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CABLE.POINT_EVENT.LIST.TITLE')} />
				</Row.Item>
			</Row>

            <Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel noTitleBorder title={t('GENERIC.TEXT.FILTERS')}>
						<Row>
							<Row.Item lg={12} md={12} sm={12}>
								<Container className={styles['filters']}>
									<Row>
										<Row.Item sm={12} md={4} lg={4}>
											<Input name='eventName' 
													value={filters.name}
													title={t('GENERIC.TEXT.NAME')}
													fullWidth
													handleChange={(e) => {internalHandleChanges({name: e.target.name, value:e.target.value})}} />
										</Row.Item>
										<Row.Item sm={12} md={4} lg={4}>
											<Select medium
												value={typeValue?.value}
												name='eventType'
												title={t('CABLE.POINT_EVENT.NEW.POINT_EVENT_TYPE')}
												placeholder={t('CABLE.POINT_EVENT.NEW.POINT_EVENT_TYPE')} 
												options={pointEventTypesOptions!} 
												handleChange={(event) => internalHandleChanges({name: 'eventType', value: event})} fullWidth/>
                            
										</Row.Item>
										<Row.Item sm={12} md={2} lg={2}>
											<Select medium
												value={systemDefaultValue?.value}
												name='systemDefault'
												title={t('GENERIC.TEXT.SYSTEM_DEFAULT')}
												placeholder={t('GENERIC.TEXT.SYSTEM_DEFAULT')} 
												options={systemDefaultOptions!} 
												handleChange={(event) => internalHandleChanges({name: 'systemDefault', value: event})} fullWidth/>
                            
										</Row.Item>
										<Row.Item sm={12} md={2} lg={2}>
										<Button primary md
											title={t('GENERIC.BUTTON.CLEAR.TEXT')}
											icon="eraser"
											action={() => cleanFilters()} />
										</Row.Item>

									</Row>
								</Container>
							</Row.Item>
						</Row>
					</Panel>
				</Row.Item>
			</Row>

            {(pointEventStatus === 'waiting') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING')}/>
						</Panel>
					</Row.Item>
				</Row>
			}

			{(pointEventStatus === 'error') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation 
								icon='exclamation-circle'
								text={t('GENERIC.TEXT.UNABLE_RETURN')} />
						</Panel>
					</Row.Item>
				</Row>
			}

			{pointEvents &&  pointEventStatus === 'success' && pointEvents.length === 0 &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel noPadding>
							<TextInformation 
								icon="search"
								text={t('CABLE.POINT_EVENT.LIST.NO_EVENTS_FOUND')}/>
						</Panel>
					</Row.Item>
				</Row>
			}

			{(pointEvents && pointEventStatus === 'success') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
					
						<Panel noPadding>
							<>
								<Container className={styles['actions']}>
									<Button primary md
										title={t('CABLE.POINT_INCIDENT.EXPORT_CSV')}
										icon="file-csv"
										loading={loadingButtonExportCSV}
										className={styles['action']}
										action={() => { exportCSV() }} />

									<Button primary md
										title={t('CABLE.POINT_EVENT.LIST.NEW_EVENT',undefined)}
										icon="plus"
										action={() => { navigate('/srepsMF/pointEvents/new')}} />
								</Container>
								<Container className={styles['list']}>
									<TableListPagination list={filterPointEvents?filterPointEvents: []}
														headers={[t('GENERIC.TEXT.CODE'),t('GENERIC.TEXT.NAME'),t('GENERIC.TEXT.TYPE'), t('GENERIC.TEXT.SYSTEM_DEFAULT'),'Opções']}
														columns={['code','name','pointEventType','systemDefault','actions']}
														handleColumnRender={handleColumnRender} />
								</Container>
							</>
						</Panel>
					</Row.Item>
				</Row>
			}
        </Container>
    );
}

export default List;