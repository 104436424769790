import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvatarName, Button, DateTimePicker, Loading, Row, Select, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';


import { PointRegistrationCalculate } from '../../../../models/PointRegistrationCalculate';
import { convertMinutesToTimeStr, convertTimeStrToMinutes, formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { isObjectEmpty, uuidv4 } from '@optimuminterfaces/revex-react-components/dist/utils/DeveloperUtil';
import { colors } from '@optimuminterfaces/revex-react-components/dist/utils/ColorUtil';
import {fetchAllPointEvents } from '../../../../services/pointEvent.services';
import { postPointRegistrationEvent } from '../../../../services/pointRegistrationEvent.services';

import styles from './New.module.scss';

interface NewProps{
    pointRegistrationCalculate: PointRegistrationCalculate,
    employeeId: string,
    employeeName: string,
    employeeOccupation: string,
    handleChanges: Function,
    className?: string,
    Container?:any
}
const New = ({employeeName, employeeOccupation, employeeId, pointRegistrationCalculate, handleChanges, className, Container='div'}: NewProps) => {
    const [pointRegistrationEvent, setPointRegistrationEvent] = useState<any>({employeeId: employeeId, pointRegistrationEventDate: pointRegistrationCalculate.datePoint});
    const [pointRegistrationEventValidation, setPointRegistrationEventValidation] = useState(null);
    const [pointEvents, setPointEvents] = useState();

    const [pointEventOptions, setPointEventOptions] = useState<[{key: string, value: string, selected: boolean, type: string}]>();
    const [pointEvent, setPointEvent] = useState<any>();

    const [status, setStatus] = useState('waiting');
    const [loading, setLoading] = useState<{pointEventFetch: boolean, pointRegistrationEventSave: boolean}>
            ({ pointEventFetch: true, pointRegistrationEventSave: false });
    const [toastData, setToastData] = useState<any>([]);
    const [formErrors, setFormErrors] = useState(null);

    useEffect(() => {
        (async () => {
            setLoading({...loading, pointEventFetch: true});

            try{
                const jsonReturned =  await fetchAllPointEvents({systemDefault: false});
                if(jsonReturned){
                        setPointEvents(jsonReturned.pointEvents);
                        setLoading({...loading, pointEventFetch: false});
                        buildSelectOptions(jsonReturned.pointEvents);
                        setStatus('success');

                }else{
                    setStatus('error');
                    setLoading({...loading, pointEventFetch: false});
                }
            }catch(error){
                console.log(error);
                setStatus('error');
            }
        })();

    }, []);

    const buildSelectOptions = (events:any) => {

        let options: any = [];
        options = events.filter((e:any) => !e.system).map((e: any) => ({
            key: e.id, 
            value:e.name, 
            selected: pointEvent? pointEvent.id === e.id: false,
            type: e.pointEventType
        }));

        setPointEventOptions(options);
            
    }

    const internalHandleChanges = ({name, value }: {name: string, value?: any}) => {
        let pe = {...pointRegistrationEvent}

        switch(name){

            case 'pointRegistrationEventType':
                pe= {...pe, pointRegistrationEventType : {id: value}};
                setSelectOption(value);
                break;
            
            case 'pointRegistrationEventValue':
                pe= {...pe, pointRegistrationEventValue :  value};
                //setSelectOption(value);
                break;

            case 'PointRegistrationEventNew.cancel':
                {
                    handleChanges?.({ name, value });
                }
                break;
            case 'PointRegistrationEventNew.save':
                {

                    setFormErrors(null);
                    setLoading({ ...loading, pointEventFetch: false, pointRegistrationEventSave: true });

                    const callbackSuccess = (response: any) => {
                        if(response !== null && response.status === 'SUCCESS'){
                            internalAddToast({
                                show: true,
                                title: t('COMPONENTS.TOAST.TITLE.DATA_CHANGES_SAVE_SUCCESS'),
                                icon: 'save',
                                message: t('CABLE.TOAST.SUCCESS_POINT_EVENT_CHANGE', undefined, response.message),
                                success: true,
                                positionTopRight: true,
                                showTime: 5000
                            }, () => handleChanges?.({ name: 'PointRegistrationEventNew.success'}));
                        }else{

                            internalAddToast({
                                show: true,
                                title: t('COMPONENTS.TOAST.TITLE.SERVER_INTERNAL_ERROR'),
                                icon: 'exclamation-circle',
                                message: response.message? response.message: t('CABLE.TOAST.ERROR_POINT_EVENT_UPDATE'),
                                danger: true,
                                positionTopRight: true,
                                showTime: 5000
                            });

                            // if(!!response?.data?.data?.errors){
                            //     callbackErrors(response.data.data?.errors)
                            //     setFormErrors(response.data.data.errors);
                            // }
                            setLoading({...loading, pointEventFetch: false, pointRegistrationEventSave: false });
                        }
                    };

                    const callbackErrors = (errors: any) => {
                        console.log(errors);
                        internalAddToast({
                            show: true,
                            title: t('COMPONENTS.TOAST.TITLE.SERVER_INTERNAL_ERROR'),
                            icon: 'exclamation-circle',
                            message: t('CABLE.TOAST.ERROR_POINT_EVENT_UPDATE'),
                            warning: true,
                            positionTopRight: true,
                            showTime: 5000
                        });
                        setLoading({ ...loading, pointEventFetch: false, pointRegistrationEventSave: false });
                    };

                    // save/update the converter time to minutes 
                    value = {...value, pointRegistrationEventValue: convertTimeStrToMinutes(value.pointRegistrationEventValue)}

                    postPointRegistrationEvent(value).then(callbackSuccess, callbackErrors);
                   
                }
                break;    

        }
        if(pointRegistrationEventValidation !== null){
            dataValidation({...pe});
        }
        setPointRegistrationEvent({...pe});
    };

    const dataValidation = (pe?: any) => {
        let pEvent = pe || {...pointRegistrationEvent}
        let validationErrors: any = {};

        if( !pEvent.pointRegistrationEventValue || pEvent.pointRegistrationEventValue === ''){
            validationErrors = {...validationErrors, 'code': 'deve ser informado'}
        }

        if( !pEvent.pointRegistrationEventType.id || pEvent.pointRegistrationEventType.id === ''){
            validationErrors = {...validationErrors, 'pointRegistrationEventType': 'deve ser informado'}
        }
        if(isObjectEmpty(validationErrors)){
            setPointRegistrationEventValidation(null);
            return true;
        }else{
            setPointRegistrationEventValidation({...validationErrors});
            return false;
        }
    }

    const buildMessageError = () => {
        let message = '';
        if(pointRegistrationEventValidation !== null){
            Object.keys(pointRegistrationEventValidation)
                .forEach(
                    i => message += '<br />'+t('GENERIC.FIELD_VALIDATION.REQUIRED_FIELD', undefined, 
                        i.toLocaleUpperCase()==='POINTEVENTTYPE'? t('CABLE.POINT_EVENT.NEW.POINT_EVENT_TYPE') : t('GENERIC.TEXT.'+i.toLocaleUpperCase())))
        }

        return message;
    }

    const internalAddToast = (toastDataParam:any, handleCloseCallback?:Function) => {
        toastDataParam.key = `toast-${uuidv4()}`;
        toastDataParam.closeChange = () => {
            setToastData((toastData: any[]) => toastData.filter(({ key }) => (key !== toastDataParam.key)));
            handleCloseCallback?.();
        };

        setToastData((toastData: any) => [ ...toastData, toastDataParam ]);
    };

    const setSelectOption = (key: string, options?: [{key: string, value: string, selected: boolean, type: string}] ) => {
        let events = options || pointEventOptions!;

        events.map(t =>{
            if(t.key===key){
                t.selected=true;
                setPointEvent(t);
            }else{
                t.selected = false;
            }
        });

        setPointEventOptions(events);
    }

    return (
        <Container className={styles['point-registration-event-new']+ ' '+ !!className}>
            {status === 'waiting' &&
                <Row>
                    <Row.Item lg={12} md={12} sm={12}>
                        <Loading text={t('GENERIC.TEXT.LOADING')}/>
                    </Row.Item>
                </Row>
            }
            {(status === 'error') &&
                <Row>
                    <Row.Item lg={12} md={12} sm={12}>
                            <TextInformation
                                icon='exclamation-circle'
                                text={t('GENERIC.TEXT.UNABLE_RETURN')} />
                    </Row.Item>
                </Row>
            }
            {(status === 'success' && !loading.pointEventFetch) &&
                <>
                    {(pointRegistrationEventValidation !== null) && 
                        <Container className={ `${styles['point-event-feedback']}`}>
                            <TextInformation 
                                iconColor={colors['red-color-light']} 
                                icon='exclamation-triangle' 
                                text='Validação'
                                subText = { buildMessageError()} />
                        </Container>
                    }
                    <Row>
                        <Row.Item sm={12} md={12} lg={12} className={styles['additional-data']}>
                            <legend>Dados</legend>
                            <Container>
                                 <AvatarName size="m" 
                                    text={employeeName} 
                                    subText={employeeOccupation} 
                                    color=''/>
                            </Container>
                            <dl style={{marginTop: '30px'}}>
                                <dt className={styles['data']}> <span>{t('GENERIC.TEXT.DATE')}:</span></dt>
                                <dd className={styles['data']}><FontAwesomeIcon icon='calendar'/> {formatLocalDate(pointRegistrationCalculate.datePoint, true, 'YYYY-MM-DD', 'DD/MM/YY')}</dd>

                                <dt className={styles['late']}><span>{pointRegistrationCalculate.pointCalculate['SYSTEM_LATE_WORK'].name}:</span></dt>
                                <dd className={styles['late']}><FontAwesomeIcon icon='clock'/> {convertMinutesToTimeStr(pointRegistrationCalculate.pointCalculate['SYSTEM_LATE_WORK'].value)}</dd>

                                <dt className={styles['overtime']}><span>{pointRegistrationCalculate.pointCalculate['SYSTEM_OVERTIME'].name}:</span></dt>
                                <dd className={styles['overtime']}><FontAwesomeIcon icon='clock'/> {convertMinutesToTimeStr(pointRegistrationCalculate.pointCalculate['SYSTEM_OVERTIME'].value)}</dd>
                            </dl>

                            {/* <Container className={styles['data']}>
                                <FontAwesomeIcon icon='calendar'/> <span>{t('GENERIC.TEXT.DATE')}: </span> {formatLocalDate(pointRegistrationCalculate.datePoint, true, 'YYYY-MM-DD', 'DD/MM/YY')}
                            </Container>
                            <Container className={styles['late']}>
                                <FontAwesomeIcon icon='minus'/> <span>{pointRegistrationCalculate.pointCalculate['SYSTEM_LATE_WORK'].name}:</span> {convertMinutesToTimeStr(pointRegistrationCalculate.pointCalculate['SYSTEM_LATE_WORK'].value)}
                            </Container>
                            <Container className={styles['overtime']}>
                                <FontAwesomeIcon icon='plus'/> <span>{pointRegistrationCalculate.pointCalculate['SYSTEM_OVERTIME'].name}:</span> {convertMinutesToTimeStr(pointRegistrationCalculate.pointCalculate['SYSTEM_OVERTIME'].value)}
                            </Container> */}
                        </Row.Item>
                    </Row>
                    <Row>
                        <Row.Item sm={12} md={12} lg={12}>
                            <legend>Dados Evento</legend>
                        </Row.Item>
                        <Row.Item sm={12} md={8} lg={8}>
                            <Select 
                                name='pointRegistrationEventType'
                                fullWidth
                                title={t('GENERIC.TEXT.EVENT_TYPE')}
                                options={pointEventOptions!}
                                value={pointEvent?.value}
                                handleChange={(event) => internalHandleChanges({name: 'pointRegistrationEventType', value: event})}
                                />
                        </Row.Item>
                        <Row.Item sm={12} md={4} lg={4}>

                            <DateTimePicker 
                                    title={t('GENERIC.TEXT.HOURS')} 
                                    name="pointRegistrationEventValue" 
                                    type="time"
                                    minTime="00:00" 
                                    maxTime="22:15"
                                    noSeconds
                                    mask
                                    handleChange={(event)=>internalHandleChanges({name: 'pointRegistrationEventValue', value: event})}  />
                        </Row.Item>
                    </Row>
                    <Row>
                        <Row.Item sm={12} md={12} lg={12}>
                            <legend>{t('GENERIC.TEXT.ABSTRACT')}:</legend>
                        </Row.Item>
                        <Row.Item sm={12} md={12} lg={12} >
                            <Container className={styles['resume']}>
                                <dl>
                                    {(pointEvent?.type==='ALLOWANCE'  || pointEvent?.type==='DECREMENT_DATABASE' || pointEvent?.type==='DISCOUNT')  &&  
                                        <>
                                            <dt> <span>{t('GENERIC.TEXT.HOURS')}:</span></dt>
                                            <dd>&nbsp;&nbsp; {convertMinutesToTimeStr(pointRegistrationCalculate.pointCalculate['SYSTEM_LATE_WORK'].value)}</dd>
                                        </>
                                    }
                                    {(pointEvent?.type==='PAYMENT' || pointEvent?.type==='INCREMENT_DATABASE')  &&  
                                        <>
                                            <dt> <span>{t('GENERIC.TEXT.HOURS')}:</span></dt>
                                            <dd>&nbsp;&nbsp; {convertMinutesToTimeStr(pointRegistrationCalculate.pointCalculate['SYSTEM_OVERTIME'].value)}</dd>
                                        </>
                                    }

                                    {pointRegistrationEvent?.pointRegistrationEventValue &&
                                        <>
                                            <dt> <span>{t('CABLE.POINT_REGISTRATION_EVENT.DISCOUNT')}:</span></dt>
                                            <dd>- {pointRegistrationEvent?.pointRegistrationEventValue}</dd>
                                        </>
                                    }
                                    {pointRegistrationEvent?.pointRegistrationEventValue &&
                                        <>
                                            <dt> <span>{t('GENERIC.TEXT.TOTAL')}:</span></dt>
                                            <dd>&nbsp;&nbsp; {convertMinutesToTimeStr(((pointEvent?.type==='PAYMENT' || pointEvent?.type==='INCREMENT_DATABASE')?
													pointRegistrationCalculate.pointCalculate['SYSTEM_OVERTIME'].value : 
													pointRegistrationCalculate.pointCalculate['SYSTEM_LATE_WORK'].value) - 
													convertTimeStrToMinutes(pointRegistrationEvent?.pointRegistrationEventValue))}</dd>
                                        </>
                                    }
                                </dl>
                            </Container>
                        </Row.Item>
                    </Row>

                    <Container className={ `${styles['point-event-footer']}`}>
                        <Button title={t('GENERIC.BUTTON.CANCEL.TEXT')}
                            outlined 
                            loading={loading.pointRegistrationEventSave} 
                            action={(e) => { 
                                e?.preventDefault?.();
                                internalHandleChanges({ name: 'PointRegistrationEventNew.cancel'});
                            }} />

                        <Button title={t('GENERIC.BUTTON.SAVE.TEXT')} 
                            primary 
                            icon='save' 
                            loading={loading.pointRegistrationEventSave} 
                            style={{ marginLeft: '5px' }} 
                            disabled={pointRegistrationEventValidation !== null}
                            action={(e) => {
                                
                                e?.preventDefault?.();
                                if(dataValidation()){
                                    internalHandleChanges({ name: 'PointRegistrationEventNew.save', value: { ...pointRegistrationEvent }});    
                                }
                                
                            }} />
                    </Container>
                </>
            }
            {toastData?.map?.((toastDataE:any) => (
                <Toast {...toastDataE} />
            ))}
        </Container>
    );
}

export default New;