import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {Button, Pagination, PerPage, Table} from '@optimuminterfaces/revex-react-components/dist/components/index';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';

import styles from './ListPagination.module.scss';
import { useNavigate } from 'react-router-dom';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

interface ListPaginationProps {
	list?: any, 
	hasPermissionUpdateSrepConfiguration?: boolean, 
	hasPermissionDeleteSrepConfiguration?: boolean, 
	actionDelete?: any, 
	Container?: any
}
const ListPagination = ({ list, hasPermissionUpdateSrepConfiguration, hasPermissionDeleteSrepConfiguration, actionDelete, Container = 'div' }: ListPaginationProps) => {

    const [currentList, setCurrentList] = useState([]);
    const [listTotal, setListTotal] = useState(list.length);
    const [quantityPerPage, setQuantityPerPage] = useState(10);
    const [filters, setFilters] = useState({
        currentPage: 1,
        pageSize: 10,
    });
	let navigate = useNavigate();

    useEffect(() => {
        setFilters({ currentPage: 1, pageSize: filters.pageSize });
        setListTotal(list.length || 0);
        handlePageChanged(1, filters.pageSize);
    }, [list]);

    const handlePageChanged = (page: number, quantity: number) => {
        let size = !!quantity ? quantity : filters.pageSize;
        setFilters({ currentPage: page, pageSize: size });
        let filteredList = [];

        try {
            filteredList = list.slice(((page - 1) * size), (page * size));
        } catch (e) {
            filteredList = [];
        }

        setCurrentList(filteredList);
    };

    const handlePageSize = (size: number) => {
        setQuantityPerPage(size);
        handlePageChanged(1, size);
    }

    const renderSREPConfiguration = (srepParam:any, index:number) => {
        return (
            <Table.BodyItem key={`srep-configuration-${srepParam.id}`}>
                <Table.BodyItem.Item>{srepParam.name}</Table.BodyItem.Item>
				<Table.BodyItem.Item>{srepParam.employees===null?0:srepParam.employees?.split(',').length}</Table.BodyItem.Item>
                <Table.BodyItem.Item align="center">
                    {`${formatLocalDate(srepParam.startDate, true, undefined, undefined)} - ${srepParam.endDate !== null ? formatLocalDate(srepParam.endDate, true,undefined, undefined) : t('SREP.LIST.UNINFORMED_END')}`}
                </Table.BodyItem.Item>
                <Table.BodyItem.Item align="center">
                    {hasPermissionUpdateSrepConfiguration && 
                        <Button title={t('GENERIC.TEXT.EDIT')} warn sm icon="edit" className={styles['btn-action']}
                            action={
                                () => {navigate(`/srepsMF/settings/${srepParam.id}/edit`);}
                            } />
                    }
                    {hasPermissionDeleteSrepConfiguration && (srepParam.employees == null || srepParam.employees === "") &&
                        <Button title={t('GENERIC.BUTTON.DELETE.TEXT')} danger sm icon="trash" className={styles['btn-action']} 
                            action={() => actionDelete(srepParam.id, index)} />
                    }
                </Table.BodyItem.Item>
            </Table.BodyItem>
        );
    };

    return (
        <Container className={styles['list-srep-configurations-rt']}>
            <Pagination.Compact
                currentPage={filters.currentPage}
                pageLimit={filters.pageSize}
                totalRecords={listTotal}
                handlePageChanged={handlePageChanged} />

            <PerPage
                selected={quantityPerPage}
                handlePerPageChanged={(value) => handlePageSize(value)} />

            <Table primary striped>
                <Table.Header>
                    <Table.HeaderItem>
                        <Table.HeaderItem.Item>{t('SREP.LIST.CONFIGURATION_NAME')}</Table.HeaderItem.Item>
						<Table.HeaderItem.Item>{t('CLOSED_PERIODS.TEXT.NUMBER_OF_EMPLOYEES')}</Table.HeaderItem.Item>
                        <Table.HeaderItem.Item>{t('GENERIC.TEXT.PERIOD')}</Table.HeaderItem.Item>
                        {hasPermissionUpdateSrepConfiguration && 
                            <Table.HeaderItem.Item>{t('GENERIC.TEXT.OPTIONS')}</Table.HeaderItem.Item>
                        }
                    </Table.HeaderItem>
                </Table.Header>
                <Table.Body>
                    {currentList.map((srepParam,index) => {
                        return renderSREPConfiguration(srepParam,index);
                    })}
                </Table.Body>
            </Table>    
        </Container>
    );

};

ListPagination.defaultProps = {
    hasPermissionUpdateSrepConfiguration: false,
    hasPermissionDeleteSrepConfiguration: false
}

ListPagination.propTypes = {
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
    hasPermissionUpdateSrepConfiguration: PropTypes.bool,
    hasPermissionDeleteSrepConfiguration: PropTypes.bool,
    actionDelete: PropTypes.func
};

export default ListPagination;