import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// External components
import { AlertTiny, Button, DateTimePicker, Input, Label, Loading, Row, Select, TextInformation, Time, Toast } from "@optimuminterfaces/revex-react-components/dist/components";

// Internal components
import TimeOrdering from "../../../../molecules/TimeOrdering/TimeOrdering";

// External functions
import { translation as t } from "@optimuminterfaces/revex-react-components/dist/translation";

// REST functions
import { fetchEndDateOfLastClosedPeriod } from "../../../../../services/closedPeriod.services";
import { fetchDataToBuildPointIncident, postCreateByEmployee } from "../../../../../services/pointIncident.services";

// Models
import { PointComment } from "../../../../../models/PointComment";

import styles from './New.module.scss';

interface NewJustificationProps {
    pointComments?: PointComment[],
    handleClose: Function,
    Container?: any
};

interface JustificationModel {
    incidentDate: string,
    descriptionResolution: string,
    pointIncidentType: string | null,
    expectedPointsNumber: number,
    actualPointsNumber: number,
    expectedDailyHours: string | null,
    actualDailyHours: string | null,
    pointComment: string,
    resolutionDailyHours: string
};

interface ToastModel {
    show: boolean,
    title: string,
    message: string,
    danger: boolean
}

interface SelectItemModel {
    key: string,
    value: string,
    selected: boolean
};

interface PointModel {
    time: string,
    pointOrder: number,
    idx: string
}

const getBeforeYesterday = () => {
    let date: Date = new Date();
    date.setDate(date.getDate() - 2);

    return date.toISOString().slice(0, 10);
};

const generateRandonString = () => {
    return (Math.random() + 1).toString(36).substring(7)
};

const DEFAULT_JUSTIFICATION = {
    incidentDate: getBeforeYesterday(),
    descriptionResolution: "",
    pointIncidentType: "",
    expectedPointsNumber: 0,
    actualPointsNumber: 0,
    expectedDailyHours: "",
    actualDailyHours: "",
    pointComment: "",
    resolutionDailyHours: ""
};

const DEFAULT_COMMENT_VALUE: SelectItemModel = { key: '', value: t('CABLE.POINT_INCIDENT.SELECT'), selected: false };

const DATE_PATTERN = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/;

const NewJustification = ({
    pointComments,
    handleClose,
    Container = "div"
}: NewJustificationProps) => {

    // Antes de ontem (data limite)
    const beforeYesterday: string = getBeforeYesterday();

    const [newJustificationStatus, setNewJustificaionStatus] = useState<string>("LOADING");
    const [justification, setJustification] = useState<JustificationModel>(DEFAULT_JUSTIFICATION);
    const [plannedIsDayOff, setPlannedIsDayOff] = useState<boolean>(true);
    const [pointsJustification, setPointsJustification] = useState<PointModel[]>();
    const [endDateOfLastClosedPeriod, setEndDateOfLastClosedPeriod] = useState<string | null>(null);
    const [pointIncidentValue, setPointIncidentValue] = useState<string | null>(null);
    const [pointIncidentTypes, setPointIncidentTypes] = useState<SelectItemModel[]>([
        {
            key: "DIVERGENCE",
            value: t('CABLE.POINT_INCIDENT_TYPES.DIVERGENCE'),
            selected: false
        },
        {
            key: "NONE",
            value: t('CABLE.POINT_INCIDENT_TYPES.NONE'),
            selected: false
        },
        {
            key: "WORK_DAY_OFF",
            value: t('CABLE.POINT_INCIDENT_TYPES.WORK_DAY_OFF'),
            selected: false
        },
        {
            key: "AREA",
            value: t('CABLE.POINT_INCIDENT_TYPES.AREA'),
            selected: false
        }
    ]);
    const [commentValue, setCommentValue] = useState<SelectItemModel>(DEFAULT_COMMENT_VALUE);
    const [listComments, setListComments] = useState<SelectItemModel[]>([DEFAULT_COMMENT_VALUE]);
    const [toastAlert, setToastAlert] = useState<ToastModel>({ show: false, title: '', message: '', danger: false });
    const [loadingButtonJustification, setLoadingButtonJustification] = useState(false);

    useEffect(() => {
        getEndDateOfLastClosedPeriod();
    }, []);

    useEffect(() => {
        let pointsToEdit: string | null = !!justification.resolutionDailyHours ?
            justification.resolutionDailyHours : !!justification.actualDailyHours ? 
            justification.actualDailyHours : null;

        let justifyPoints: PointModel[] = [];
        if (!!pointsToEdit) {
            pointsToEdit.split('-').forEach((point, index) => {
                justifyPoints.push({
                    time: point,
                    pointOrder: index,
                    idx: generateRandonString()
                });
            });
        }

        setPointsJustification([...justifyPoints]);

    }, [justification]);

    useEffect(() => {
        if (pointComments) {
            setCommentValue(DEFAULT_COMMENT_VALUE);
            let options: any[] = [DEFAULT_COMMENT_VALUE];
            let comments = [];

            if (justification.pointIncidentType === 'WORK_DAY_OFF') {
                comments = pointComments.filter(comment => comment.showInWorkDayOff === true);

            } else if (justification.pointIncidentType === 'AREA') {
                comments = pointComments.filter(comment => comment.showInArea === true);

            } else if (justification.pointIncidentType === 'DIVERGENCE') {
                comments = pointComments.filter(comment => comment.showInDivergence === true);

            } else {
                comments = pointComments.filter(comment => comment.showInWorkDayOff === false || comment.name === 'Outro');
            }

            comments.forEach(element => {
                let valueOfSelect: SelectItemModel = {
                    key: element.id,
                    value: element.name,
                    selected: false
                };

                if (!!justification.pointComment && justification.pointComment === element.id) {
                    valueOfSelect.selected = true;
                    setCommentValue(valueOfSelect);
                    justificationHandleChange(undefined, "pointComment", valueOfSelect.key);
                }

                options.push(valueOfSelect);
            });

            setListComments(options);
        }
    }, [pointComments, justification.pointIncidentType]);

    const getEndDateOfLastClosedPeriod = async () => {
        setNewJustificaionStatus("LOADING");

        try {
            const jsonReturned = await fetchEndDateOfLastClosedPeriod();
            if (jsonReturned) {
                if (jsonReturned.status === "SUCCESS") {
                    let isEmptyList: boolean = false;
                    let dateString: string | null = jsonReturned.data.endDateOfLastClosedPeriod;

                    // Caso retorne valor, acrescenta 1 dia
                    if (!!dateString) {
                        let date: Date = new Date(dateString);
                        date.setDate(date.getDate() + 1);
                        dateString = date.toISOString().slice(0, 10);

                        // Verifica se data é maior que antes de ontem, caso verdadeiro não pode ser criado o incidente
                        if (date > new Date(getBeforeYesterday())) {
                            isEmptyList = true;
                        }
                    }

                    setEndDateOfLastClosedPeriod(dateString);

                    if (isEmptyList) {
                        setNewJustificaionStatus("EMPTY_LIST");

                    } else {
                        getDataToBuildJustification({ ...justification });
                    }

                } else {
                    setNewJustificaionStatus("ERROR");
                    setToastAlert({
                        show: true,
                        title: t('COMPONENTS.TOAST.TITLE.SERVER_INTERNAL_ERROR'),
                        message: jsonReturned.message,
                        danger: true
                    });
                }

            } else {
                setNewJustificaionStatus("ERROR");
            }

        } catch (error) {
            console.log(error);
            setNewJustificaionStatus("ERROR");
        }
    };

    const getDataToBuildJustification = async (pointIncident: JustificationModel) => {
        setNewJustificaionStatus("LOADING_PERIOD");

        try {
            const jsonReturned = await fetchDataToBuildPointIncident({ incidentDate: pointIncident.incidentDate });
            if (jsonReturned) {
                if (jsonReturned.status === "SUCCESS") {
                    pointIncident.pointIncidentType = jsonReturned.data.pointIncidentType;
                    pointIncident.expectedDailyHours = jsonReturned.data.expectedDailyHours;
                    pointIncident.expectedPointsNumber = jsonReturned.data.expectedPointsNumber;
                    pointIncident.actualDailyHours = jsonReturned.data.actualDailyHours;
                    pointIncident.actualPointsNumber = jsonReturned.data.actualPointsNumber;
                    pointIncident.resolutionDailyHours = jsonReturned.data.actualDailyHours;

                    let points: string[] = jsonReturned.data.expectedDailyHours.split("-");

                    setJustification(pointIncident);
                    setPlannedIsDayOff((points.length > 1) ? false : true);

                    if (!!pointIncident.pointIncidentType) {
                        pointIncidentTypeHandleChange(pointIncident.pointIncidentType, false);
                    }

                    setNewJustificaionStatus("SUCCESS");

                } else {
                    setToastAlert({
                        show: true,
                        title: t('GENERIC.TEXT.ERROR_OCURRED'),
                        message: jsonReturned.message,
                        danger: true
                    });
                }

            } else {
                setNewJustificaionStatus("ERROR");
            }

        } catch (error) {
            console.log(error);
            setNewJustificaionStatus("ERROR");
        }
    };

    const saveJustification = async (pointIncident: JustificationModel) => {
        setLoadingButtonJustification(true);

        let toast: ToastModel = {
            show: true,
            title: t('GENERIC.TEXT.ERROR_OCURRED'),
            message: t('COMPONENTS.TOAST.SUBTITLE.ERROR_FETCH'),
            danger: true
        };

        if (pointIncident.pointComment.trim() === "" || pointIncident.descriptionResolution.trim() === "") {
            toast.message = t('CABLE.POINT_INCIDENT.ALL_DATA_INFORMED');
            setToastAlert(toast);
            setLoadingButtonJustification(false);
            return;
        }

        if (pointIncident.pointIncidentType !== "AREA" && !!pointsJustification) {
            if (pointsJustification.length === 0) {
                toast.message = t('CABLE.POINT_INCIDENT.RECORDS_INFORMEDS');
                setToastAlert(toast);
                setLoadingButtonJustification(false);
                return;
            }

            if (pointsJustification.length % 2 !== 0) {
                toast.message = t('CABLE.POINT_INCIDENT.RECORDS_PAIRS');
                setToastAlert(toast);
                setLoadingButtonJustification(false);
                return;
            }

            if (pointsJustification.find(e => e.time === '') !== undefined) {
                toast.message = t('CABLE.POINT_INCIDENT.ALL_DATA_INFORMED');
                setToastAlert(toast);
                setLoadingButtonJustification(false);
                return;
            }

            pointIncident.resolutionDailyHours = pointsJustification.map(e => e.time).join("-");
        }

        try {
            const jsonReturned = await postCreateByEmployee(pointIncident);
            if (jsonReturned) {
                if (jsonReturned.status === "ERROR") {
                    toast.message = jsonReturned.message;
                    setToastAlert(toast);
                    setLoadingButtonJustification(false);

                } else {
                    toast.danger = false;
                    toast.message = jsonReturned.message;
                    setToastAlert(toast);

                    setTimeout(() => {
                        handleClose();
                    }, 5000);
                }

            } else {
                setToastAlert(toast);
                setLoadingButtonJustification(false);
            }

        } catch (error) {
            console.log(error);
            setToastAlert(toast);
            setLoadingButtonJustification(false);
        }
    };

    const justificationHandleChange = (
        e?: React.ChangeEvent<HTMLInputElement> | undefined,
        name?: string,
        value?: string | number | boolean
    ) => {
        if (e) {
            const target = e.target;
            name = target.name;
            value = target.type === "checkbox" ? target.checked : (target.type === "number" && !!target.value) ? parseInt(target.value) : target.value;
        }

        setJustification({ ...justification, [name!]: value });

        if (name === "incidentDate" && typeof value === "string" && value.match(DATE_PATTERN)) {
            getDataToBuildJustification({ ...justification, incidentDate: value });
        }
    };

    const pointIncidentTypeHandleChange = (key: string, updateJustification: boolean = true) => {
        if (updateJustification) {
            justificationHandleChange(undefined, "pointIncidentType", key);
        }

        let list: SelectItemModel[] = [...pointIncidentTypes];

        list.forEach(item => {
            if (item.key === key) {
                item.selected = true;
                setPointIncidentValue(item.value);

            } else {
                item.selected = false;
            }
        });

        setPointIncidentTypes(list);
    };

    const pointIncidentCommentHandleChange = (key: string) => {
        justificationHandleChange(undefined, "pointComment", key);

        let commets: SelectItemModel[] = [...listComments];

        commets.forEach(comment => {
            if (comment.key === key) {
                comment.selected = true;
                setCommentValue(comment);

            } else {
                comment.selected = false;
            }
        });

        setListComments(commets);
    };

    const pointIncidentJustificationHandleChange = (event: any) => {
        const eventName = event.name;
        const eventValue = event.value;
        if (eventName === 'element remove') {
            let points = [...pointsJustification!];
            if (points.indexOf(eventValue)) {
                points.splice(eventValue, 1);
                setPointsJustification(points);
            }
        }

        if (eventName === 'time change') {
            let points = [...pointsJustification!];
            points?.splice(eventValue.order, 1, { ...points[eventValue.order], time: eventValue.time });
            setPointsJustification([...points]);
        }
    };

    const pointIncidentJustificationOrderHandleChange = (event: any) => {
        let pointsState = [...pointsJustification!];

        pointsState.forEach(point => {
            event.value.forEach((v: string, idx: number) => {
                let [id, time] = v.split('|');
                if (time === point.time && id === point.idx) {
                    if (point.pointOrder !== idx) {
                        point.pointOrder = idx;
                    }
                }
            });
        });

        pointsState.sort((point1, point2) => {
            if (point1.pointOrder < point2.pointOrder) {
                return -1;
            } else {
                return 1;
            }
        })

        setPointsJustification([...pointsState]);
    };

    const renderInputJustification = (defaultValues: string) => {
        return (
            <>
                <Container className={styles['point-justification-container']} >
                    <Container className={styles['points-justification']}>
                        <TimeOrdering horizontal handleOrder={(e: any) => { pointIncidentJustificationOrderHandleChange(e) }}>
                            {pointsJustification?.map((value, index) => {
                                return <TimeOrdering.Item key={`${value.idx}${index}`} time={value.time}
                                    order={index}
                                    idx={value.idx}
                                    disabled={defaultValues?.includes(value.time) && value.time !== ''}
                                    deleted={true}
                                    handleChange={pointIncidentJustificationHandleChange} />
                            })}
                        </TimeOrdering>
                    </Container>

                </Container>
                <Container className={styles['add-point-action']}>
                    <Button icon='plus'
                        className={styles['add-point']}
                        primary md
                        title={t('CABLE.POINT_INCIDENT.ADD_RECORD')}
                        action={() => setPointsJustification([...pointsJustification!, { time: '', pointOrder: pointsJustification!.length, idx: generateRandonString() }])} />
                </Container>
            </>
        );
    };

    return (
        <Container className={styles['point-incident-my-new-rt']}>
            <Row>
                {newJustificationStatus === "ERROR" &&
                    <Row.Item sm={12} md={12} lg={12}>
                        <TextInformation
                            text={t('COMPONENTS.TOAST.TITLE.SERVER_INTERNAL_ERROR')}
                            subText={t('COMPONENTS.TOAST.SUBTITLE.ERROR_FETCH')}
                            icon="search" />
                    </Row.Item>
                }

                {newJustificationStatus === "EMPTY_LIST" &&
                    <Row.Item sm={12} md={12} lg={12}>
                        <TextInformation
                            text={t('CLOSED_PERIODS.TEXT.NO_UNFINISHED_PERIODS_FOUND')}
                            icon="search" />
                    </Row.Item>
                }

                {newJustificationStatus !== "LOADING" && newJustificationStatus !== "EMPTY_LIST" && newJustificationStatus !== "ERROR" &&
                    <>
                        <Row.Item sm={12} md={12} lg={12}>
                            <AlertTiny
                                warn
                                title={t('GENERIC.TEXT.ATTENTION')}
                                text={t('CABLE.POINT_INCIDENT.INFORMATION_TO_CREATE_INCIDENT')}
                                icon="info-circle" />
                        </Row.Item>

                        <Row.Item sm={12} md={12} lg={12}>
                            <DateTimePicker
                                title={t('CABLE.POINT_INCIDENT.INCIDENT_DATE')}
                                name="incidentDate"
                                value={justification.incidentDate}
                                type="date"
                                mask
                                fullWidth
                                status={!!justification.incidentDate ? "success" : "error"}
                                minDate={!!endDateOfLastClosedPeriod ? endDateOfLastClosedPeriod : undefined}
                                maxDate={beforeYesterday}
                                disabled={newJustificationStatus === "LOADING_PERIOD" ? true : false}
                                handleChange={(e) => { justificationHandleChange(undefined, "incidentDate", e) }} />
                        </Row.Item>
                    </>
                }

                {(newJustificationStatus === "LOADING" || newJustificationStatus === "LOADING_PERIOD") &&
                    <Row.Item sm={12} md={12} lg={12} className={styles['loading-data']}>
                        <Loading text={t('GENERIC.TEXT.LOADING')} />
                    </Row.Item>
                }

                {newJustificationStatus === "SUCCESS" &&
                    <>
                        <Row.Item sm={12} md={12} lg={12}>
                            <Select
                                medium
                                fullWidth
                                name="pointIncidentType"
                                title={t('CABLE.POINT_INCIDENT.OCCURRENCE')}
                                placeholder={t('CABLE.POINT_INCIDENT.OCCURRENCE')}
                                value={pointIncidentValue}
                                options={pointIncidentTypes}
                                handleChange={pointIncidentTypeHandleChange} />
                        </Row.Item>

                        {justification.pointIncidentType !== 'AREA' &&
                            <>
                                <Row.Item sm={12} md={12} lg={12}>
                                    <Container>
                                        <dl>
                                            <dt>{t('CABLE.POINT_INCIDENT.PLANNED')}: </dt><dd>{justification?.expectedDailyHours?.split('-').map(daily => <Time key={daily} time={daily} primary={!plannedIsDayOff} danger={plannedIsDayOff} />)}</dd>
                                            <dt>{t('CABLE.POINT_INCIDENT.DONE')}: </dt><dd>{justification?.actualDailyHours?.split('-').map(daily => <Time key={daily} time={daily} warning />)}</dd>
                                        </dl>
                                    </Container>
                                </Row.Item>

                                <Row.Item sm={12} md={12} lg={12}>
                                    <Container className={styles['point-incident-justification-form']}>
                                        <Label title={t('GENERIC.TEXT.POINTS')} />
                                        {renderInputJustification(!!justification.actualDailyHours ? justification.actualDailyHours : "")}
                                    </Container>
                                </Row.Item>
                            </>
                        }

                        <Row.Item sm={12} md={12} lg={12}>
                            <Container>
                                <Select
                                    medium
                                    fullWidth
                                    name='selectReason'
                                    value={commentValue.value}
                                    title={t('GENERIC.TEXT.REASON')}
                                    options={listComments}
                                    handleChange={pointIncidentCommentHandleChange} />
                            </Container>
                        </Row.Item>

                        <Row.Item sm={12} md={12} lg={12}>
                            <Container>
                                <Input
                                    type="text"
                                    fullWidth
                                    title={t('CABLE.POINT_REGISTRATION.TEXT.OBSERVATION')}
                                    name="descriptionResolution"
                                    value={justification.descriptionResolution}
                                    //status={inputStatus}
                                    handleChange={(event) => justificationHandleChange(event)} />
                            </Container>
                        </Row.Item>

                        <Row.Item sm={12} md={12} lg={12}>
                            <Container>
                                <Container className={styles['justification-actions']}>
                                    <Button primary md
                                        title={t('CABLE.POINT_REGISTRATION.TEXT.CLOSE')}
                                        icon="undo"
                                        className={styles['justification-action']}
                                        action={() => handleClose()} />
                                    <Button success md
                                        title={t('CABLE.POINT_INCIDENT.JUSTIFY')}
                                        icon="signature"
                                        className={styles['justification-action']}
                                        loading={loadingButtonJustification}
                                        action={() => saveJustification({ ...justification })} />
                                </Container>
                            </Container>
                        </Row.Item>
                    </>
                }
            </Row >

            {toastAlert.show &&
                <Toast
                    success={!toastAlert.danger}
                    danger={toastAlert.danger}
                    title={toastAlert.title}
                    icon={toastAlert.danger ? 'exclamation-circle' : 'check'}
                    positionTopRight
                    closeChange={() => setToastAlert({ ...toastAlert, show: false })}
                    message={toastAlert.message}
                    showTime={5000} />
            }

        </Container>
    );

};

NewJustification.propTypes = {
    pointComments: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleClose: PropTypes.func.isRequired
};

export default NewJustification;