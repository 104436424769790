
import { library } from '@fortawesome/fontawesome-svg-core'

import { 
    faClock,
	faLock,
	faUnlock
} from '@fortawesome/free-solid-svg-icons'

import {
	faClock				    as fasClock,
	faPlus 				    as fasPlus,
	faSearch 			    as fasSearch,
	faEdit 				    as fasEdit,
	faCheck 			    as fasCheck,
	faTimes 			    as fasTimes,
	faInfoCircle 		    as fasInfoCircle,
	faTrash 			    as fasTrash, 
	faSave 				    as fasSave,
	faTools 			    as fasTools,
	faUser 				    as fasUser,
	faCogs				    as fasCogs,
	faFileUpload		    as fasFileUpload,
	faCloudUpload		    as fasCloudUpload,
	faCloudDownload		    as fasCloudDownload,
	faTachometerAlt 	    as fasTachometerAlt,
	faUserClock 		    as fasUserClock,
	faAlarmExclamation 	    as fasAlarmExclamation,
	faExclamationCircle     as fasExclamationCircle,
	faFileCsv			    as fasFileCsv,
	faUserLock			    as fasUserLock,
	faEye				    as fasEye,
	faUndo				    as fasUndo,
	faMapMarkedAlt		    as fasMapMarkedAlt,
	faMapMarkerAltSlash     as faMapMarkerAltSlash,
	faDrawCircle		    as fasDrawCircle,
	faUsers				    as fasUsers,
	faSignature			    as fasSignature,
	faFilePdf			    as fasFilePdf,
	faSortDown 			    as fasSortDown,
	faDownload			    as fasDownload,
    faFileExclamation       as fasFileExclamation,
    faExclamationTriangle   as fasExclamationTriangle,
    faFile                  as fasFile,
	faFilesMedical          as fasFilesMedical,
	faQuestionCircle		as fasQuestionCircle,
	faCheckCircle			as fasCheckCircle,
	faSync					as fasSync,
	faEraser				as fasEraser,
	faArrowCircleUp			as fasArrowCircleUp,
	faArrowCircleDown		as fasArrowCircleDown,
	faMinus					as fasMinus,
	faPen					as fasPen,
	

} from '@fortawesome/pro-solid-svg-icons';

import {
	faClock as falClock, 
} from '@fortawesome/pro-light-svg-icons';

library.add(
	fasClock as any,fasPlus as any,fasSearch as any,fasEdit as any,fasCheck as any, fasTimes as any, fasInfoCircle as any,fasTrash as any,
	fasSave as any,fasTools as any,fasUser as any, fasCogs as any, fasFileUpload as any, fasCloudUpload as any, fasCloudDownload as any,
	fasTachometerAlt as any,fasUserClock as any,fasAlarmExclamation as any,fasExclamationCircle as any,fasFileCsv as any,
	fasUserLock as any,fasEye as any,fasUndo as any,fasMapMarkedAlt as any, faMapMarkerAltSlash as any, fasDrawCircle as any,fasUsers as any,fasSignature as any, fasFilePdf as any,
	fasSortDown as any,fasDownload as any, fasFileExclamation as any, fasExclamationTriangle as any, fasFile as any, fasQuestionCircle,
	fasCheckCircle as any, fasFilesMedical as any, fasSync as any, fasEraser as any, fasArrowCircleUp as any, fasArrowCircleDown as any, fasMinus as any,
	fasPen as any,

    falClock as any,

	faClock as any, faLock as any, faUnlock as any
);

