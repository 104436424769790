import API from './core';

export const TOKEN_KEY = "TOKEN_KEY";

export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);

export const setLogin = (token: string) => {
    sessionStorage.setItem(TOKEN_KEY, token);
};

export const setEnvironment = (data: any) => {
    sessionStorage.setItem('CABLE_ENVIRONMENT', JSON.stringify(data));
};

export const getEnvironment = () => {
	const json = sessionStorage.getItem('CABLE_ENVIRONMENT');
	if(json){
    	return JSON.parse(json);
	}else{
		return '';
	}
};

export const setLogout = () => {
    sessionStorage.removeItem(TOKEN_KEY);
};


export const fetchEnvironment = async () => {
	try{
		return API.get(`/rest/points/environment`)
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}