import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGripVertical} from '@fortawesome/pro-regular-svg-icons';
import { Button, DateTimePicker, DragDropList } from '@optimuminterfaces/revex-react-components/dist/components';


import styles from './TimeOrdering.module.scss';
import moment from 'moment';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';

interface TimeOrderingProps{
	Container?: any,
	children: any,
	className?: string
	horizontal?: boolean,
	handleOrder: Function

}

interface TimeOrderingItemProps{
	Container?: any
	time: string,
	order: number,
	date?: string,
	referenceDate?: string,
	disabled: boolean,
	deleted?: boolean,
	className?: string,
	changeReference?: boolean,
	idx: string,
	handleChange: Function
}
const TimeOrdering = ({ children, 
						horizontal, 
						className, 
						handleOrder, 
						Container='div'}:TimeOrderingProps) => {
	const [list, setList] = useState<any[]>([]);
	

	useEffect(()=>{
		let l: any[] = [];
		React.Children.map(children, (child, index) => {
			if(child && child !== null){
				l.push({id: child.props.idx+'|'+child.props.time, component: React.cloneElement(child)})
			}
		});

		setList([...l]);
	},[children]);

	return(
		<Container className={`${className?className:''} ${styles['time-ordering-rt']} ${horizontal? styles['time-ordering-horizontal-rt']: ''}`}>
			{list && <DragDropList horizontal handleChange={(e)=> { handleOrder(e)}} listComponents={list} height='40px'/>}
		</Container>
	)
}

TimeOrdering.Item = ({time, 
					order, 
					idx ,
					date='',
					referenceDate='',
					disabled, 
					deleted=false, 
					changeReference = false, 
					handleChange, 
					className, 
					Container='div'} : TimeOrderingItemProps) => {
	const key = (time+order+Math.random().toString());

	const isBefore = date===''? false : moment(date).isBefore(moment(referenceDate));
	const isAfter = referenceDate===''? false : moment(date).isAfter(moment(referenceDate));

	const renderReferenceDateIndicate = () => {
		
		return(
			<>
			
				{isBefore && <FontAwesomeIcon icon={'plus'} title={`Data da marcação é ${formatLocalDate(date, true, 'YYYY-MM-DD', undefined)}`}/>}
				{isAfter && <FontAwesomeIcon icon={'minus'} title={`Data da marcação é ${formatLocalDate(date, true, 'YYYY-MM-DD', undefined)}`}/>}
			</>
			
		);
	}
	return(
		<Container className={`${className?className:''} ${styles['time-ordering-item']}`}>
			<Container className={`${styles['dates']} ${!referenceDate?styles['one-date']:''}`}>
				<Container>Date: <strong>{formatLocalDate(date,true, 'YYYY-MM-DD', undefined)}</strong> </Container>
				{referenceDate && <Container>Referência: <strong>{formatLocalDate(referenceDate,true, 'YYYY-MM-DD', undefined)}</strong></Container>}
			</Container>
			<Container className={styles['details']}>
				<Container className={styles['time-ordering-time']}>
					<DateTimePicker key={key} name={key} value={time} type="time" noSeconds 
							handleChange={(e)=>handleChange({name: 'time change', value: {order, time: e}})} mask disabled={disabled}/>
				</Container>
			</Container>
			<Container className={styles['actions']}>
					<Container className={styles['time-reference-date-action']}>
					{changeReference && (referenceDate !== '' && referenceDate !==null) && 
						<>
							<Button xxs 
									icon='arrow-circle-up' 
									defaultColor
									disabled={isAfter}
									action={()=> handleChange({name:'changeReferenceDate', value: {type: 'DOWN', id: idx, order: order}})}/>
							<Button xxs 
									icon='arrow-circle-down' 
									defaultColor
									disabled={isBefore}
									action={()=> handleChange({name:'changeReferenceDate', value:  {type: 'UP', id: idx, order: order}})}/>
						</>
					}
					</Container>
					{deleted && 
						<Container className={styles['time-ordering-action']}>
							<Button xxs icon='trash' defaultColor action={()=> handleChange({name:'element remove', value: order})}/>
						</Container>
					}
				</Container>
			
		</Container>
	)
}

export default TimeOrdering;