import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AlertTiny, Button, DualList, Input, Loading, Modal, PageTitle, Panel, Row, Select, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';

import { Employee } from '../../../../models/Employee';

import { fetchAllEmployeeAvailable, postClosedPeriod } from '../../../../services/closedPeriod.services';
import { getEnvironment } from "../../../../services/auth.services";

import { calculateSelectMonth, calculateSelectYear } from '../../../../utils/SelectUtil';
import { buildListWarning, buildListError } from '../../../../utils/ClosedPeriodUtil';

import { formatLocalDate, MONTH_OF_YEAR_EN } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index';

import styles from './New.module.scss';

interface NewProps {
	Container?: any
}

interface ModalConfirmation {
	show: boolean,
	isError: boolean,
	sendPointSheet: boolean
}

const New = ({ Container = 'div' }: NewProps) => {

	let navigate = useNavigate();

	// Pega o período anterior ao atual
	const currentDate = new Date();
	currentDate.setMonth(currentDate.getMonth() - 1);

	const years = calculateSelectYear();
	const months = calculateSelectMonth();
    const indexSelected = months.findIndex(m => m.selected == true)-1;
	let currentMonth = months[indexSelected < 0?0:indexSelected];

	const [closedPeriodStatus, setClosedPeriodStatus] = useState<string>();
	const [listYears, setListYears] = useState(years);
	const [listMonths, setListMonths] = useState(months);
	const [inputStatus, setInputStatus] = useState<string>();
	const [inputValue, setInputValue] = useState<string>();
	const [yearValue, setYearValue] = useState<string>(currentDate.getFullYear().toString());
	const [monthValue, setMonthValue] = useState<any>(currentMonth);
	const [loadingButtonSave, setLoadingButtonSave] = useState(false);
	const [employees, setEmployees] = useState<Employee[]>([]);
	const [dualListOptions, setDualListOptions] = useState<any[]>();
	const [dualListOptionsSelected, setDualListOptionsSelected] = useState([]);
	const [toastAlert, setToastAlert] = useState<{ show: boolean, message: string, danger: boolean }>({ show: false, message: '', danger: false })

	// Modal de confirmação
	const [listWarning, setListWarning] = useState<string[]>([]);
	const [modalConfirmation, setModalConfirmation] = useState<ModalConfirmation>({
		show: false,
		isError: false,
		sendPointSheet: false
	});

	useEffect(() => {
		checkIsFutureDate();

		const buildDualListOptions = (employees: Employee[]) => {
			if (!!employees) {
				let dualList: { key: string; selected: boolean; values: any[]; }[] = [];
				employees.forEach(employee => {
					let lastClosedPeriod: string = !!employee.startDateLastClosedPeriod ?
						`${formatLocalDate(employee.startDateLastClosedPeriod, true, 'YYYY-MM-DD', 'DD/MM/YY')} - ${formatLocalDate(employee.endDateLastClosedPeriod, true, 'YYYY-MM-DD', 'DD/MM/YY')}` :
						t('CLOSED_PERIODS.TEXT.NO_CLOSED_PERIOD');

					dualList.push({ key: employee.id, selected: false, values: [employee.name, employee.occupation?.name, `${t('CLOSED_PERIODS.TEXT.LAST_PERIOD_CLOSED')}: ${lastClosedPeriod}`] })
				});

				setDualListOptions(dualList);
				setClosedPeriodStatus('success');

			} else {
				setDualListOptions([]);
				setClosedPeriodStatus('emptyList');
			}
		};

		(async () => {
			setClosedPeriodStatus('waiting');
			try {
				const jsonReturned = await fetchAllEmployeeAvailable({ 'year': yearValue, 'month': monthValue.key });
				if (jsonReturned) {
					setEmployees(jsonReturned.employees);
					buildDualListOptions(jsonReturned.employees);
					//setClosedPeriods(jsonReturned.closedPeriods);
				} else {
					setClosedPeriodStatus('error');
				}
			} catch (error) {
				console.log(error);
			}

		})();
	}, [yearValue, monthValue]);


	const changeSelectYear = (key: string) => {
		let years = [...listYears];

		for (var i = 0; i < years.length; i++) {
			if (years[i].key === key) {
				years[i].selected = true;
				setYearValue(years[i].key);
			} else {
				years[i].selected = false;
			}
		}

		setListYears(years);
	}

	const changeSelectMonth = (key: string) => {
		let months = [...listMonths];

		for (var i = 0; i < months.length; i++) {
			if (months[i].key === key) {
				months[i].selected = true;
				setMonthValue(months[i]);
			} else {
				months[i].selected = false;
			}
		}

		setListMonths(months);

	}

	const validateInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.value.length >= 1) {
			setInputStatus("success");
		} else {
			setInputStatus("error");
		}

		setInputValue(event.target.value);
	};

	// filtra todos colaboradores selecionados
	const handleChangeDualList = (returnOptions: any) => {
		let listReturn = returnOptions.filter((e: any) => e.selected === true);
		let listContains = listReturn.map((e: any) => e.key);

		setDualListOptionsSelected(listContains);
	};

	const checkWarningsAndErrors = () => {
		if (!checkIsFutureDate()) {
			setLoadingButtonSave(true);

			const json = getEnvironment();

			let day: number = 1;

			if (json.scaleConfigs && json.scaleConfigs.customCycleOfScale === true) {
				day = json.scaleConfigs.cycleOfScaleStart;
			}

			let month: number = MONTH_OF_YEAR_EN.indexOf(monthValue.key);
			let year: number = parseInt(yearValue);
			const actualStartDate: Date = new Date(year, month, day, 0, 0, 0);

			month = month - 1;
			if (month === -1) {
				month = 11;
				year = year - 1;
			}

			let startDateLastClosedPeriod: Date = new Date(year, month, day, 0, 0, 0);

			let listErrors: string[] = buildListError(
				employees,
				dualListOptionsSelected,
				[],
				actualStartDate);

			if (!!listErrors && listErrors.length > 0) {
				setListWarning(listErrors);
				setModalConfirmation({ show: true, isError: true, sendPointSheet: false });

			} else {
				let list: string[] = buildListWarning(
					employees,
					dualListOptionsSelected,
					startDateLastClosedPeriod);

				setListWarning(list);

				if (list.length === 0) {
					setModalConfirmation({ show: true, isError: false, sendPointSheet: true });
					setLoadingButtonSave(false);

				} else {
					setModalConfirmation({ show: true, isError: false, sendPointSheet: false });
					setLoadingButtonSave(false);
				}
			}
		}
	};

	const saveClosedPeriod = async (sendPointSheet: boolean) => {

		if (inputValue === '' || inputValue === undefined) {
			setToastAlert({ ...toastAlert, danger: true, message: t('CLOSED_PERIODS.TEXT.CLOSING_NAME_INFORM', undefined), show: true });
			setLoadingButtonSave(false);
			return;
		}
		if (dualListOptionsSelected.length === 0) {
			setToastAlert({ ...toastAlert, danger: true, message: t('CLOSED_PERIODS.TEXT.NO_EMPLOYEE_SELECTED', undefined), show: true });
			setLoadingButtonSave(false);
			return;
		}

		setLoadingButtonSave(true);

		try {
			const jsonReturned = await postClosedPeriod({ year: parseInt(yearValue), month: monthValue.key, name: inputValue, employeesId: dualListOptionsSelected.toString(), sendPointSheet: sendPointSheet });
			if (jsonReturned) {
				if (jsonReturned.status === 'ERROR') {
					setToastAlert({ ...toastAlert, danger: true, message: jsonReturned.message, show: true });
					setLoadingButtonSave(false);

				} else {
					setToastAlert({ ...toastAlert, danger: false, message: jsonReturned.message, show: true });
					setTimeout(() => {
						setLoadingButtonSave(false);
						navigate('/srepsMF/closedPeriods')
					}, 3000);
				}
				//setClosedPeriods(jsonReturned.closedPeriods);
			} else {

			}

		} catch (error) {
			console.log(error);
		}

	};

	const closeModalConfirmation = () => {
		setModalConfirmation({
			show: false,
			isError: false,
			sendPointSheet: false
		});
		setLoadingButtonSave(false);
	};

	const getActualEndDate = () => {
		const json = getEnvironment();

		let month: number = MONTH_OF_YEAR_EN.indexOf(monthValue.key) + 1;

		if (json.scaleConfigs && json.scaleConfigs.customCycleOfScale === true) {
			let day = json.scaleConfigs.cycleOfScaleEnd;
            
			month = month === 11 ? 0 : month;

			return new Date(parseInt(yearValue), month, day, 0, 0, 0);

		} else {
			return new Date(parseInt(yearValue), month, 0, 0, 0, 0);
		}
        
	};

	const checkIsFutureDate = () => {
		let date = new Date();
		let actualEndDate = getActualEndDate();
		if (actualEndDate < date) {
			return false;
		}

		setToastAlert({ show: true, message: t('CLOSED_PERIODS.TEXT.ERROR_LONGER_THAN_CURRENT_PERIOD'), danger: true });

		return true;
	};

	return (
		<Container className={styles['closed-periods-new-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CLOSED_PERIODS.TEXT.NEW_PERIOD_CLOSING', undefined)} returnUrl="#/srepsMF/closedPeriods" />
				</Row.Item>
			</Row>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel>
						<Row>
							<Row.Item lg={6} md={6} sm={12}>
								<Input type="text"
									fullWidth
									title={t('CLOSED_PERIODS.TEXT.NAME_FOR_CLOSED_PERIOD', undefined)}
									name="closedPeriodName"
									status={inputStatus}
									handleChange={validateInput} />
							</Row.Item>
							<Row.Item lg={3} md={3} sm={6}>
								<Select medium
									fullWidth
									name="selectMonth"
									value={monthValue.value}
									title={t('GENERIC.TEXT.PERIOD', undefined)}
									options={listMonths}
									handleChange={changeSelectMonth} />
							</Row.Item>
							<Row.Item lg={3} md={3} sm={6}>
								<Select medium
									fullWidth
									name="selectYear"
									value={yearValue}
									title={t('GENERIC.TEXT.YEAR', undefined)}
									options={listYears}
									handleChange={changeSelectYear} />
							</Row.Item>
						</Row>
					</Panel>
				</Row.Item>
			</Row>

			{(closedPeriodStatus === 'waiting') &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING', undefined)} />
						</Panel>
					</Row.Item>
				</Row>
			}
			{(closedPeriodStatus === 'success') &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Panel title={`${monthValue.value}/${yearValue}`} noTitleBorder>
							<Container>
								<DualList
									titleAvailable={t('CABLE.AREA.NEW.AVAILABLE_EMPLOYEES', undefined)}
									titleSelected={t('CABLE.AREA.NEW.SELECTED_EMPLOYEES', undefined)}
									options={dualListOptions!}
									firstIcon="user"
									secondIcon="tools"
									thirdIcon="calendar"
									handleChange={handleChangeDualList} />
								<Container className={styles['actions']}>
									<Button danger md
										title={t('GENERIC.TEXT.CANCEL', undefined)}
										icon="times"
										className={styles['button-action']}
										action={() => { navigate('/srepsMF/closedPeriods') }} />
									<Button primary md
										title={t('GENERIC.BUTTON.SAVE.TEXT', undefined)}
										icon="save"
										loading={loadingButtonSave}
										className={styles['button-action']}
										action={() => { checkWarningsAndErrors() }} />
								</Container>
							</Container>
						</Panel>
					</Row.Item>
				</Row>
			}

			{(closedPeriodStatus === 'emptyList') &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Panel title={`${monthValue.value}/${yearValue}`} noTitleBorder>
							<Container>
								<TextInformation
									text={`${t('CLOSED_PERIODS.TEXT.NO_EMPLOYEE', undefined)} ${`${monthValue.value}/${yearValue}`}`}
									icon="search" />
							</Container>
						</Panel>
					</Row.Item>
				</Row>
			}

			{modalConfirmation.show &&
				<Modal title={t('GENERIC.TEXT.CONFIRMATION')} xs showClose handleClose={() => closeModalConfirmation()}>
					{!modalConfirmation.isError && !modalConfirmation.sendPointSheet &&
						<p>{t('CLOSED_PERIODS.TEXT.WARNING_EMPLOYEES_WITH_GAP')}</p>
					}

					{modalConfirmation.isError && !modalConfirmation.sendPointSheet &&
						<p>{`${t('CLOSED_PERIODS.TEXT.CLOSED_PERIOD_CONTAINS_ERRORS')} ${t('GENERIC.TEXT.CHECK_ERROR_LIST')}:`}</p>
					}

					{!modalConfirmation.sendPointSheet &&
						<ul>
							{listWarning.map(item => {
								return <li key={`list-warning-${item}`}>{item}</li>
							})}
						</ul>
					}

					{modalConfirmation.isError && !modalConfirmation.sendPointSheet &&
						<Container style={{ textAlign: 'center' }}>
							<Button primary md
								title="OK"
								action={() => { closeModalConfirmation() }} />
						</Container>
					}

					{!modalConfirmation.isError && !modalConfirmation.sendPointSheet &&
						<Container>
							<p>{t('GENERIC.TEXT.ARE_YOU_SURE_YOU_WANT_TO_CONTINUE')}</p>

							<Container style={{ textAlign: 'center' }}>
								<Button danger md
									title={t('GENERIC.TEXT.CANCEL', undefined)}
									icon="times"
									action={() => { closeModalConfirmation() }} />
								<Button primary md
									title={t('GENERIC.BUTTON.SAVE.TEXT', undefined)}
									icon="save"
									loading={loadingButtonSave}
									action={() => {
										setModalConfirmation({
											show: true,
											isError: false,
											sendPointSheet: true
										})
									}} />
							</Container>
						</Container>
					}

					{!modalConfirmation.isError && modalConfirmation.sendPointSheet &&
						<Container>
							<AlertTiny
								warn
								icon="exclamation-circle"
								title={t('GENERIC.TEXT.ATTENTION')}
								text={t('CLOSED_PERIODS.TEXT.SEND_POINT_SHEET_INFORMATION')} />

							<p>{t('CLOSED_PERIODS.TEXT.SEND_POINT_SHEET_QUESTION')}</p>

							<Container style={{ textAlign: 'center' }}>
								<Button danger md
									title={t('GENERIC.TEXT.NO', undefined)}
									icon="times"
									loading={loadingButtonSave}
									action={() => { saveClosedPeriod(false) }} />
								<Button primary md
									title={t('GENERIC.TEXT.YES', undefined)}
									icon="save"
									loading={loadingButtonSave}
									action={() => { saveClosedPeriod(true) }} />
							</Container>
						</Container>
					}
				</Modal>
			}

			{toastAlert.show &&
				<Toast
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION', undefined)}
					icon={toastAlert.danger ? 'exclamation-circle' : 'check'}
					positionTopRight
					closeChange={() => setToastAlert({ ...toastAlert, show: false })}
					message={toastAlert.message}
					showTime={5000} />
			}
		</Container>
	);
}

export default New;