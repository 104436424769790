import { Alert, Button, ImportFileItem, ImportFileNew, Loading, Modal, PageTitle, Panel, Row, Tabs, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import { downloadCsv } from '@optimuminterfaces/revex-react-components/dist/utils/FileUtil';
import React, { useEffect, useState } from 'react';
import { ImportFile } from '../../../../models/ImportFile';
import { downloadFile, fetchAllImportFiles, postImportFile } from '../../../../services/importFiles.services';

import styles from './List.module.scss';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

interface ListProps {
	Container?: any
}

const List = ({Container='div'}:ListProps) => {

	const [currentActiveTabIndex, setCurrentActiveTabIndex] = useState<number>(0);
	const [modalImportDetail, setModalImportDetail] = useState<{showModal: boolean, detail: any}>({ showModal: false, detail: '' });
	const [modalNewImport, setModalNewImport] = useState<boolean>(false);
	const [importFiles, setImportFiles] = useState<ImportFile[]>();
	const [importFilesAFDT, setImportFilesAFDT] = useState<ImportFile[]>();
	const [importFilesAFD, setImportFilesAFD] = useState<ImportFile[]>();
	const [importFilesPoint, setImportFilesPoint] = useState<ImportFile[]>();
	const [importFileStatus, setImportFileStatus] = useState<string>('waiting');
	const [importFileAndType, setImportFileAndType] = useState<{file: File, type: string}>();
	const [modalNewImportErrorMessage, setModalNewImportErrorMessage] = useState<string>();
	const [newImportLoading, setNewImportLoading] = useState<boolean>(false);
	const [showAlertSuccess, setShowAlertSuccess] = useState<boolean>(false);

	useEffect(() => {
		(async () => {
			setImportFileStatus('waiting');
			try{
				const jsonReturned =  await fetchAllImportFiles();
				if(jsonReturned){
					setImportFileStatus('success');
					setImportFiles(jsonReturned.importFiles);
				}else{
					setImportFileStatus('error');
				}
			}catch(error){
				console.log(error);
			}

		})();
	},[])

	useEffect(() => {

		setImportFilesAFDT(importFiles?.filter(importFile => {
			return importFile.type === 'AFDT'
		}));

		setImportFilesAFD(importFiles?.filter(importFile => {
			return importFile.type === 'AFD'
		}));

		setImportFilesPoint(importFiles?.filter(importFile => {
			return importFile.type === 'POINTS_CSV'
		}));

	},[importFiles]);

	const renderItens = (importFiles: ImportFile[]) => {
		return (
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<ImportFileItem 
						iconMap={{'AFDT': ['fas', 'user-clock'], 'AFD': ['fas', 'user-clock']}}
						showList
						buttonDownloadTitle={t('GENERIC.BUTTON.DOWNLOAD.TEXT',undefined)}
						showButtonDownload
						handleClick = {(event) => {fileImportDownload(event.value.importFileId, importFiles[event.value.index].fileName)}}
						importFiles={importFiles}
					/>
				</Row.Item>
				
			</Row>
		);
	}

	const showOrHideModalImportDetail = ( details:any ) => {
		setModalImportDetail({ showModal: (!modalImportDetail.showModal), detail: details });
	};

	const showOrHideModalNewImport = () => {
		setModalNewImport(!modalNewImport);
	};

	const handleSuccessAdd = (event: any) => {
		console.log(event);
		if(event.name === 'importFileNew.importType'){
			setImportFileAndType({...importFileAndType!, type: event.value});
		}
		if(event.name === 'importFileNew.files'){
			setImportFileAndType({...importFileAndType!, file: event.value[0]});
		}
	};

	const createImportFile = async () => {
		if(importFileAndType?.type && importFileAndType?.file){
			setNewImportLoading(true);
			try{
				const jsonReturned =  await postImportFile(importFileAndType.file, importFileAndType.type);
				if(jsonReturned){
					if(jsonReturned.status === 'ERROR'){
						setModalNewImportErrorMessage(jsonReturned.message);
					}else{
						let imfi = importFiles;
						imfi = imfi?.concat(jsonReturned.data.importFile)

						setImportFiles(imfi);
						showOrHideModalNewImport();
						setShowAlertSuccess(true);
					}
				}else{
					setImportFileStatus('error');
				}
				setNewImportLoading(false);
			}catch(error){
				console.log(error);
			}
		}else{
			
		}
	}

	const fileImportDownload = async (id: string, name: string) => {

		try{
			const jsonReturned =  await downloadFile(id);
			if(jsonReturned){
				downloadCsv(jsonReturned, name);
			}
		}catch(error){
			console.log(error);
		}
    };

	return (
		<Container className={styles['import-file-list-rt']} >
			<Row>
				<Row.Item lg={12} md={12} sm={12}>
					<PageTitle.Small title={t('CABLE.IMPORT_FILE.TITLE',undefined)} />
				</Row.Item>
			</Row>
			{(!importFiles && importFileStatus === 'waiting') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING',undefined)}/>
						</Panel>
					</Row.Item>
				</Row>
			}
			{(!importFiles && importFileStatus === 'error') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation 
								icon="exclamation-circle"
								text={t('GENERIC.TEXT.UNABLE_RETURN',undefined)} />
						</Panel>
					</Row.Item>
				</Row>
			}
			{(importFiles && importFileStatus === 'success') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Row>
								<Row.Item lg={12} md={12} sm={12}>
									<Container className={styles['import-file-list-rt-actions']}>
										<Button className={styles['button-rt-new-upload']} primary 
											title={t('CABLE.IMPORT_FILE.SEND_NEW',undefined)}
											action={() => showOrHideModalNewImport()} />
									</Container>
									
								</Row.Item>
								<Row.Item lg={12} md={12} sm={12}>
									<Tabs activeTab={currentActiveTabIndex}>
										<Tabs.Item title='AFDT' key="tab-afdt">
											<Container>
												{renderItens(importFilesAFDT!)}
											</Container>
										</Tabs.Item>
										<Tabs.Item title='AFD' key="tab-afd">
											<Container>
												{renderItens(importFilesAFD!)}
											</Container>
										</Tabs.Item>
										<Tabs.Item title={t('CABLE.IMPORT_FILE.CSV_FILE')} key="tab-points">
											<Container>
												{renderItens(importFilesPoint!)}
											</Container>
										</Tabs.Item>
									</Tabs>
								</Row.Item>

								<Row.Item lg={12} md={12} sm={12} xs={12}>
									{modalImportDetail.showModal &&
										<Modal title={t('CABLE.IMPORT_FILE.DETAIL', undefined)} lg showClose
											handleClose={showOrHideModalImportDetail}>
										</Modal>
									}
								</Row.Item>

								<Row.Item lg={12} md={12} sm={12} xs={12}>
									{modalNewImport &&
										<Modal title={t('CABLE.IMPORT_FILE.NEW',undefined)} md showClose
											handleClose={() => { showOrHideModalNewImport() }}>
											{newImportLoading && <Loading text={t('CABLE.IMPORT_FILE.UPLOADING',undefined)} />}
											{!newImportLoading &&
												<>
												{modalNewImportErrorMessage && <Alert warn text={modalNewImportErrorMessage} /> }
												<ImportFileNew importFileTypes={
															[
																{
																	name: 'AFDT', 
																	icon: 'user-clock', 
																	description:t('CABLE.IMPORT_FILE.PROCESSED_DATA_FILE',undefined),
																	type: 'afdt'
																},
																{
																	name: 'AFD', 
																	icon: 'user-clock', 
																	description: t('CABLE.IMPORT_FILE.DATA_SOURCE_FILE',undefined), 
																	type: 'afd'
																}
															]
														}
														
														onChange={(event) => handleSuccessAdd(event)}/>
												<Container className={styles['modal-add-import-file-actions']}>
													<Button title={t('GENERIC.TEXT.CANCEL', undefined)} action={() => showOrHideModalNewImport()}/>
													<Button primary title={t('GENERIC.TEXT.SEND',undefined)} action={ () => createImportFile()} />
												</Container>
												</>
											}
										</Modal>
									}
								</Row.Item>
							</Row>
							
						</Panel>
					</Row.Item>
					{showAlertSuccess &&
						<Toast 
							success 
							title={t('GENERIC.TEXT.OPERATION',undefined)}
							positionTopRight
							closeChange={() => setShowAlertSuccess(false)}
							message={t('CABLE.IMPORT_FILE.UPLOAD_FILE',undefined)} 
							showTime={5000}/>
					}
				</Row>
			}
		</Container>
		

	)
}

export default List;