import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Button, DateTimePicker, Input, Row} from '@optimuminterfaces/revex-react-components/dist/components/index';

import styles from './NewSplitHour.module.scss';
import { SrepSplitHour } from '../../../../../models/SrepSplitHour';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

interface NewSplitHourProps{
	timeInit: any, 
	cancelAction: Function, 
	saveAction: Function, 
	Container?: any
}

const NewSplitHour = ({ timeInit, cancelAction, saveAction, Container = 'div' }: NewSplitHourProps) => {

    const [splitHour, setSplitHour] = useState<SrepSplitHour>({
		hourInit: timeInit,
		percentage: 100
	});
    const [disabledButton, setDisabledButton] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);

    useEffect(() => {
        validateForm(splitHour!);
    }, [splitHour]);

    const handleChange = (e?: React.ChangeEvent<HTMLInputElement>| undefined, 
						name?:string, 
						value?:string) => {
        if (e) {
            const target = e.target;
            value = target.value;
            name = target.name;
        }

        setSplitHour({ ...splitHour!, [name!]: value });
    };

    const validateForm = (splitHour: SrepSplitHour) => {
        if (!!splitHour.hourInit && !!splitHour.hourEnd && !!splitHour.percentage) {
            let hourInit = moment(splitHour.hourInit, "HH:mm");
            let hourEnd = moment(splitHour.hourEnd, "HH:mm");

            if (hourInit.isBefore(hourEnd) && splitHour.percentage >= 0 && splitHour.percentage <= 999) {
                setDisabledButton(false);
            } else {
                setDisabledButton(true);
            }
        } else {
            setDisabledButton(true);
        }
    }

    const save = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setLoadingButton(true);
        saveAction(splitHour);
    }

    const renderForm = () => {
        return (
            <form onSubmit={e => e.preventDefault()} className={styles['form-new-split-hour']}>
                <Row>
                    <Row.Item lg={6} md={6} sm={6}>
                        <DateTimePicker
                            title={t('GENERIC.TEXT.START_TIME')}
                            name="hourInit"
                            value={splitHour.hourInit}
                            minTime="00:00"
                            maxTime="23:59"
                            type="time"
                            noSeconds
							timeInverseColor={false}
							forceOpenPicker={false}
                            mask
                            disabled
                            fullWidth
                            handleChange={(e) => {handleChange(undefined, "hourInit", e) }} />
                    </Row.Item>
                    <Row.Item lg={6} md={6} sm={6}>
                        <DateTimePicker
                            title={t('GENERIC.TEXT.END_TIME')}
                            name="hourEnd"
                            value={splitHour.hourEnd}
                            minTime="00:00"
                            maxTime="23:59"
                            type="time"
                            noSeconds
							timeInverseColor={false}
                            mask
							forceOpenPicker={false}
                            fullWidth
                            status={!!splitHour.hourEnd ? 'success' : 'error'}
                            handleChange={(e) => { handleChange(undefined, "hourEnd", e) }} />
                    </Row.Item>
                    <Row.Item lg={12} md={12} sm={12}>
                        <Input
                            type="number"
                            medium
                            value={splitHour.percentage}
                            title={t('GENERIC.TEXT.PERCENTAGE') + ' %'}
                            name="percentage"
                            placeholder={t('GENERIC.TEXT.PERCENTAGE') + ' %'}
                            fullWidth
                           // minValue="1"
                            //maxValue="999"
                            status={!!splitHour.percentage && (splitHour.percentage >= 1 && splitHour.percentage <= 999) ? 'success' : 'error'}
                            handleChange={(e) => handleChange(e)} />
                    </Row.Item>
                    <Row.Item lg={12} md={12} sm={12}>
                        <Container className={styles['action-split-hour']}>
                            <Button
                                danger
                                title={t('GENERIC.BUTTON.DELETE.TEXT')}
                                outlined
                                action={(e) => {
                                    e.preventDefault();
                                    cancelAction();
                                }} />

                            <Button
                                success
                                title={t('GENERIC.TEXT.REGISTER')}
                                disabled={disabledButton}
                                loading={loadingButton}
                                icon="save"
                                action={(e) => save(e)} />
                        </Container>
                    </Row.Item>
                </Row>
            </form>
        );
    }

    return (
        <Container className={styles['new-split-hour']} >
            {renderForm()}
        </Container>
    );

};

NewSplitHour.propTypes = {
    cancelAction: PropTypes.func.isRequired,
    saveAction: PropTypes.func.isRequired
};

export default NewSplitHour;