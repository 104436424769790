import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AlertTiny, Button, DualList, Input, Loading, Modal, PageTitle, Panel, Row, Select, Table, Tabs, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';

import { ClosedPeriod } from '../../../../models/ClosedPeriod';
import { Employee } from '../../../../models/Employee';

import { fetchClosedPeriod, putClosedPeriod } from '../../../../services/closedPeriod.services';
import { calculateSelectMonth, calculateSelectYear } from '../../../../utils/SelectUtil';
import { buildListWarning, buildListError } from '../../../../utils/ClosedPeriodUtil';
import { colors } from '@optimuminterfaces/revex-react-components/dist/utils/ColorUtil';
import { getEnvironment } from "../../../../services/auth.services";

import { formatLocalDate, MONTH_OF_YEAR_EN } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index';

import styles from './Edit.module.scss';

interface EditProps {
	Container?: any
}

interface ModalConfirmation {
	show: boolean,
	isError: boolean,
	sendPointSheet: boolean
}

const Edit = ({ Container = 'div' }: EditProps) => {

	let navigate = useNavigate();

	const years = calculateSelectYear();
	const months = calculateSelectMonth();

	let currentMonth = months.filter(m => m.selected == true)[0];

	const [closedPeriodStatus, setClosedPeriodStatus] = useState<string>('waiting');
	const [listYears, setListYears] = useState(years);
	const [listMonths, setListMonths] = useState(months);
	const [inputStatus, setInputStatus] = useState<string>();
	const [inputValue, setInputValue] = useState<string>('');
	const [yearValue, setYearValue] = useState<any>(years[3]);
	const [monthValue, setMonthValue] = useState<any>(currentMonth);
	const [loadingButtonSave, setLoadingButtonSave] = useState(false);
	const [employees, setEmployees] = useState<Employee[]>([]);
	const [dualListOptions, setDualListOptions] = useState<any[]>();
	const [dualListOptionsSelected, setDualListOptionsSelected] = useState([]);
	const [originalEmployeeIds, setOriginalEmployeeIds] = useState<string[]>([]);
	const [documents, setDocuments] = useState<any[]>([]);
	const [toastAlert, setToastAlert] = useState<{ show: boolean, message: string, danger: boolean }>({ show: false, message: '', danger: false });

	// Modal de confirmação
	const [modalConfirmation, setModalConfirmation] = useState<ModalConfirmation>({
		show: false,
		isError: false,
		sendPointSheet: false
	});
	const [listWarning, setListWarning] = useState<string[]>([]);

	//Parametros da URL
	const [params, setParams] = useState(useParams());


	useEffect(() => {
		const buildDualListOptions = (employees: Employee[], employeesSelected: string[]) => {
			if (!!employees) {
				let dualList: { key: string; selected: boolean; values: any[]; }[] = [];
				employees.forEach(employee => {
					let lastClosedPeriod: string = !!employee.startDateLastClosedPeriod ?
						`${formatLocalDate(employee.startDateLastClosedPeriod, true, 'YYYY-MM-DD', 'DD/MM/YY')} - ${formatLocalDate(employee.endDateLastClosedPeriod, true, 'YYYY-MM-DD', 'DD/MM/YY')}` :
						t('CLOSED_PERIODS.TEXT.NO_CLOSED_PERIOD');

					dualList.push({ key: employee.id, selected: employeesSelected.indexOf(employee.id) !== -1, values: [employee.name, employee.occupation?.name, `${t('CLOSED_PERIODS.TEXT.LAST_PERIOD_CLOSED')}: ${lastClosedPeriod}`] })
				});

				setDualListOptions(dualList);
				setClosedPeriodStatus('success');
			} else {
				setDualListOptions([]);
				setClosedPeriodStatus('emptyList');
			}
		}

		(async () => {
			setClosedPeriodStatus('waiting');
			try {
				const jsonReturned = await fetchClosedPeriod(params.id!);

				if (jsonReturned) {
					let closedPeriod: ClosedPeriod = jsonReturned.closedPeriods;
					let startDate: Date = new Date(`${jsonReturned.closedPeriods.startDate}T00:00:00`);
					closedPeriod.year = startDate.getFullYear();
					closedPeriod.month = listMonths[startDate.getMonth()].key;

					setInputValue(closedPeriod.name);
					setOriginalEmployeeIds(!!closedPeriod.employeesId ? closedPeriod.employeesId.split(',') : []);
					setEmployees(jsonReturned.options);
					setDocuments(jsonReturned.signatures);

					listMonths.forEach(month => {
						if (month.key === closedPeriod.month) {
							setMonthValue(month);
						}
					});

					listYears.forEach(year => {
						if (!!closedPeriod.year && year.key === closedPeriod.year.toString()) {
							setYearValue(year);
						}
					});

					buildDualListOptions(jsonReturned.options, closedPeriod.employeesId.split(','));

				} else {
					setClosedPeriodStatus('error');
				}
			} catch (error) {
				console.log(error);
			}

		})();
	}, []);

	// filtra todos colaboradores selecionados
	const handleChangeDualList = (returnOptions: any) => {
		let listReturn = returnOptions.filter((e: any) => e.selected === true);
		let listContains = listReturn.map((e: any) => e.key);

		setDualListOptionsSelected(listContains);
	};

	const updateClosedPeriod = async (sendPointSheet: boolean) => {

		if (inputValue === '' || inputValue === undefined) {
			setToastAlert({ ...toastAlert, danger: true, message: t('CLOSED_PERIODS.TEXT.CLOSING_NAME_INFORM', undefined), show: true });
			setLoadingButtonSave(false);
			return;
		}
		if (dualListOptionsSelected.length === 0) {
			setToastAlert({ ...toastAlert, danger: true, message: t('CLOSED_PERIODS.TEXT.NO_EMPLOYEE_SELECTED', undefined), show: true });
			setLoadingButtonSave(false);
			return;
		}

		setLoadingButtonSave(true);

		try {
			const jsonReturned = await putClosedPeriod(params.id!, { employeesId: dualListOptionsSelected.toString(), sendPointSheet: sendPointSheet });
			if (jsonReturned) {
				if (jsonReturned.status === 'ERROR') {
					setToastAlert({ ...toastAlert, danger: true, message: jsonReturned.message, show: true });
					setLoadingButtonSave(false);
				} else {
					setToastAlert({ ...toastAlert, danger: false, message: jsonReturned.message, show: true });
					setTimeout(() => {
						setLoadingButtonSave(false);
						navigate('/srepsMF/closedPeriods')
					}, 3000);
				}
				//setClosedPeriods(jsonReturned.closedPeriods);
			} else {

			}

		} catch (error) {
			console.log(error);
		}

	};

	const checkWarningsAndErrors = () => {
		setLoadingButtonSave(true);

		const json = getEnvironment();

		let day: number = 1;

		if (json.scaleConfigs && json.scaleConfigs.customCycleOfScale === true) {
			day = json.scaleConfigs.cycleOfScaleStart;
		}

		let month: number = MONTH_OF_YEAR_EN.indexOf(monthValue.key);
		let year: number = parseInt(yearValue.key);
		const actualStartDate: Date = new Date(year, month, day, 0, 0, 0);

		month = month - 1;
		if (month === -1) {
			month = 11;
			year = year - 1;
		}

		let startDateLastClosedPeriod: Date = new Date(year, month, day, 0, 0, 0);

		let errors: string[] = buildListError(
			employees,
			dualListOptionsSelected,
			originalEmployeeIds,
			actualStartDate);

		setListWarning(errors);

		// exibir erros
		if (!!errors && errors.length > 0) {
			setModalConfirmation({ show: true, isError: true, sendPointSheet: false });

		} else {
			let list: string[] = buildListWarning(
				employees,
				dualListOptionsSelected,
				startDateLastClosedPeriod);

			setListWarning(list);

			if (list.length === 0) {
				setModalConfirmation({ show: true, isError: false, sendPointSheet: true });
				setLoadingButtonSave(false);

			} else {
				setModalConfirmation({ show: true, isError: false, sendPointSheet: false });
				setLoadingButtonSave(false);
			}
		}
	};

	const closeModalConfirmation = () => {
		setModalConfirmation({
			show: false,
			isError: false,
			sendPointSheet: false
		});
		setLoadingButtonSave(false);
	};

	const renderDocumentStatus = (status: string) => {
		let colorStatus: string = (status === 'SIGNED' || status === 'COPY') ? colors['green-color'] :
			(status === 'WAITING' || status === 'OPENED') ? colors['blue-color'] : colors['red-color'];

		return (
			<span style={{ color: colorStatus, fontWeight: 'bold' }}>{t(`BIRDMAN.STATUS.${status}`)}</span>
		);
	};

	const renderSignatureLine = (signature: any) => {
		return (
			<Table.BodyItem key={`signature-${signature.id}`}>
				<Table.BodyItem.Item>{signature.subscriberName}</Table.BodyItem.Item>
				<Table.BodyItem.Item>{signature.subscriberEmail}</Table.BodyItem.Item>
				<Table.BodyItem.Item>{!!signature.employeeId ? t('GENERIC.TEXT.EMPLOYEE') : t('GENERIC.TEXT.LEADER')}</Table.BodyItem.Item>
				<Table.BodyItem.Item>{renderDocumentStatus(signature.status)}</Table.BodyItem.Item>
			</Table.BodyItem>
		);
	};

	const renderDocumentLine = (signatures: any[]) => {
		let document: any = signatures[0].document;

		return (
			<Table.BodyItem key={`document-${document.id}`}>
				<Table.BodyItem.Item><FontAwesomeIcon icon={['fas', 'file-pdf']} size='2x' /> {document.originalName}</Table.BodyItem.Item>
				<Table.BodyItem.Item>{renderDocumentStatus(document.status)}</Table.BodyItem.Item>
				<Table.BodyItem.Item>
					<Table defaul responsible>
						<Table.Header>
							<Table.HeaderItem>
								<Table.HeaderItem.Item>{t('GENERIC.TEXT.NAME')}</Table.HeaderItem.Item>
								<Table.HeaderItem.Item>{t('GENERIC.TEXT.EMAIL')}</Table.HeaderItem.Item>
								<Table.HeaderItem.Item>{t('USER.TEXT.GROUP')}</Table.HeaderItem.Item>
								<Table.HeaderItem.Item>{t('GENERIC.TEXT.STATUS')}</Table.HeaderItem.Item>
							</Table.HeaderItem>
						</Table.Header>
						<Table.Body>
							{signatures?.map(signature => renderSignatureLine(signature))}
						</Table.Body>
					</Table>
				</Table.BodyItem.Item>
				<Table.BodyItem.Item>
					<Button
						title={t('GENERIC.TEXT.DISPLAY')}
						primary
						sm
						icon="eye"
						action={() => { window.location.href = `/#/assinadorMF/documents/${document.id}/show` }} />
				</Table.BodyItem.Item>
			</Table.BodyItem>
		);
	};

	return (
		<Container className={styles['closed-periods-edit-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CLOSED_PERIODS.TEXT.UPDATE_CLOSING_PERIOD', undefined)} returnUrl="#/srepsMF/closedPeriods" />
				</Row.Item>
			</Row>
            {(closedPeriodStatus === 'success') &&
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel>
						<Row>
							<Row.Item lg={6} md={6} sm={12}>
								<Input type="text"
									disabled
									fullWidth
									value={inputValue}
									title={t('CLOSED_PERIODS.TEXT.NAME_FOR_CLOSED_PERIOD', undefined)}
									name="closedPeriodName"
									status={inputStatus}
									handleChange={() => { }} />
							</Row.Item>
							<Row.Item lg={3} md={3} sm={6}>
								<Select medium
									disabled
									fullWidth
									name="selectMonth"
									value={monthValue.value}
									title={t('GENERIC.TEXT.PERIOD', undefined)}
									options={listMonths}
									handleChange={() => { }} />
							</Row.Item>
							<Row.Item lg={3} md={3} sm={6}>
								<Select medium
									disabled
									fullWidth
									name="selectYear"
									value={yearValue.key}
									title={t('GENERIC.TEXT.YEAR', undefined)}
									options={listYears}
									handleChange={() => { }} />
							</Row.Item>
						</Row>
					</Panel>
				</Row.Item>
			</Row>
            }

			{(closedPeriodStatus === 'waiting') &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING', undefined)} />
						</Panel>
					</Row.Item>
				</Row>
			}
			{(closedPeriodStatus === 'success') &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Panel title={`${monthValue.value}/${yearValue.key}`} noTitleBorder noPadding>
							<Tabs activeTab={0}>
								<Tabs.Item title={t('GENERIC.TEXT.EMPLOYEES')}>
									<Container>
										<DualList
											titleAvailable={t('CABLE.AREA.NEW.AVAILABLE_EMPLOYEES', undefined)}
											titleSelected={t('CABLE.AREA.NEW.SELECTED_EMPLOYEES', undefined)}
											options={dualListOptions!}
											firstIcon="user"
											secondIcon="tools"
											thirdIcon="calendar"
											handleChange={handleChangeDualList} />
										<Container className={styles['actions']}>
											<Button danger md
												title={t('GENERIC.TEXT.CANCEL', undefined)}
												icon="times"
												className={styles['button-action']}
												action={() => { navigate('/srepsMF/closedPeriods') }} />
											<Button primary md
												title={t('GENERIC.TEXT.UPDATE', undefined)}
												icon="save"
												//disabled={inputStatus !== "success" || optionsSelected.length === 0}
												loading={loadingButtonSave}
												className={styles['button-action']}
												action={() => { checkWarningsAndErrors() }} />
										</Container>
									</Container>
								</Tabs.Item>

								<Tabs.Item title={t('GENERIC.TEXT.DOCUMENTS')}>
									<Container>
										<Table defaul responsible>
											<Table.Header>
												<Table.HeaderItem>
													<Table.HeaderItem.Item>{t('GENERIC.TEXT.DOCUMENT')}</Table.HeaderItem.Item>
													<Table.HeaderItem.Item>{t('GENERIC.TEXT.STATUS')}</Table.HeaderItem.Item>
													<Table.HeaderItem.Item>{t('GENERIC.TEXT.SUBSCRIPTIONS')}</Table.HeaderItem.Item>
													<Table.HeaderItem.Item>{t('GENERIC.TEXT.ACTIONS')}</Table.HeaderItem.Item>
												</Table.HeaderItem>
											</Table.Header>

											{documents.length === 0 &&
												<Table.Body>
													<Table.BodyItem>
														<Table.BodyItem.Item colSpan={4} align="center">{t('BIRDMAN.TEXT.EMPTY_LIST')}</Table.BodyItem.Item>
													</Table.BodyItem>
												</Table.Body>
											}

											{documents.length > 0 &&
												<Table.Body>
													{documents?.map(document => renderDocumentLine(document))}
												</Table.Body>
											}
										</Table>
									</Container>
								</Tabs.Item>
							</Tabs>
						</Panel>
					</Row.Item>
				</Row>
			}

			{(closedPeriodStatus === 'emptyList') &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Panel title={`${monthValue.value}/${yearValue}`} noTitleBorder>
							<Container>
								<TextInformation
									text={`${t('CLOSED_PERIODS.TEXT.NO_EMPLOYEE', undefined)}  ${`${monthValue.value}/${yearValue}`}`}
									icon="search" />
							</Container>
						</Panel>
					</Row.Item>
				</Row>
			}

			{modalConfirmation.show &&
				<Modal title={modalConfirmation.isError ? t('GENERIC.TEXT.ERROR_OCURRED') : t('GENERIC.TEXT.CONFIRMATION')}
					xs
					showClose
					handleClose={() => closeModalConfirmation()}>

					{!modalConfirmation.isError && !modalConfirmation.sendPointSheet &&
						<p>{t('CLOSED_PERIODS.TEXT.WARNING_EMPLOYEES_WITH_GAP')}</p>
					}

					{modalConfirmation.isError && !modalConfirmation.sendPointSheet &&
						<p>{`${t('CLOSED_PERIODS.TEXT.CLOSED_PERIOD_CONTAINS_ERRORS')} ${t('GENERIC.TEXT.CHECK_ERROR_LIST')}:`}</p>
					}

					{!modalConfirmation.sendPointSheet &&
						<ul>
							{listWarning.map(item => {
								return <li key={`list-warning-${item}`}>{item}</li>
							})}
						</ul>
					}

					{modalConfirmation.isError && !modalConfirmation.sendPointSheet &&
						<Container style={{ textAlign: 'center' }}>
							<Button primary md
								title="OK"
								action={() => { closeModalConfirmation() }} />
						</Container>
					}

					{!modalConfirmation.isError && !modalConfirmation.sendPointSheet &&
						<Container>
							<p>{t('GENERIC.TEXT.ARE_YOU_SURE_YOU_WANT_TO_CONTINUE')}</p>

							<Container style={{ textAlign: 'center' }}>
								<Button danger md
									title={t('GENERIC.TEXT.CANCEL', undefined)}
									icon="times"
									action={() => { closeModalConfirmation() }} />
								<Button primary md
									title={t('GENERIC.BUTTON.SAVE.TEXT', undefined)}
									icon="save"
									loading={loadingButtonSave}
									action={() => {
										setModalConfirmation({
											show: true,
											isError: false,
											sendPointSheet: true
										})
									}} />
							</Container>
						</Container>
					}

					{!modalConfirmation.isError && modalConfirmation.sendPointSheet &&
						<Container>
							<AlertTiny
								warn
								icon="exclamation-circle"
								title={t('GENERIC.TEXT.ATTENTION')}
								text={t('CLOSED_PERIODS.TEXT.SEND_POINT_SHEET_INFORMATION')} />

							<p>{t('CLOSED_PERIODS.TEXT.SEND_POINT_SHEET_QUESTION')}</p>

							<Container style={{ textAlign: 'center' }}>
								<Button danger md
									title={t('GENERIC.TEXT.NO', undefined)}
									icon="times"
									loading={loadingButtonSave}
									action={() => { updateClosedPeriod(false) }} />
								<Button primary md
									title={t('GENERIC.TEXT.YES', undefined)}
									icon="save"
									loading={loadingButtonSave}
									action={() => { updateClosedPeriod(true) }} />
							</Container>
						</Container>
					}
				</Modal>
			}

			{toastAlert.show &&
				<Toast
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION', undefined)}
					icon={toastAlert.danger ? 'exclamation-circle' : 'check'}
					positionTopRight
					closeChange={() => setToastAlert({ ...toastAlert, show: false })}
					message={toastAlert.message}
					showTime={5000} />
			}
		</Container>
	);
}

export default Edit;