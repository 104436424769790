import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvatarName, Button, Loading, MapView, Modal, PageTitle, Panel, Row, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';

import styles from './List.module.scss';
import { fetchAllPointLimitAreas, deleteLimitArea } from '../../../../services/pointLimitArea.services';
import { PointLimitArea } from '../../../../models/PointLimitArea';
import { useNavigate } from 'react-router-dom';

import imageUser from '../../../../assets/images/avatar.png';

import {translation} from '@optimuminterfaces/revex-react-components/dist/translation/index';

interface ListProps{
	Container?: any
}
const List = ({Container='div'}: ListProps) => {

	let navigate = useNavigate();

	const URL_MICRO_FRONT = process.env.REACT_APP_URL;

	const [pointLimitAreaStatus,setPointLimitAreaStatus] = useState<string>();
	const [pointLimitAreas, setPointLimitAreas] = useState<PointLimitArea[]>();
	const [showModalDetailLimitArea, setShowModalDetailLimitArea] = useState<boolean>(false);
	const [detailLimitArea, setDetailLimitArea] = useState<PointLimitArea>();
	const [deleteArea, setDeleteArea] = useState<PointLimitArea>();
	const [showModalDeleteLimitArea, setShowModalDeleteLimitArea] = useState(false);
	const [loadingButtonDeleteLimitArea, setLoadingButtonDeleteLimitArea] = useState(false);
	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false});

	useEffect(() => {
		(async () => {
			setPointLimitAreaStatus('waiting');
			try{
				const jsonReturned =  await fetchAllPointLimitAreas();
				if(jsonReturned){
					setPointLimitAreas(jsonReturned.pointLimitAreas);
					setPointLimitAreaStatus('success');
				}else{
					setPointLimitAreaStatus('error');
				}
			}catch(error){
				console.log(error);
			}

		})();
    }, []);

	const showOrHideModalDetailLimitArea = () => {
		setShowModalDetailLimitArea(!showModalDetailLimitArea);
	}

	const showOrHideModalDeleteLimitArea = () => {
		setShowModalDeleteLimitArea(!showModalDeleteLimitArea);
	}

	const deleteLimit = async () => {
		setLoadingButtonDeleteLimitArea(true);

		try{
			const jsonReturned =  await deleteLimitArea(deleteArea!.id);
			if(jsonReturned){
				removeListById(deleteArea!.id);
				setToastAlert({...toastAlert, message:translation('CABLE.AREA.LIST.DELETE_CONFIRMED',undefined),show: true});
			}else{
				setPointLimitAreaStatus('error');
				setToastAlert({...toastAlert, message:translation('CABLE.AREA.LIST.DELETE_ERROR',undefined), danger: true, show: true});
			}
			setLoadingButtonDeleteLimitArea(false);
			showOrHideModalDeleteLimitArea();
		}catch(error){
			console.log(error);
		}

		
	}

	const removeListById = (id:string) => {
        let newList = [];

        for (let i = 0; i < pointLimitAreas!.length; i++) {
            if (pointLimitAreas![i].id !== id) {
                newList.push(pointLimitAreas![i]);
            }
        }

        setPointLimitAreas([...newList]);
    };

	const renderArea = () => {

		return (
			<Container>
			
			<Row>
				{pointLimitAreas?.map(pointLimitArea => 
					<Row.Item sm={3} md={3} lg={3} key={pointLimitArea.id}>
						<Container className={styles['map-view-box']}>
							<Container className={styles['map-view']}>
								{pointLimitArea.lat && pointLimitArea.lng && 
									<MapView lng={pointLimitArea.lng} lat={pointLimitArea.lat} radius={pointLimitArea.radius} height='140px' id={pointLimitArea.id}/>
								}
								{!pointLimitArea.lat && !pointLimitArea.lng && 
									<Container className={styles['no-location']}>
										<FontAwesomeIcon icon='map-marker-alt-slash' />
									</Container>
								}
							</Container>
							<Container className={styles['map-view-detalhes']}>
								<Container className={styles['title']}>{pointLimitArea.name}</Container>
								<Container><FontAwesomeIcon icon='draw-circle' /> { pointLimitArea.radius?`${pointLimitArea.radius} ${translation('CABLE.AREA.LIST.METERS',undefined)}`:' - '}</Container>
								<Container><FontAwesomeIcon icon='users' /> {`${pointLimitArea.employeesInArea.length} ${translation('GENERIC.TEXT.EMPLOYEES',undefined)}`}</Container>
							</Container>
							<Container className={styles['map-view-actions']}>
								<Button xs 
										className={styles['button-action']} 
										primary 
										action = {() => {navigate(`/srepsMF/limitAreas/${pointLimitArea.id}/edit`)}}
										title={translation('GENERIC.TEXT.EDIT',undefined)}/>
								<Button xs 
										className={styles['button-action']} 
										primary
										action = {() => {setDetailLimitArea(pointLimitArea); showOrHideModalDetailLimitArea();}}
										title={translation('CABLE.AREA.LIST.VISUALIZE',undefined)}/>
								<Button xs 
										className={styles['button-action']} 
										danger
										action = {() => {setDeleteArea(pointLimitArea); showOrHideModalDeleteLimitArea();}}
										title={translation('GENERIC.TEXT.DELETE',undefined)}/>
							</Container>
						</Container>
					</Row.Item>
				)}
				
			</Row>
			</Container>
		)
	}

	return(
		<Container className={styles['limit-areas-list-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={translation('CABLE.AREA.LIST.TITLE',undefined)} />
				</Row.Item>
			</Row>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel noTitleBorder>
						<Row>
							<Row.Item lg={12} md={12} sm={12}>
								<Container className={styles['group-buttons']}>
									<Button primary md
										title={translation('CABLE.AREA.LIST.NEW_AREA',undefined)}
										icon="plus"
										action={() => { navigate('/srepsMF/limitAreas/new')}} />
								</Container>
							</Row.Item>
						</Row>
					</Panel>
				</Row.Item>
			</Row>

			{pointLimitAreas &&  pointLimitAreaStatus === 'success' && pointLimitAreas.length === 0 &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel noPadding>
							<TextInformation 
								icon="search"
								text={translation('CABLE.AREA.LIST.AREA_NOT_FOUND',undefined)}/>
						</Panel>
					</Row.Item>
				</Row>
			}

			{(pointLimitAreaStatus === 'waiting') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={translation('GENERIC.TEXT.LOADING', undefined)}/>
						</Panel>
					</Row.Item>
				</Row>
			}
			{(pointLimitAreaStatus === 'error') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation 
								icon="exclamation-circle"
								text={translation('GENERIC.TEXT.UNABLE_RETURN',undefined)} />
						</Panel>
					</Row.Item>
				</Row>
			}
			{(pointLimitAreas && pointLimitAreaStatus === 'success' &&  pointLimitAreas.length > 0) &&
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Panel>
							{renderArea()}
						</Panel>
					</Row.Item>
				</Row>
			}

			{showModalDetailLimitArea &&
                <Modal title={`Detalhes área ${detailLimitArea!.name}`} showClose bg handleClose={() => { showOrHideModalDetailLimitArea() }}>
                    <Container className={styles['modal-details-limit-area']}>
							<Container className={styles['map-view']}>
								<MapView lng={detailLimitArea!.lng} lat={detailLimitArea!.lat} radius={detailLimitArea!.radius} height='250px' id={`modal_${detailLimitArea!.id}`}/>
							</Container>
							<Container className={styles['employees']}>
								<h3>Colaboradores</h3>
									{detailLimitArea?.employeesInArea.map(employee => {
										return (
											<Row.Item sm={6} md={3} lg={3}>
												<AvatarName text={employee.name} 
															subText={employee.occupation?.name} 
															color=''
															image={`${URL_MICRO_FRONT}${imageUser}`}
															size='s' />
											</Row.Item>
										);
									})}
							</Container>
                    </Container>                   
                </Modal>
            }

			{showModalDeleteLimitArea &&
                <Modal title="Confirmação" showClose xs handleClose={() => { showOrHideModalDeleteLimitArea() }}>
                    <Container className={styles['modal-delete']}>
                        <p>{translation('CABLE.AREA.LIST.DELETE_CONFIRMATION',undefined)} <strong>{deleteArea!.name}</strong>?</p>
                        <Button primary md
                            title={translation('GENERIC.TEXT.KEEP', undefined)}
                            icon="undo"
							className={styles['modal-delete-action']}
                            action={() => { showOrHideModalDeleteLimitArea() }} />
                        <Button danger md
                            title={translation('GENERIC.TEXT.DELETE', undefined)}
                            icon="trash"
							className={styles['modal-delete-action']}
                            loading={loadingButtonDeleteLimitArea}
                            action={() => { deleteLimit() }} />
                    </Container>                   
                </Modal>
            }

			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={translation('CABLE.AREA.LIST.OPERATION', undefined)}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}
		</Container>
	);
}

export default List;