import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import { Alert, AlertTiny, Animate, 
		Button, DateTimePicker, Input,
		Loading, Label, Modal, PageTitle, Panel, 
		Row, Switch ,Table, Tabs, DualList, Toast } from '@optimuminterfaces/revex-react-components/dist/components/index';
import { formatLocalDate, formatLocalTime } from "@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil";

// Import Api
import { deleteSrepSplitHour, fetchSrepParamsById, postNewSrepSplitHour, postUpdateSrepParams } from "../../../../services/srepParams.services";

// Import models
import { SrepParam } from "../../../../models/SrepParam";
import { Employee } from "../../../../models/Employee";
import { SrepSplitHour } from "../../../../models/SrepSplitHour";

import NewSplitHour from "./NewSplitHour/NewSplitHour";

import styles from './Edit.module.scss';
import { copyObjectJSON } from "../../../../utils/DeveloperUtil";

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

interface EditProps{
	Container?: any,
	Label?: string,
}
interface SrepConfigurationModel{
	srepParam: SrepParam,
	employees: Employee[],
	srepSplitHours: SrepSplitHour[]
}

interface SrepParamPlusEmployeeModel extends SrepParam{
	employeesToUpdate: string | null
}


export const isBeforeDate = (firstDate:string, secondDate: string, dateFormat = 'YYYY-MM-DD') => {
	let date1 = moment.utc(firstDate, dateFormat);
	let date2 = moment.utc(secondDate, dateFormat);

	if (date1 < date2) {
		return true;
	} else {
		return false;
	}
}

const Edit = ({ Container = 'div' }: EditProps) => {

	let navigate = useNavigate()

	const [srepConfigurations, setSrepConfigurations] = useState<SrepConfigurationModel>();
	const [srepParamsStatus,setSrepParamsStatus] = useState('waiting');
	const [configuration, setConfiguration] = useState<SrepParamPlusEmployeeModel>();
    const [splitDaytime, setSplitDayTime] = useState<any[]>([]);
    const [splitNight, setSplitNight] = useState<any[]>([]);
    const [splitDatabase, setSplitDatabase] = useState<any[]>([]);
    const [employees, setEmployees] = useState<any[]>();
    const [preFilledPoints, setPreFilledPoints] = useState<boolean>(false);
    const [preFilledPointEmployees, setPreFilledPointEmployees] = useState<any[]>([]);
    const [employeesToUpdate, setEmployeesToUpdate] = useState<any[]>();
    const [showModalNewSplitHours, setShowModalNewSplitHours] = useState(false);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [showModalUpdateEmployees, setShowModalUpdateEmployees] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const [typeSplitHour, setTypeSplitHour] = useState<string>();
    const [timeInit, setTimeInit] = useState<string>();
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [textAlertError, setTextAlertError] = useState<string | null>();

	//Parametros da URL
	const [params, setParams] = useState(useParams());

    const textModal = t('SREP.EDIT.RECALCULATE_HOUR_BANK_INFO');


	useEffect(()=>{
		(async () => {
			try{
				const jsonReturned =  await fetchSrepParamsById(params.id!);
				if(jsonReturned){
					setSrepParamsStatus('success');
					setSrepConfigurations(jsonReturned);
					
				}else{
					setSrepParamsStatus('error');
				}
			}catch(error){
				console.log(error);
			}

		})();
	}, []);

	useEffect(() => {
        if (srepConfigurations && srepParamsStatus === 'success') {
			let employees: any[] = [];
            let employeeSelecteds: any[] = [];

			srepConfigurations.employees.forEach(employee => {
                let e = {
					key: employee.id,
					selected: srepConfigurations.srepParam.employees===null ? false : srepConfigurations.srepParam.employees?.includes(employee.id),
					values: [employee.name.toLowerCase(), employee.occupation?.name.toLowerCase()]
				};

				employees.push({ ...e });
                
                if (e.selected) {
                    e.selected = srepConfigurations.srepParam.preFilledPointEmployees === null ? false : srepConfigurations.srepParam.preFilledPointEmployees?.includes(e.key);

                    employeeSelecteds.push({ ...e });
                }
			});

            let mapConfiguration: SrepParamPlusEmployeeModel = {
                name: srepConfigurations.srepParam.name,
                startDate: !!srepConfigurations.srepParam.startDate ? formatLocalDate(srepConfigurations.srepParam.startDate, true, undefined, undefined) : null,
                endDate: !!srepConfigurations.srepParam.endDate ? formatLocalDate(srepConfigurations.srepParam.endDate, true, undefined, undefined) : null,
                geolocation: srepConfigurations.srepParam.geolocation,
                hoursBank: srepConfigurations.srepParam.hoursBank,
                hoursBankStart: !!srepConfigurations.srepParam.hoursBankStart ? formatLocalDate(srepConfigurations.srepParam.hoursBankStart, true, undefined, undefined) : null,
                hoursBankLimit: srepConfigurations.srepParam.hoursBankLimit,
                hoursBankAutoIncrement: srepConfigurations.srepParam.hoursBankAutoIncrement,
                hoursBankAutoDecrement: srepConfigurations.srepParam.hoursBankAutoDecrement,
                overnight: srepConfigurations.srepParam.overnight,
                overnightExtend: srepConfigurations.srepParam.overnightExtend,
                overnightStart: !!srepConfigurations.srepParam.overnightStart ? formatLocalTime(srepConfigurations.srepParam.overnightStart) : null,
                overnightEnd: !!srepConfigurations.srepParam.overnightEnd ? formatLocalTime(srepConfigurations.srepParam.overnightEnd) : null,
                tolerance: srepConfigurations.srepParam.tolerance,
				useTolerancePoint: srepConfigurations.srepParam.useTolerancePoint,
                tolerancePoint: srepConfigurations.srepParam.tolerancePoint,
                toleranceDay: srepConfigurations.srepParam.toleranceDay,
                percentageHoliday: srepConfigurations.srepParam.percentageHoliday,
                percentageDayOff: srepConfigurations.srepParam.percentageDayOff,
                holidayNotWorkedOff: srepConfigurations.srepParam.holidayNotWorkedOff,
                percentageInterval: srepConfigurations.srepParam.percentageInterval,
                employees: srepConfigurations.srepParam.employees,
                employeesToUpdate: null,
				allowsRegistrationOutsideArea: srepConfigurations.srepParam.allowsRegistrationOutsideArea,
                endPeriodWithoutEvent: srepConfigurations.srepParam.endPeriodWithoutEvent,
                endPeriodWithPendingJustifications: srepConfigurations.srepParam.endPeriodWithPendingJustifications,
                numberJustificationsAllowed: srepConfigurations.srepParam.numberJustificationsAllowed,
                preFilledPointEmployees: srepConfigurations.srepParam.preFilledPointEmployees
            };

            if (!!mapConfiguration.preFilledPointEmployees) {
                setPreFilledPoints(true);
            }

            setConfiguration(mapConfiguration);
            setEmployees(employees);
            setPreFilledPointEmployees(employeeSelecteds);

            if (!!srepConfigurations.srepSplitHours && srepConfigurations.srepSplitHours.length > 0) {
                let listDaytime: any[] = [];
                let listNight = [];
                let listDatabase = [];

                for (var i = 0; i < srepConfigurations.srepSplitHours.length; i++) {
                    if (srepConfigurations.srepSplitHours[i].type === 'DAYTIME') {
                        listDaytime.push(srepConfigurations.srepSplitHours[i]);
                    } else if (srepConfigurations.srepSplitHours[i].type === 'NIGHT') {
                        listNight.push(srepConfigurations.srepSplitHours[i]);
                    } else if (srepConfigurations.srepSplitHours[i].type === 'DATABASE') {
                        listDatabase.push(srepConfigurations.srepSplitHours[i]);
                    }
                }

                setSplitDayTime(listDaytime);
                setSplitNight(listNight);
                setSplitDatabase(listDatabase);
            }

        }
    }, [srepConfigurations]);

    useEffect(() => {
        // Caso lista de colaboradores selecionados sofra alteração, deve atualizar a lista de colaboradores com pontos pré-preenchidos
        if (!!configuration && !!employees && employees.length > 0) {
            let list: any[] = employees;
            let listSelecteds: any[] = [];
            let listEmployeeIds: any[] = [];

            list.forEach(item => {
                if (item.selected) {
                    let i: any = { ...item };
    
                    if (!!configuration.preFilledPointEmployees && 
                        configuration.preFilledPointEmployees.includes(item.key)) {
                        i.selected = true
                        listEmployeeIds.push(item.key);
    
                    } else {
                        i.selected = false;
                    }
    
                    listSelecteds.push(i);
                }
            });

            setPreFilledPointEmployees(listSelecteds);
            setConfiguration({ ...configuration, preFilledPointEmployees: listEmployeeIds.join(',') });
        }
    }, [employees]);


	useEffect(() => {
		const validateForm = (configuration:SrepParam) => {
			setShowAlertSuccess(false);
	
			if (configuration.name.length === 0) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.REQUIRED_FIELD',undefined,`"${t('SREP.LIST.CONFIGURATION_NAME')}"`));
			}else if (configuration.name.length > 150) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.CHARACTER_LIMIT',undefined,`"${t('SREP.LIST.CONFIGURATION_NAME')}"`,150));
			} else if (!configuration.startDate) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.REQUIRED_FIELD',undefined,`"${t('GENERIC.TEXT.START_DATE')}"`));
			} else if (configuration.endDate && isBeforeDate(configuration.endDate, configuration.startDate, 'DD/MM/YYYY')) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.END_DATE_BEFORE_START_DATE'));
			} else if (configuration.tolerance == true && !configuration.tolerancePoint) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.REQUIRED_FIELD',undefined,`"${t('SREP.EDIT.POINT_REGISTRATION_TOLERANCE')}"`));
			} else if (configuration.tolerance == true && (configuration.tolerancePoint < 0 || configuration.tolerancePoint > 999)) {
				setDisabledButton(true);
				setTextAlertError( t('GENERIC.FIELD_VALIDATION.RANGE_LIMIT',undefined,"1","999",`"${t('SREP.EDIT.POINT_REGISTRATION_TOLERANCE')}"`));
			} else if (configuration.tolerance == true && !configuration.toleranceDay) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.REQUIRED_FIELD',undefined,`"${t('SREP.EDIT.DAILY_TOLERANCE')}"`));
			} else if (configuration.tolerance === true && (configuration.toleranceDay < 0 || configuration.toleranceDay > 999)) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.RANGE_LIMIT',undefined,"1","999",`"${t('SREP.EDIT.DAILY_TOLERANCE')}"`));
			} else if (!configuration.percentageHoliday) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.REQUIRED_FIELD',undefined,`"${t('GENERIC.TEXT.WORK_HOLIDAY')}"`));
			} else if (configuration.percentageHoliday < 1 || configuration.percentageHoliday > 999) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.RANGE_LIMIT',undefined,"1","999",`"${t('GENERIC.TEXT.WORK_HOLIDAY')}"`));
			} else if (!configuration.percentageDayOff) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.REQUIRED_FIELD',undefined,`"${t('GENERIC.TEXT.DAY_OFF_WORKED')}"`));
			} else if (configuration.percentageDayOff < 1 || configuration.percentageDayOff > 999) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.RANGE_LIMIT',undefined,"1","999",`"${t('GENERIC.TEXT.DAY_OFF_WORKED')}"`));
			} else if (!configuration.percentageInterval) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.REQUIRED_FIELD',undefined,`"${t('SREP.EDIT.EXTRA_BREAK')}"`));
			} else if (configuration.percentageInterval < 1 || configuration.percentageInterval > 999) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.RANGE_LIMIT',undefined,"1","999",`"${t('SREP.EDIT.EXTRA_BREAK')}"`))
			} else if (configuration.hoursBank === true && configuration.hoursBankStart.length === 0) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.REQUIRED_FIELD',undefined,`"${t('SREP.EDIT.START_HOUR_BANK')}"`));
			} else if (configuration.hoursBank === true && !configuration.hoursBankLimit) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.REQUIRED_FIELD',undefined,`"${t('SREP.EDIT.EXPIRATION_TIME_HOUR_BANK')}"`));
			} else if (configuration.hoursBank === true && (configuration.hoursBankLimit < 1 || configuration.hoursBankLimit > 24)) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.RANGE_LIMIT',undefined,"1","24",`"${t('SREP.EDIT.EXPIRATION_TIME_HOUR_BANK')}"`));
			} else if (configuration.overnightStart.length === 0) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.REQUIRED_FIELD',undefined,`"${t('SREP.EDIT.NIGHT_START_TIME')}"`));
			} else if (configuration.overnightEnd.length === 0) {
				setDisabledButton(true);
				setTextAlertError(t('GENERIC.FIELD_VALIDATION.REQUIRED_FIELD',undefined,`"${t('SREP.EDIT.NIGHT_END_TIME')}"`));
			} else if (configuration.numberJustificationsAllowed < 1) {
                setDisabledButton(true);
                setTextAlertError(t('GENERIC.FIELD_VALIDATION.MINIMUM_LIMIT', undefined, 1, t('SREP.EDIT.INPUT_NUMBER_JUSTIFICATIONS_ALLOWED')));
            } else {
				setDisabledButton(false);
				setTextAlertError(null);
			}
		};
		
        if (!!configuration) {
            validateForm(configuration);
        }
    }, [configuration]);

	const update = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setLoadingButton(true);
		setDisabledButton(true);
		setShowAlertSuccess(false);

        if (configuration!.hoursBank && (!!configuration!.employees && configuration!.employees.length > 0)) {
            let employeeList = employees?.filter(option => option.selected === true);
            setEmployeesToUpdate(employeeList);

            let selectedEmployeeIds = employeeList?.map(option => option.key);
            setConfiguration({ ...configuration!, employeesToUpdate: selectedEmployeeIds!.join(',') });

            setShowModalConfirm(true);
        } else {
			try{
				const jsonReturned =  await postUpdateSrepParams(params.id!, configuration);
				if(jsonReturned){
					setShowAlertSuccess(true);
				}
				
				setLoadingButton(false);
				setDisabledButton(false);
			}catch(error){
				console.log(error)
			}
			
            //dispatch(updateSrepConfiguration(srepConfiguration.srepConfiguration.id, configuration, callBackUpdateSuccess));
        }
    };

	const updateWithoutEmployeesToRecalculate = async (e: React.ChangeEvent<HTMLInputElement>) => {
        //e.preventDefault();
        setShowModalConfirm(false);
        let configurationToUpdate = {...configuration};
        configurationToUpdate.employeesToUpdate = null;

		try{
			const jsonReturned =  await postUpdateSrepParams(params.id!, configurationToUpdate);
			if(jsonReturned){
				setShowAlertSuccess(true);
			}
			
			setLoadingButton(false);
			setDisabledButton(false);
		}catch(error){
			console.log(error)
		}
        //dispatch(updateSrepConfiguration(srepConfiguration.srepConfiguration.id, configurationToUpdate, callBackUpdateSuccess));
    };

    const updateWithEmployeesToRecalculate = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowModalUpdateEmployees(false);
        let configurationToUpdate = {...configuration};

        try {
            const jsonReturned = await postUpdateSrepParams(params.id!, configurationToUpdate);
            if (jsonReturned) {
                setShowAlertSuccess(true);
            }

            setLoadingButton(false);
            setDisabledButton(false);
        } catch (error) {
            console.log(error);
        }
    };

	const srepConfigurationsPage = () => {
        navigate('/srepsMF/settings');
    };

    const handleChange = (e?: React.ChangeEvent<HTMLInputElement>| undefined, 
							name?:string, 
							value?:string | number | boolean) => {

        if (e) {
            const target = e.target;
            value = target.type === 'checkbox' ? target.checked : (target.type === 'number' && !!target.value) ? parseInt(target.value) : target.value;
            name = target.name;
        }

        setConfiguration({ ...configuration!, [name!]: value });
        console.log(name, value)

    }

    const handleChangePreFilledPoints = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.checked;

        setPreFilledPoints(value);

        if (!value) {
            setConfiguration({ ...configuration!, preFilledPointEmployees: null });

            let list: any[] = preFilledPointEmployees;
            list.forEach(item => item.selected = false);
            setPreFilledPointEmployees(list);
        }
    };

    const handleChangeDualList = (returnOptions:any) => {
        setEmployees(returnOptions);
        let selectedEmployeeIds = returnOptions.filter((option:any) => option.selected === true).map((option:any) => option.key);
        setConfiguration({ ...configuration!, employees: selectedEmployeeIds.join(',') });
    };

    const handleChangeDualListEmployeesToUpdate = (returnOptions:any) => {
        let selectedEmployeeIds = returnOptions.filter((option:any) => option.selected === true).map((option:any) => option.key);
        setConfiguration({ ...configuration!, employeesToUpdate: selectedEmployeeIds.join(',') });
    };

    const handleChangeDualListPreFilledPointEmployees = (returnOptions: any) => {
        let selectedEmployeeIds = returnOptions
            .filter((option: any) => option.selected === true)
            .map((option: any) => option.key);

        setConfiguration({ ...configuration!, preFilledPointEmployees: selectedEmployeeIds.join(',') });
    };

    const showModal = (type:string) => {
        if (String(type).toUpperCase() === 'DAYTIME') {
            if (splitDaytime!.length === 0) {
                setTimeInit('00:00');
            } else {
                let timeEndLastSplit = formatLocalTime(splitDaytime![splitDaytime!.length - 1].hourEnd);
                setTimeInit(timeEndLastSplit);
            }
        } else if (String(type).toUpperCase() === 'NIGHT') {
            if (splitNight?.length === 0) {
                setTimeInit('00:00');
            } else {
                let timeEndLastSplit = formatLocalTime(splitNight![splitNight!.length - 1].hourEnd);
                setTimeInit(timeEndLastSplit);
            }
        } else if (String(type).toUpperCase() === 'DATABASE') {
            if (splitDatabase?.length === 0) {
                setTimeInit('00:00');
            } else {
                let timeEndLastSplit = formatLocalTime(splitDatabase![splitDatabase.length - 1].hourEnd);
                setTimeInit(timeEndLastSplit);
            }
        }

        setTypeSplitHour(type);
        setShowModalNewSplitHours(true);
    }

	const saveSplitHour = async (splitHour:SrepSplitHour) => {
		try{
			const jsonReturned =  await postNewSrepSplitHour({
				timeInit: splitHour.hourInit, timeEnd: splitHour.hourEnd, percentage: splitHour.percentage,
				type: typeSplitHour, srepParamId: params.id
			});
			if(jsonReturned){
				let srepSplitHour = jsonReturned.data;

                if (srepSplitHour.type === 'DAYTIME') {
                    let listDaytime = copyObjectJSON(splitDaytime);
                    listDaytime.push(srepSplitHour);
                    setSplitDayTime(copyObjectJSON(listDaytime));
                } else if (srepSplitHour.type === 'NIGHT') {
                    let listNight = copyObjectJSON(splitNight);
                    listNight.push(srepSplitHour);
                    setSplitNight(copyObjectJSON(listNight));
                } else if (srepSplitHour.type === 'DATABASE') {
                    let listDatabase = copyObjectJSON(splitDatabase);
                    listDatabase.push(srepSplitHour);
                    setSplitDatabase(copyObjectJSON(listDatabase));
                }
				setShowModalNewSplitHours(false);
			}else{
				setShowModalNewSplitHours(false);
			}
		}catch(error){
			console.log(error);
		}
    }

    const deleteSplitHour = async (id: string, type: string) => {
		try{
			const jsonReturned =  await deleteSrepSplitHour(id);
			if(jsonReturned){
				if (type === 'DAYTIME') {
					let listDaytime:any[] = [];
					splitDaytime.map(daytime => {
						if (daytime.id !== id) {
							listDaytime.push(daytime);
						}
					});
					setSplitDayTime(copyObjectJSON(listDaytime));
				} else if (type === 'NIGHT') {
					let listNight:any[] = [];
					splitNight.map(night => {
						if (night.id !== id) {
							listNight.push(night);
						}
					});
					setSplitNight(copyObjectJSON(listNight));
				} else if (type === 'DATABASE') {
					let listDatabase:any[] = [];
					splitDatabase.map(database => {
						if (database.id !== id) {
							listDatabase.push(database);
						}
					});
					setSplitDatabase(copyObjectJSON(listDatabase));
				}
			}else{

			}
		}catch(error){
			console.log(error)
		}
    };

	const renderSrepSplit = (srepSplit:SrepSplitHour, index:number) => {
        return (
            <Table.BodyItem key={`srep-split-${srepSplit.id}`}>
                <Table.BodyItem.Item>{formatLocalTime(srepSplit.hourInit)}</Table.BodyItem.Item>
                <Table.BodyItem.Item>{formatLocalTime(srepSplit.hourEnd)}</Table.BodyItem.Item>
                <Table.BodyItem.Item>{srepSplit.percentage}</Table.BodyItem.Item>
                <Table.BodyItem.Item>
                    {((srepSplit.type === 'DAYTIME' && index === splitDaytime!.length - 1) || (srepSplit.type === 'NIGHT' && index === splitNight!.length - 1) ||
                        (srepSplit.type === 'DATABASE' && index === splitDatabase!.length - 1)) &&
                        <Button
                            title={t('GENERIC.BUTTON.DELETE.TEXT')}
                            danger
                            sm
                            icon="trash"
                            action={() => deleteSplitHour(srepSplit.id!, srepSplit.type!)} />
                    }
                </Table.BodyItem.Item>
            </Table.BodyItem>
        )
    };

    const renderFormGeneral = () => {
        return (
            <>
                <Row>
                    <Row.Item lg={8} md={8} sm={8}>
                        <Input
                            medium
                            value={configuration!.name}
                            title={t('SREP.LIST.CONFIGURATION_NAME')}
                            name="name"
                            placeholder="Nome"
                            fullWidth
                            status={!!configuration!.name && configuration!.name.length <= 150 ? 'success' : 'error'}
                            handleChange={(e) => handleChange(e)} />
                    </Row.Item>
                    <Row.Item lg={2} md={2} sm={2}>
                        <DateTimePicker
                            title={t('SREP.EDIT.START_OF_CONFIGURATION')}
                            name="startDate"
                            value={configuration!.startDate}
                            type="date"
                            mask
							noSeconds={false}
							forceOpenPicker={false}
							timeInverseColor
                            fullWidth
                            status={!!configuration!.startDate ? 'success' : 'error'}
                            handleChange={(e) => { handleChange(undefined, "startDate", e) }} />
                    </Row.Item>
                    <Row.Item lg={2} md={2} sm={2}>
                        <DateTimePicker
                            title={t('SREP.EDIT.END_OF_CONFIGURATION')}
                            name="endDate"
                            value={configuration!.endDate}
                            type="date"
                            mask
							noSeconds={false}
							forceOpenPicker={false}
							timeInverseColor
                            fullWidth
                            handleChange={(e) => { handleChange(undefined, "endDate", e) }} />
                    </Row.Item>
                </Row>
            </>
        )
    }

    const renderFormTolerance = () => {
        return (
            <>
                <Row>
                    <Row.Item lg={12} md={12} sm={12}>
                        <Container className={styles['point-category-title']}>Tolerância</Container>
                    </Row.Item>
                    <Row.Item lg={12} md={12} sm={12}>
                        <Switch
                            value={configuration!.tolerance}
                            iconOff="times"
                            name="tolerance"
                            iconOn="check"
                            colorOn="#3b51ed"
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            text={t('SREP.EDIT.USE_TOLERANCE')}
                            sm />
                    </Row.Item>
                </Row>
                <Row>
                    {configuration!.tolerance &&
                        <>
							<Row.Item lg={12} md={12} sm={12}>
								<Switch
								value={configuration!.useTolerancePoint}
								iconOff="times"
								name="useTolerancePoint"
								iconOn="check"
								colorOn="#3b51ed"
								handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
								text={t('SREP.EDIT.USE_TOLERANCE_POINT')}
								sm />
                    		</Row.Item>
                            {configuration!.useTolerancePoint && <Row.Item lg={3} md={3} sm={3}>
                                <Input
                                    type="number"
                                    medium
                                    value={configuration!.tolerancePoint}
                                    title={t('SREP.EDIT.POINT_REGISTRATION_TOLERANCE')}
                                    name="tolerancePoint"
                                    placeholder={t('SREP.EDIT.POINT_REGISTRATION_TOLERANCE')}
                                    fullWidth
									disabled={!configuration!.useTolerancePoint}
                                    minValue={0}
                                    maxValue={999}
                                    status={(!!configuration!.tolerancePoint && 
												configuration!.tolerancePoint >= 0 && 
												configuration!.tolerancePoint <= 999) ? 'success' : 'error'}
                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)} />
                            </Row.Item>
							}
                            <Row.Item lg={3} md={3} sm={3}>
                                <Input
                                    type="number"
                                    medium
                                    value={configuration!.toleranceDay}
                                    title={t('SREP.EDIT.DAILY_TOLERANCE')}
                                    name="toleranceDay"
                                    placeholder={t('SREP.EDIT.DAILY_TOLERANCE')}
                                    fullWidth
                                    minValue={0}
                                    maxValue={999}
                                    status={(!!configuration!.toleranceDay && 
											configuration!.toleranceDay >= 0 && 
											configuration!.toleranceDay <= 999) ? 'success' : 'error'}
                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)} />
                            </Row.Item>

                        </>
                    }
                </Row>
            </>
        )
    }

    const renderFormAditionalHours = () => {
        return (
            <>
                <Row>
                    <Row.Item lg={12} md={12} sm={12}>
                        <Container className={styles['point-category-title']}>{t('SREP.EDIT.ADDITIONAL_HOURS_INTERVAL')}</Container>
                    </Row.Item>
                    <Row.Item lg={6} md={6} sm={6}>
                        <Container className={styles['point-subcategory-title']}>{t('SREP.EDIT.SPLIT_DAYLY_HOURS')}</Container>
                        
						<Table striped primary /*condensed*/>
                            <Table.Header>
                                <Table.HeaderItem>
                                    <Table.HeaderItem.Item>{t('GENERIC.TEXT.START_TIME')}</Table.HeaderItem.Item>
                                    <Table.HeaderItem.Item>{t('GENERIC.TEXT.END_TIME')}</Table.HeaderItem.Item>
                                    <Table.HeaderItem.Item>{t('GENERIC.TEXT.PERCENTAGE')}</Table.HeaderItem.Item>
                                    <Table.HeaderItem.Item>{t('GENERIC.TEXT.OPTIONS')}</Table.HeaderItem.Item>
                                </Table.HeaderItem>
                            </Table.Header>
                            <Table.Body>
								{splitDaytime?
									(splitDaytime?.map((srepSplit, index) => {
										return renderSrepSplit(srepSplit, index);
									})):<></>}
                              
                                <Table.BodyItem>
                                    <Table.BodyItem.Item colSpan={4} align='center' >
                                        <Button
                                            title={t('GENERIC.TEXT.ADD')}
                                            success
                                            sm
                                            icon="plus"
                                            action={() => showModal("daytime")} />
                                    </Table.BodyItem.Item>
                                </Table.BodyItem>
                            </Table.Body>
                        </Table>
                    </Row.Item>
                    <Row.Item lg={6} md={6} sm={6}>
                        <Container className={styles['point-subcategory-title']}>{t('SREP.EDIT.SPLIT_NIGHT_HOURS')}</Container>
                        <Table striped primary /*condensed*/>
                            <Table.Header>
                                <Table.HeaderItem>
                                    <Table.HeaderItem.Item>{t('GENERIC.TEXT.START_TIME')}</Table.HeaderItem.Item>
                                    <Table.HeaderItem.Item>{t('GENERIC.TEXT.END_TIME')}</Table.HeaderItem.Item>
                                    <Table.HeaderItem.Item>{t('GENERIC.TEXT.PERCENTAGE')}</Table.HeaderItem.Item>
                                    <Table.HeaderItem.Item>{t('GENERIC.TEXT.OPTIONS')}</Table.HeaderItem.Item>
                                </Table.HeaderItem>
                            </Table.Header>
                            <Table.Body>
								{splitNight?
									(splitNight?.map((srepSplit, index) => {
										return renderSrepSplit(srepSplit, index);
									})):<></>}
                                <Table.BodyItem>
                                    <Table.BodyItem.Item colSpan={4} align='center' >
                                        <Button
                                            title={t('GENERIC.TEXT.ADD')}
                                            success
                                            sm
                                            icon="plus"
                                            action={() => showModal("night")} />
                                    </Table.BodyItem.Item>
                                </Table.BodyItem>
                            </Table.Body>
                        </Table>
                    </Row.Item>
                </Row>
                <Row>
                    <Row.Item lg={12} md={12} sm={12}>
                        <Container className={styles['point-category-title']}>{t('GENERIC.TEXT.ADDITIONAL')}</Container>
                    </Row.Item>
                    <Row.Item lg={6} md={6} sm={6}>
                        <Input
                            type="number"
                            medium
                            value={configuration!.percentageHoliday}
                            title={`${t('GENERIC.TEXT.WORK_HOLIDAY')}(%)`}
                            name="percentageHoliday"
                            placeholder={`${t('GENERIC.TEXT.WORK_HOLIDAY')}(%)`}
                            fullWidth
                            minValue={1}
                            maxValue={999}
                            status={(!!configuration!.percentageHoliday && 
									configuration!.percentageHoliday >= 1 && 
									configuration!.percentageHoliday <= 999) ? 'success' : 'error'}
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)} />
                    </Row.Item>
                    <Row.Item lg={6} md={6} sm={6}>
                        <Input
                            type="number"
                            medium
                            value={configuration!.percentageDayOff}
                            title={`${t('GENERIC.TEXT.DAY_OFF_WORKED')}(%)`}
                            name="percentageDayOff"
                            placeholder={`${t('GENERIC.TEXT.DAY_OFF_WORKED')}(%)`}
                            fullWidth
                            minValue={1}
                            maxValue={999}
                            status={(!!configuration!.percentageDayOff && configuration!.percentageDayOff >= 1 && configuration!.percentageDayOff <= 999) ? 'success' : 'error'}
                            handleChange={(e) => handleChange(e)} />
                    </Row.Item>
                    <Row.Item lg={6} md={6} sm={6}>
                        <Input
                            type="number"
                            medium
                            value={configuration!.percentageInterval}
                            title={`${t('SREP.EDIT.EXTRA_BREAK')}(%)`}
                            name="percentageInterval"
                            placeholder={`${t('SREP.EDIT.EXTRA_BREAK')}(%)`}
                            fullWidth
                            minValue={1}
                            maxValue={999}
                            status={(!!configuration!.percentageInterval && configuration!.percentageInterval >= 1 && configuration!.percentageInterval <= 999) ? 'success' : 'error'}
                            handleChange={(e) => handleChange(e)} />
                    </Row.Item>
                </Row>
                <Row>
                    <Row.Item lg={12} md={12} sm={12}>
                        <Container className={styles['point-category-title']}>{t('SREP.EDIT.HOLIDAY_BEHAVIOR')}</Container>
                    </Row.Item>
                    <Row.Item lg={12} md={12} sm={12}>
                        <Switch
                            value={configuration!.holidayNotWorkedOff}
                            iconOff="times"
                            name="holidayNotWorkedOff"
                            iconOn="check"
                            colorOn="#3b51ed"
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            text={t('SREP.EDIT.HOLIDAY_BEHAVIOR_DAY_OFF')}
                            sm />
                    </Row.Item>
                </Row>
            </>
        )
    }

	const renderFormGeoLocation = () => {
        return (
            <>
                <Row>
					<Row.Item lg={12} md={12} sm={12}>
                        <Switch
                            value={configuration!.geolocation}
                            iconOff="times"
                            name="geolocation"
                            iconOn="check"
                            colorOn="#3b51ed"
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            text={t('SREP.EDIT.USE_GEOGRAPHIC_COORDINATES')}
                            sm />
                    </Row.Item>
				</Row>
				<Row>
                    <Row.Item lg={12} md={12} sm={12}>
                        <Switch
                            value={configuration!.allowsRegistrationOutsideArea}
                            iconOff="times"
                            name="allowsRegistrationOutsideArea"
                            iconOn="check"
                            colorOn="#3b51ed"
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            text={t('SREP.EDIT.ALLOW_TIME_REGISTRATION')}
                            sm />
                    </Row.Item>
                </Row>
			</>
		)
	}

    const renderFormHoursBank = () => {
        return (
            <>
                <Row>
                    <Row.Item lg={6} md={6} sm={6}>
                        <Switch
                            value={configuration!.hoursBank}
                            iconOff="times"
                            name="hoursBank"
                            iconOn="check"
                            colorOn="#3b51ed"
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            text={t('SREP.EDIT.ACTIVATE_HOUR_BANK')}
                            sm />
                    </Row.Item>
                </Row>
                {configuration!.hoursBank &&
                    <Row>
                        <Row.Item lg={12} md={12} sm={12}>
                            <Container className={styles['point-category-title']}>{t('GENERIC.TEXT.COMPENSATION')}</Container>
                        </Row.Item>
                        <Row.Item lg={12} md={12} sm={12}>
                            <Switch
                                value={configuration!.hoursBankAutoIncrement}
                                iconOff="times"
                                name="hoursBankAutoIncrement"
                                iconOn="check"
                                colorOn="#3b51ed"
                                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                                text={'Auto incremento do banco de horas quando ocorrrer horas extras'}
                                sm />
                        </Row.Item>
                        <Row.Item lg={12} md={12} sm={12}>
                            <Switch
                                value={configuration!.hoursBankAutoDecrement}
                                iconOff="times"
                                name="hoursBankAutoDecrement"
                                iconOn="check"
                                colorOn="#3b51ed"
                                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                                text={'Auto decremento do banco de horas quando ocorrrer falta/atrasos'}
                                sm />
                        </Row.Item>
                        <Row.Item lg={3} md={3} sm={3}>
                            <DateTimePicker
                                title={t('SREP.EDIT.START_HOUR_BANK')}
                                name="hoursBankStart"
                                value={configuration!.hoursBankStart}
                                type="date"
                                mask
								noSeconds={false}
								forceOpenPicker={false}
								timeInverseColor
                                fullWidth
                                status={!!configuration!.hoursBankStart ? 'success' : 'error'}
                                handleChange={(e) => { handleChange(undefined, "hoursBankStart", e) }} />
                        </Row.Item>
                        <Row.Item lg={3} md={3} sm={3}>
                            <Input
                                type="number"
                                medium
                                value={configuration!.hoursBankLimit}
                                title={t('SREP.EDIT.EXPIRATION_TIME_HOUR_BANK')}
                                name="hoursBankLimit"
                                placeholder="Meses"
                                fullWidth
                                minValue={1}
                                maxValue={24}
                                status={(!!configuration!.hoursBankLimit && configuration!.hoursBankLimit >= 1 && configuration!.hoursBankLimit <= 24) ? 'success' : 'error'}
                                handleChange={(e) => handleChange(e)} />
                        </Row.Item>

                        <Row.Item lg={12} md={12} sm={12}>
                            <Container className={styles['point-category-title']}>{t('SREP.EDIT.MULTIPLICATION_OF_HOURS_INFO')}</Container>
                        </Row.Item>
                        <Row.Item lg={6} md={6} sm={6}>
                            <Container className={styles['point-subcategory-title']}>{t('SREP.EDIT.MULTIPLICATION_OF_HOURS')}</Container>
                            <Table striped primary /*condensed*/>
                                <Table.Header>
                                    <Table.HeaderItem>
                                        <Table.HeaderItem.Item>{t('GENERIC.TEXT.START_TIME')}</Table.HeaderItem.Item>
                                        <Table.HeaderItem.Item>{t('GENERIC.TEXT.END_TIME')}</Table.HeaderItem.Item>
                                        <Table.HeaderItem.Item>{t('GENERIC.TEXT.PERCENTAGE')}</Table.HeaderItem.Item>
                                        <Table.HeaderItem.Item>{t('GENERIC.TEXT.OPTIONS')}</Table.HeaderItem.Item>
                                    </Table.HeaderItem>
                                </Table.Header>
                                <Table.Body>
									{splitDatabase?
									(splitDatabase?.map((srepSplit, index) => {
										return renderSrepSplit(srepSplit, index);
									})):<></>}
                                    <Table.BodyItem>
                                        <Table.BodyItem.Item colSpan={4} align='center' >
                                            <Button
                                                title={t('GENERIC.TEXT.ADD')}
                                                success
                                                sm
                                                icon="plus"
                                                action={() => showModal("database")} />
                                        </Table.BodyItem.Item>
                                    </Table.BodyItem>
                                </Table.Body>
                            </Table>
                        </Row.Item>
                    </Row>
                }
            </>
        )
    }

    const renderFormOvernight = () => {
        return (
            <>
                <Row>
                    <Row.Item lg={12} md={12} sm={12}>
                        <Switch
                            value={configuration!.overnight}
                            iconOff="times"
                            iconOn="check"
                            colorOn="#3b51ed"
                            name="overnight"
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfiguration({ ...configuration!, overnight: e.target.checked })}
                            text={t('SREP.EDIT.USE_ADDITIONAL_NIGHT')}
                            sm />
                    </Row.Item>
                </Row>
                <Row>
                    {configuration!.overnight &&
                        <>
                            <Row.Item lg={12} md={12} sm={12}>
                                <Switch
                                    value={configuration!.overnightExtend}
                                    iconOff="times"
                                    name="overnightExtend"
                                    iconOn="check"
                                    colorOn="#3b51ed"
                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                                    text={t('SREP.EDIT.EXTEND_NIGHT_TIME')}
                                    sm />
                            </Row.Item>
                            <Row.Item lg={3} md={3} sm={3}>
                                <DateTimePicker
                                    title={t('SREP.EDIT.NIGHT_START_TIME')}
                                    name="overnightStart"
                                    value={configuration!.overnightStart}
                                    minTime="00:00"
                                    maxTime="23:59"
                                    type="time"
                                    noSeconds
                                    mask
									forceOpenPicker={false}
									timeInverseColor
                                    fullWidth
                                    status={!!configuration!.overnightStart ? 'success' : 'error'}
                                    handleChange={(e) => { handleChange(undefined, "overnightStart", e) }} />
                            </Row.Item>
                            {!configuration!.overnightExtend &&
                                <Row.Item lg={3} md={3} sm={3}>
                                    <DateTimePicker
                                        title={t('SREP.EDIT.NIGHT_END_TIME')}
                                        name="overnightEnd"
                                        value={configuration!.overnightEnd}
                                        minTime="00:00"
                                        maxTime="23:59"
                                        type="time"
                                        noSeconds
                                        mask
										forceOpenPicker={false}
										timeInverseColor
                                        fullWidth
                                        status={!!configuration!.overnightEnd ? 'success' : 'error'}
                                        handleChange={(e) => { handleChange(undefined, "overnightEnd", e) }} />
                                </Row.Item>
                            }

                            <Row.Item lg={12} md={12} sm={12}>
                                <Label title={t('SREP.EDIT.EXTEND_NIGHT_TIME_INFO')}/>
                                    
                            </Row.Item>
                        </>
                    }
                </Row>
            </>
        )
    }

    const renderFormEmployees = () => {
        return (
            <Container>
                <DualList
                    titleAvailable={t('CABLE.AREA.NEW.AVAILABLE_EMPLOYEES')}
                    titleSelected={t('CABLE.AREA.NEW.SELECTED_EMPLOYEES')}
                    options={employees!}
					//options={[]}
                    firstIcon="user"
                    secondIcon="tools"
                    thirdIcon="clock"
                    handleChange={handleChangeDualList} />
            </Container>
        )
    }

    const renderPreFilledPointEmployees = () => {
        return (
            <Row>
                <Row.Item xs={12} sm={12} md={12} lg={12}>
                    <Switch
                        value={preFilledPoints}
                        iconOff="times"
                        name="preFilledPoints"
                        iconOn="check"
                        colorOn="#3b51ed"
                        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangePreFilledPoints(e)}
                        text={t('SREP.EDIT.ACTIVATE_PREFILLED_POINT')}
                        sm />
                </Row.Item>
                <Row.Item xs={12} sm={12} md={12} lg={12}>
                    {preFilledPoints &&
                        <DualList
                            titleAvailable={t('CABLE.AREA.NEW.AVAILABLE_EMPLOYEES')}
                            titleSelected={t('CABLE.AREA.NEW.SELECTED_EMPLOYEES')}
                            options={preFilledPointEmployees!}
                            firstIcon="user"
                            secondIcon="tools"
                            thirdIcon="clock"
                            handleChange={handleChangeDualListPreFilledPointEmployees} />
                    }
                </Row.Item>
            </Row>
        );
    };

    const renderFormIncidents = () => {
        return (
            <Row>
                <Row.Item lg={3} md={3} sm={3}>
                    <Input
                        type="number"
                        medium
                        value={configuration!.numberJustificationsAllowed}
                        title={t('SREP.EDIT.INPUT_NUMBER_JUSTIFICATIONS_ALLOWED')}
                        name="numberJustificationsAllowed"
                        placeholder={t('GENERIC.TEXT.QUANTITY')}
                        fullWidth
                        minValue={1}
                        status={(!!configuration!.numberJustificationsAllowed && configuration!.numberJustificationsAllowed >= 1) ? 'success' : 'error'}
                        handleChange={(e) => handleChange(e)} />
                </Row.Item>
            </Row>
        );
    };

    const renderFormClosedPeriods = () => {
        return (
            <>
                <Row>
                    <Row.Item lg={12} md={12} sm={12}>
                        <Container>
                            <Switch
                                value={configuration!.endPeriodWithoutEvent}
                                iconOff="times"
                                iconOn="check"
                                colorOn="#3b51ed"
                                name="endPeriodWithoutEvent"
                                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfiguration({ ...configuration!, endPeriodWithoutEvent: e.target.checked })}
                                text={t('SREP.EDIT.END_PERIOD_WITHOUT_EVENT')}
                                sm />
                        </Container>
                    </Row.Item>

                    <Row.Item lg={12} md={12} sm={12}>
                        <Container style={{ marginTop: '10px' }}>
                            <Switch
                                value={configuration!.endPeriodWithPendingJustifications}
                                iconOff="times"
                                iconOn="check"
                                colorOn="#3b51ed"
                                name="endPeriodWithPendingJustifications"
                                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfiguration({ ...configuration!, endPeriodWithPendingJustifications: e.target.checked })}
                                text={t('SREP.EDIT.END_PERIOD_WITH_PENDING_JUSTIFICATIONS')}
                                sm />
                        </Container>
                    </Row.Item>
                </Row>
            </>
        );
    };

    const renderForm = () => {
        return (
            <>

                <form onSubmit={e => e.preventDefault()} className={styles['form-point-edit']}>

                    <Tabs activeTab={0}>
                        <Tabs.Item title={t('GENERIC.TEXT.GENERAL')}>
                            {renderFormGeneral()}
                        </Tabs.Item>
                        <Tabs.Item title={t('SREP.EDIT.TOLERANCE')}>
                            {renderFormTolerance()}
                        </Tabs.Item>
                        <Tabs.Item title={t('SREP.EDIT.ADDITIONAL_HOURS')}>
                            {renderFormAditionalHours()}
                        </Tabs.Item>
                        <Tabs.Item title={t('SREP.EDIT.NIGHT_ADDITIONAL')}>
                            {renderFormOvernight()}
                        </Tabs.Item>
                        <Tabs.Item title={t('GENERIC.TEXT.HOUR_BANK')}>
                            {renderFormHoursBank()}
                        </Tabs.Item>
						<Tabs.Item title={t('SREP.EDIT.GEOGRAPHICAL_POSITION')}>
                            {renderFormGeoLocation()}
                        </Tabs.Item>
                        <Tabs.Item title={t('GENERIC.TEXT.EMPLOYEES')}>
                            {renderFormEmployees()}
                        </Tabs.Item>
                        <Tabs.Item title={t('GENERIC.TEXT.INCIDENTS')}>
                            {renderFormIncidents()}
                        </Tabs.Item>
                        <Tabs.Item title={t('CLOSED_PERIODS.TEXT.CLOSED_PERIODS')}>
                            {renderFormClosedPeriods()}
                        </Tabs.Item>
                        <Tabs.Item title={t('CABLE.POINT_REGISTRATION.TEXT.PRE_FILLED_POINT')}>
                            {renderPreFilledPointEmployees()}
                        </Tabs.Item>
                    </Tabs>

                    <Container className={styles['form-point-edit-actions']}>
                        <Button
                            danger
                            title={t('GENERIC.BUTTON.CANCEL.TEXT')}
                            outlined
                            action={(e) => {
                                e.preventDefault();
                                srepConfigurationsPage();
                            }} />

                        <Button
                            success
                            title={t('GENERIC.BUTTON.UPDATE.TEXT')}
                            icon="save"
                            loading={loadingButton}
                            disabled={disabledButton}
                            action={(e) => update(e)} />

                    </Container>
                </form>
            </>
        );
    }

    return (
        <Container className={styles['page-point-edit-rt']}>
            <Row>
                <Row.Item lg={12} md={12} sm={12}>
                    <PageTitle.Small title={t('SREP.EDIT.CONFIGURATION_EDITING')} returnUrl="/#/srepsMF/settings" />
                    {srepParamsStatus !== 'success' &&
                        <Panel>
                            <Loading />
                        </Panel>
                    }
                    {srepConfigurations && srepParamsStatus === 'success' && configuration &&
                        <Panel className={styles['panel-edit']} title={`${t('SREP.EDIT.CONFIGURATION_EDITING')} - ${srepConfigurations!.srepParam.name}`}>
                            <>
								{textAlertError != null &&
									<Animate>
										<Alert danger text={textAlertError} />
									</Animate>
								}
								{showAlertSuccess &&
									<Toast 
									success
									icon='check'
									title={t('GENERIC.TEXT.OPERATION')}
									positionTopRight
									message={t('SREP.EDIT.SUCCESS',undefined,configuration.name)} 
									showTime={5000}/>
								}
								{renderForm()}
							</>
                        </Panel>
                    }
                </Row.Item>
            </Row>
            {showModalNewSplitHours &&
                <Container className={styles['modal-split-hours']}>
                    <Modal
                        title={t('SREP.EDIT.SPLIT_HOURS_REGISTRATION')}
                        xs
                        showClose
                        handleClose={() => { setShowModalNewSplitHours(false) }}>

                        <NewSplitHour
                            timeInit={timeInit}
                            saveAction={(e:SrepSplitHour)=> saveSplitHour(e)}
							//saveAction={()=>{}}
                            cancelAction={() => setShowModalNewSplitHours(false)} />
                    </Modal>
                </Container>
            }
            {showModalConfirm &&
                <Modal
                    title={t('GENERIC.TEXT.CONFIRMATION')}
                    xs
                    showClose
                    handleClose={() => {
                        setShowModalConfirm(false);
                        setLoadingButton(false);
                    }}>
                    <Container className={styles['modal-confirm-recalc']}>
                        <Label title={t('SREP.EDIT.RECALCULATE_HOUR_BANK')} />
                        <Container>
                            <Button
                                danger
                                title={t('GENERIC.TEXT.NO')}
                                action={(e) => {
                                    updateWithoutEmployeesToRecalculate(e);
                                }} />

                            <Button
                                primary
                                title={t('GENERIC.TEXT.YES')}
                                action={() => {
                                    setShowModalConfirm(false);
                                    setShowModalUpdateEmployees(true);
                                }} />
                        </Container>
                    </Container>
                </Modal>
            }
            {showModalUpdateEmployees &&
                <Container className={styles['modal-employee-recalc']}>
                    <Modal
                        title={t('SREP.EDIT.EMPLOYEES_TO_BE_RECALCULATED')}
                        lg
                        showClose
                        handleClose={() => {
                            setShowModalUpdateEmployees(false);
                            setLoadingButton(false);
                        }}>

                        <AlertTiny title={t('GENERIC.TEXT.ATTENTION')} text={textModal} /*primary*/ icon="info-circle"/>

                        <DualList
                            titleAvailable={t('SREP.EDIT.EMPLOYEES_NOT_CALCULATED')}
                            titleSelected={t('SREP.EDIT.EMPLOYEES_CALCULATED')}
                            options={employeesToUpdate!}
                            firstIcon="user"
                            secondIcon="tools"
                            thirdIcon="clock"
                            handleChange={handleChangeDualListEmployeesToUpdate} />

                        <Container className={styles['modal-actions']}>
                            <Button
                                danger
                                title={t('GENERIC.BUTTON.CANCEL.TEXT')}
                                outlined
                                action={(e) => {
                                    e.preventDefault();
                                    setShowModalUpdateEmployees(false);
                                    setLoadingButton(false);
                                }} />

                            <Button
                                success
                                title={t('GENERIC.BUTTON.SAVE.TEXT')}
                                icon="save"
								action={(e) => updateWithEmployeesToRecalculate(e)} />

                        </Container>
                    </Modal>
                </Container>
            }
        </Container>
    )
}

export default Edit;