import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PointIncident } from '../../../../../models/PointIncident';
import { PointComment } from '../../../../../models/PointComment';

import { translateStatus, translateType } from '../../../../../utils/PointIncidentUtil';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { postPointIncidentJustification, postPointIncidentJustificationValidate } from '../../../../../services/pointIncident.services';

import { Button, Input, Label, Message, Modal, Row, Select, Time, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import TimeOrdering from '../../../../molecules/TimeOrdering/TimeOrdering';
import DailyScaleHours from '../../../../molecules/DailyScaleHours/DailyScaleHours';

import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index';

import styles from './Justification.module.scss';

interface JustificationProps{
	Container?:any,
	justifyIncident: PointIncident,
	pointComments?: PointComment[],
	handleChange?: Function,
	handleClose?: Function
};

interface PointModel {
	time: string, 
	pointOrder: number,
	idx: string
};

const generateRandonString = () => {
	return (Math.random() + 1).toString(36).substring(7)
};

const Justification = ({ justifyIncident, pointComments, handleChange, handleClose, Container='div' }: JustificationProps) => {

	let navigate = useNavigate();

	const [listComments, setListComments] = useState<{key: string, value: string, selected: boolean}[]>([{key:'', value: t('CABLE.POINT_INCIDENT.SELECT'), selected: false}]);
	const [commentValue, setCommentValue] = useState({key:'', value: t('CABLE.POINT_INCIDENT.SELECT'), selected: false});
	const [descriptionResolution, setDescriptionResolution] = useState<string>(!!justifyIncident.descriptionResolution ? justifyIncident.descriptionResolution : '');
	const [pointsJustification, setPointsJustification] = useState<PointModel[]>();
	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false})
	const [loadingButtonJustification, setLoadingButtonJustification] = useState(false);
	const [modalValidateJustification, setModalValidateJustification] = useState<{ show: boolean, params?: any, errors?: [], reorderedPoints?: string}>({ show: false });

	useEffect(() => {
		let pointsToEdit: String = !!justifyIncident.resolutionDailyHours ?
			justifyIncident.resolutionDailyHours : justifyIncident.actualDailyHours;

		let justifyPoints: PointModel[] = [];
		if (pointsToEdit) {
			pointsToEdit.split('-').forEach((point, index) => {
				justifyPoints.push({
					time: point,
					pointOrder: index,
					idx: generateRandonString()
				});
			});
		}

		setPointsJustification([...justifyPoints]);

	}, [justifyIncident]);

	useEffect(() => {
		if(pointComments){
			let options: any[] = [{key:'', value:t('CABLE.POINT_INCIDENT.SELECT'), selected: false}];
			let comments = [];
			if(justifyIncident.pointIncidentType==='WORK_DAY_OFF'){
				comments = pointComments.filter(comment => comment.showInWorkDayOff===true);
			}
			else if(justifyIncident.pointIncidentType==='AREA'){
				comments = pointComments.filter(comment => comment.showInArea===true);
			}
			else if (justifyIncident.pointIncidentType==='DIVERGENCE') {
				comments = pointComments.filter(comment => comment.showInDivergence===true);
			}
			else{
				comments = pointComments.filter(comment => comment.showInWorkDayOff===false || comment.name==='Outro');
			}

			comments.forEach(element => {
				let valueOfSelect: { key: string, value: string, selected: boolean } = {
					key: element.id,
					value: element.name,
					selected: false
				};

				if (!!justifyIncident.pointComment && justifyIncident.pointComment.id === element.id) {
					valueOfSelect.selected = true;
					setCommentValue(valueOfSelect);
				}

				options.push(valueOfSelect);
			});

			setListComments(options);
		}
	},[pointComments]);

	const renderStatus = (status: string) => {
		return (
			<Message message={translateStatus(status)}
				danger={status === 'DISAPPROVED'}
				primary={status === 'OPENED' || status === 'WAITING_FOR_REVIEW'}
				success={status === 'SOLVED'}
				warning={status === 'WAITING'}
				icon={(status === 'OPENED' || status === 'WAITING_FOR_REVIEW') ? 'signature' : status === 'SOLVED' ? 'check' : status === 'WAITING' ? 'clock' : ''} />
		);
	};

	const handleChangePointJustification = (event: any) => {
		const eventName = event.name;
		const eventValue = event.value;
		if(eventName==='element remove'){
			let points = [...pointsJustification!];
			if(points.indexOf(eventValue)){
				points.splice(eventValue, 1);
				setPointsJustification(points);
			}
		}

		if(eventName === 'time change'){
			let points = [...pointsJustification!];
			points?.splice(eventValue.order,1,{...points[eventValue.order], time: eventValue.time});
			setPointsJustification([...points]);
		}
	};

	const changeComment = (key: string) => {
        let commets = [...listComments];

        for (var i = 0; i < commets.length; i++) {
            if (commets[i].key === key) {
                commets[i].selected = true;
                setCommentValue(commets[i]);
            } else {
                commets[i].selected = false;
            }
        }

        setListComments(commets);
    };

	const handleOrder = (event:any) => {
		let pointsState = [...pointsJustification!];
		let newPointOrder: PointModel[]= [];

		pointsState.forEach(point => {
			event.value.forEach((v:string, idx: number) => {
				let [id,time] = v.split('|');
				if(time === point.time && id === point.idx){
					if(point.pointOrder !== idx){
						point.pointOrder = idx;
					}
					newPointOrder.push(point);
				}
			});
		});

		pointsState.sort((point1, point2) => {
			if(point1.pointOrder < point2.pointOrder){
				return -1;
			}else{
				return 1;
			}
		})

		setPointsJustification([...pointsState]);
	};

	const validatePointIncidentParams = () => {
		let params = {};

		if(justifyIncident.pointIncidentType === 'AREA'){
			if(commentValue.key === '' || descriptionResolution === ''){
				setToastAlert({...toastAlert, danger: true, message:t('CABLE.POINT_INCIDENT.ALL_DATA_INFORMED'),show: true});
				return;
			}

			params = {
				id: justifyIncident.id, 
				pointCommentId: commentValue.key, 
				descriptionResolution: descriptionResolution,
			};
		}else{
			if(pointsJustification!.length === 0 
				&& justifyIncident.expectedDailyHours.includes(':')){
				setToastAlert({...toastAlert, danger: true, message:t('CABLE.POINT_INCIDENT.RECORDS_INFORMEDS'),show: true});
				return;
			}
			
			if(pointsJustification!.length %2 !== 0){
				setToastAlert({...toastAlert, danger: true, message:t('CABLE.POINT_INCIDENT.RECORDS_PAIRS'),show: true});
				return;
			}
			
			if(pointsJustification!.find(e => e.time==='') !== undefined || commentValue.key === '' || descriptionResolution === ''){
				setToastAlert({...toastAlert, danger: true, message:t('CABLE.POINT_INCIDENT.ALL_DATA_INFORMED'),show: true});
				return;
			}

			params = {
				id: justifyIncident.id, 
				pointCommentId: commentValue.key, 
				descriptionResolution: descriptionResolution,
				resolutionDailyHours: pointsJustification?.map(e => e.time).join('-')
			};
		}

		return params;
	};

	const saveJustification = async (params: object) => {
		try{
			const jsonReturned =  await postPointIncidentJustification(params);
			if(jsonReturned){
				if(jsonReturned.status === 'ERROR'){
					setToastAlert({...toastAlert, danger: true, message:jsonReturned.message,show: true});
					setLoadingButtonJustification(false); 
				}else{
					setToastAlert({...toastAlert, danger: false, message:jsonReturned.message,show: true});
					
					setTimeout(() => { 
						setLoadingButtonJustification(false); 
						if(handleChange){
							handleChange({ name:'operation success', value: justifyIncident });
						}

						if(handleClose){
							handleClose();
						}else{
							navigate('/srepsMF/incidents/my');
						}
					}, 3000);
				}
				//setClosedPeriods(jsonReturned.closedPeriods);
			}
		}catch(error){
			console.log(error);
		}
	};

	const valiteAndSaveJustification = async () => {
		let params = validatePointIncidentParams();
		
		// validation error abort the method
		if(!params){
			return;
		}
		
		setLoadingButtonJustification(true);

		const jsonReturnValidate = await postPointIncidentJustificationValidate(params);

		if(jsonReturnValidate?.status === 'ERROR'){
			setModalValidateJustification({ 
				show: true, 
				params: params, 
				errors: jsonReturnValidate?.['data']?.['errors'], 
				reorderedPoints: jsonReturnValidate?.['data']?.['reorderedPoints'] 
			});
		}

		if(jsonReturnValidate?.status === 'SUCCESS'){
			saveJustification(params);
		}
	};

	const closeModalValidateJustification = () => {
		setModalValidateJustification({ show: false });
		setLoadingButtonJustification(false);
	};

	const renderInputJustification = (defaultValues: string) => {
		return (
			<>
				<Container className={styles['point-justification-container']} >
					<Container className={styles['points-justification']}>
						<TimeOrdering horizontal handleOrder={(e: any) => { handleOrder(e) }}>
							{pointsJustification?.map((value, index) => {
								return <TimeOrdering.Item key={value.idx + index} time={value.time}
									order={index}
									idx={value.idx}
									date={justifyIncident.incidentDate}
									disabled={defaultValues?.includes(value.time) && value.time !== ''}
									deleted={true}
									handleChange={handleChangePointJustification} />
							})}
						</TimeOrdering>
					</Container>
				</Container>

				<Container className={styles['add-point-action']}>
					<Button className={styles['add-point']}
						title={t('CABLE.POINT_INCIDENT.ADD_RECORD')}
						icon='plus'
						primary 
						md
						loading={loadingButtonJustification} 
						action={() => setPointsJustification([
							...pointsJustification!,
							{
								time: '', pointOrder: pointsJustification!.length,
								idx: generateRandonString()
							}
						])} />
				</Container>
			</>
		);
	};

	return ( 
		<Container className={styles['point-incident-justification-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Container>
						<h3>{t('CABLE.POINT_INCIDENT.INCIDENT_SUMMARY')}</h3>
						<dl>
							<dt>{t('GENERIC.TEXT.EMPLOYEE')}: </dt><dd>{justifyIncident?.employee.name}</dd>
							<dt>{t('CABLE.POINT_REGISTRATION.TEXT.SITUATION')}: </dt><dd>{renderStatus(justifyIncident?.pointIncidentStatus!)}</dd>
							<dt>{t('CABLE.POINT_INCIDENT.INCIDENT_DATE')}: </dt><dd>{formatLocalDate(justifyIncident?.incidentDate, true, 'YYYY-MM-DD', undefined)}</dd>
							<dt>{t('CABLE.POINT_INCIDENT.OCCURRENCE')}: </dt><dd>{translateType(justifyIncident?.pointIncidentType!, justifyIncident?.actualPointsNumber, justifyIncident?.expectedPointsNumber)}</dd>
							<dt>{t('CABLE.POINT_INCIDENT.MANAGER_OBSERVATION')}: </dt><dd>{justifyIncident?.leaderObservation ? justifyIncident?.leaderObservation : '-'}</dd>
							<dt>{t('CABLE.POINT_INCIDENT.APPROVAL_ATTEMPTS')}: </dt><dd>{`${justifyIncident?.approvalAttempts} ${t('GENERIC.TEXT.OF')} ${justifyIncident?.numberJustificationsAllowed}`}</dd>
							{justifyIncident.pointIncidentType !== 'AREA' && 
								<>
									<dt>{t('CABLE.POINT_INCIDENT.PLANNED')}: </dt><dd>{justifyIncident?.expectedDailyHours?.split('-').map(daily => <Time key={daily} time={daily} primary/>)}</dd>
									<dt>{t('CABLE.POINT_INCIDENT.DONE')}: </dt><dd>{justifyIncident?.actualDailyHours?.split('-').map(daily => <Time  key={daily} time={daily} warning/>)}</dd>
								</>
							}
						</dl>
					</Container>
				</Row.Item>

				<Row.Item sm={12} md={12} lg={12}>
					<Container className={styles['point-incident-justification-form']}>
						<h3>{t('CABLE.POINT_INCIDENT.JUSTIFICATION_FORM')}</h3>
						{justifyIncident.pointIncidentType !== 'AREA' && 
							<Container>
								<Label title='Pontos' />
								{renderInputJustification(justifyIncident.actualDailyHours)}
							</Container>
						}

						<Container>
							<Select name='selectReason' 
								medium 
								fullWidth 
								value={commentValue.value} 
								title={t('GENERIC.TEXT.REASON')} 
								options={listComments} 
								handleChange={changeComment} />
						</Container>
						
						<Container>
							<Input type="text" fullWidth
								title={t('CABLE.POINT_REGISTRATION.TEXT.OBSERVATION')}
								name="closedPeriodName"
								value={descriptionResolution}
								//status={inputStatus}
								handleChange={(event) => setDescriptionResolution(event.target.value)} />
						</Container>
					</Container>
				</Row.Item>

				<Row.Item sm={12} md={12} lg={12}>
					<Container>
						<Container className={styles['justification-actions']}>
							<Button title={t('CABLE.POINT_REGISTRATION.TEXT.CLOSE')} 
								className={styles['justification-action']} 
								icon="undo" 
								primary 
								md 
								action={() => { handleClose?.(); }} />

							{(justifyIncident?.pointIncidentStatus ==='OPENED' || justifyIncident?.pointIncidentStatus === 'WAITING_FOR_REVIEW' ) &&
								<Button className={styles['justification-action']}
									title={t('CABLE.POINT_INCIDENT.JUSTIFY')}
									icon="signature"
									success
									md
									loading={loadingButtonJustification} 
									action={() => { valiteAndSaveJustification() }} />
							}
						</Container>
					</Container>
				</Row.Item>
			</Row>

			{modalValidateJustification.show &&
				<Modal title={t('CABLE.POINT_INCIDENT.JUSTIFY') + ' - ' + t('CABLE.POINT_INCIDENT.REQUEST_REVIEW')}
					showClose
					md
					handleClose={closeModalValidateJustification}>

					<Container className={styles['modal-validate-justify-body']}>
						<Container className={styles['data']}>
							<p>{t('CABLE.POINT_INCIDENT.MODAL_JUSTIFICATION_WITH_INCIDENTS_CONTINUE_TEXT')}</p>
							<ul>
								{modalValidateJustification.errors?.map((error, i) => (
									<li key={`li-error-${i}`}>{error['defaultMessage']}</li>
								))}
							</ul>
							{!!modalValidateJustification.reorderedPoints &&
								<dl>
									<dt>Pontos:</dt>
									<dd><DailyScaleHours dailyScaleDay={modalValidateJustification.params['resolutionDailyHours']} warning /></dd>

									<dt>Sugestão de Pontos:</dt>
									<dd><DailyScaleHours dailyScaleDay={modalValidateJustification.reorderedPoints} success /></dd>
								</dl>
							}
						</Container>

						<Container className={styles['justification-actions']}>
							<Button className={styles['justification-action']}
								title={t('CABLE.POINT_REGISTRATION.TEXT.CLOSE')}
								icon="undo"
								primary 
								md
								action={closeModalValidateJustification} />

							{!!modalValidateJustification.reorderedPoints && 
								<Button className={styles['justification-action']}
									title={'Justificar com pontos sugeridos'}
									icon="undo"
									success
									md
									action={() => {
										saveJustification({
											...modalValidateJustification.params,
											'resolutionDailyHours': modalValidateJustification.reorderedPoints
										});
										setModalValidateJustification({ show: false });
									}} />
							}

							<Button className={styles['justification-action']} 
								title={t('CABLE.POINT_INCIDENT.JUSTIFY')} 
								icon="signature"
								md
								loading={false}
								success={!modalValidateJustification.errors}
								warn={!!modalValidateJustification.errors}
								action={() => { 
									saveJustification({ 
										...modalValidateJustification.params, 
										'validatePoints': true
									});
									setModalValidateJustification({ show: false });
								 }} />
						</Container>
					</Container>
				</Modal>
			}
			
			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION')}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}
		</Container>
	);
};

export default Justification;
