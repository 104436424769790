import React, { useEffect, useState } from 'react';

import { Report } from '../../../../models/Report';
import TableListPagination from '../../../organism/TableListPagination/TableListPagination';
import { Button, Loading, Message, Modal, PageTitle, Panel, Row, Select, TextInformation } from '@optimuminterfaces/revex-react-components/dist/components';

import styles from './List.module.scss';
import { fetchAllReports, downloadReport } from '../../../../services/Report.services';
import { translateStatus, translateType } from '../../../../utils/ReportUtil';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { downloadCsv } from '@optimuminterfaces/revex-react-components/dist/utils/FileUtil';
import New from '../New/New';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

interface ListProps{
	Container?: any
}
const List = ({Container='div'}: ListProps) => {

	const statuses = [
		{key:'',value:t('GENERIC.TEXT.ALL'), selected: true},
		{key:'AWAIT_PROCESSING',value:t('CABLE.REPORT.TEXT.AWAITING_PROCESSING'), selected: true},
		{key:'ERROR',value:t('CABLE.REPORT.TEXT.ERROR'), selected: true},
		{key:'PROCESSED',value:t('CABLE.REPORT.TEXT.PROCESSED'), selected: true},
	];

	const [reports, setReports] = useState<Report[]>();
	const [reportStatus, setReportStatus] = useState<string>('waiting');
	const [statusValue, setStatusValue] = useState<any>(statuses[0]);
	const [listStatuses, setListStatuses] = useState(statuses);
	const [filters, setFilters] = useState<{status?: string}>({status: statuses[0].key});
	const [showModalLoading,setShowModalLoading] = useState(false);
	const [showModalNewReport,setShowModalNewReport] = useState(false);

	useEffect(()=>{
		(async () => {
			setReportStatus('waiting');
			try{
				const jsonReturned =  await fetchAllReports(filters);
				if(jsonReturned){
					setReports(jsonReturned.reports);
					setReportStatus('success');
				}else{
					setReportStatus('error');
				}
			}catch(error){
				console.log(error);
			}

		})();
	},[filters])

	const changeSelectStatus = (key: string) => {
        let statuses = [...listStatuses];

        for (var i = 0; i < statuses.length; i++) {
            if (statuses[i].key === key) {
                statuses[i].selected = true;
                setStatusValue(statuses[i]);
				setFilters({...filters, status: statuses[i].key})
            } else {
                statuses[i].selected = false;
            }
        }

        setListStatuses(statuses);

    }

	const download = async (report: Report) => {
        setShowModalLoading(true);

        let fileName = `${report.name}.csv`;

		try{
			const jsonReturned =  await downloadReport(report.id);
			if(jsonReturned){
				downloadCsv(jsonReturned, fileName);
			}
			setShowModalLoading(false);
		}catch(error){
			console.log(error);
		}
    };

	const handleColumnRender = ( columnValue:any, columnName:string, columnIndex:number, row: Report ) => {
		switch(columnName){
			case 'period':
				return columnValue.split(' ').map((element: string, idx: number) => {
					return formatLocalDate(element, true, 'YYYY-MM-DD', undefined)+ (idx===0?' até ':'');
				});
			case 'created':
				return formatLocalDate(columnValue, true, 'yyyy-MM-DDTHH:mm:ss.sss','DD/MM/yyyy HH:mm:ss');
			case 'status':
				return renderStatus(columnValue);
			case 'type':
				return translateType(columnValue);
			case 'actions':
				return (
					<>
						{row.status==='PROCESSED' && 
							<Button primary sm
								title={t('CABLE.REPORT.TEXT.DOWNLOAD_REPORT')}
								icon="download"
								className={styles['table-item-action']}
								action={() => { download(row) }} />
						}
					</>
				)
			default:
				return columnValue;
		}
	}

	const renderStatus = (status:string) => {
		return(
			<Message message={translateStatus(status)} 
							danger={status==='ERROR'}
							success={status==='PROCESSED'}
							warning={status==='AWAIT_PROCESSING'}
							icon={status==='ERROR'?'times':status==='PROCESSED'?'check':status==='AWAIT_PROCESSING'?'clock':''}/>
		)
	}

	return (
		<Container className={styles['report-list-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CABLE.REPORT.TEXT.REPORT_LIST')} />
				</Row.Item>
			</Row>

			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel noTitleBorder>
						<Row>
							<Row.Item lg={3} md={3} sm={6}>
								<Select medium fullWidth name="selectStatus" value={statusValue.value} title={t('CABLE.POINT_REGISTRATION.TEXT.SITUATION')} options={listStatuses} handleChange={changeSelectStatus} />
							</Row.Item>
						</Row>
					</Panel>
				</Row.Item>
			</Row>
			<Row>
				<Row.Item lg={12} md={12} sm={12}>
					<Panel noPadding={reportStatus === 'success' && reports!.length > 0}>
						<>
							<Row>
								<Row.Item lg={12} md={12} sm={12}>
									<Button primary md
										title={t('CABLE.REPORT.TEXT.NEW_REPORT')}
										icon="plus"
										className={styles['action']}
										action={() => { setShowModalNewReport(true)}} />
								</Row.Item>
							</Row>
							{(reportStatus === 'waiting') && 
								<Row>
									<Row.Item lg={12} md={12} sm={12}>
										<Loading text={t('GENERIC.TEXT.LOADING')}/>
									</Row.Item>
								</Row>
							}

							{(reportStatus === 'error') && 
								<Row>
									<Row.Item lg={12} md={12} sm={12}>
										<TextInformation 
												icon='exclamation-circle'
												text={t('GENERIC.TEXT.UNABLE_RETURN')} />
									</Row.Item>
								</Row>
							}

							{reports &&  reportStatus === 'success' && reports.length === 0 &&
								<Row>
									<Row.Item lg={12} md={12} sm={12}>
										<TextInformation 
												icon="search"
												text={t('CABLE.REPORT.TEXT.NO_REPORTS_FOUND')} />
									</Row.Item>
								</Row>
							}

							{(reports && reportStatus === 'success') && reports.length > 0 && 
								<Row>
									<Row.Item lg={12} md={12} sm={12}>
										<TableListPagination list={reports}
															headers={[t('GENERIC.TEXT.NAME'),t('GENERIC.TEXT.PERIOD'),t('GENERIC.TEXT.STATUS'),t('GENERIC.TEXT.TYPE'),t('CABLE.REPORT.TEXT.MADE_IN'),t('GENERIC.TEXT.OPTIONS')]}
															columns={['name','period','status','type', 'created', 'actions']}
															handleColumnRender={handleColumnRender} />
									</Row.Item>
								</Row>
							}
						</>
					</Panel>
				</Row.Item>
			</Row>
			

			{ showModalLoading &&
                <Modal title={t('GENERIC.TEXT.LOADING')} xs>
					<Loading text={t('CABLE.REPORT.TEXT.DOWNLOAD_REPORT')} />
                </Modal>
            }

			{ showModalNewReport &&
                <Modal title={t('CABLE.REPORT.TEXT.SUBMIT_REPORT')} md 
						className={styles['modal-new-report']}>
					<New cancelAction={() => {setShowModalNewReport(false);} }
						saveBeforeAction={()=> {setShowModalNewReport(false); setFilters({...filters})}}/>
                </Modal>
            }

		</Container>
	);
}

export default List;