
import React from 'react';

import { MapView } from '@optimuminterfaces/revex-react-components/dist/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './Detail.module.scss';

interface DetailProps{
	Container?: any,
	time: string,
	lat?: number,
	lng?: number,
	photo?: string,
	className?: string,
	withinArea: boolean
}
const Detail = ({time, lat, lng, photo, withinArea, className, Container='div'}: DetailProps) => {
	return (
		<Container className={`${className?className:''} ${styles['point-registration-detail']}`}>
			
			<Container className={styles['mapview-container']}>
				{lat && lng && 
					<MapView lng={lng} 
						lat={lat} 
						height='80px' 
						id={(Math.random() + 1).toString(20)}
						/> 
				}
				{!lat && !lng && 
					<Container className={styles['no-location']}>
						<FontAwesomeIcon icon='map-marker-alt-slash' />
					</Container>
				}
			</Container>
			<Container className={styles['details']}>
				<Container className={styles['time']}>{time}</Container>
				<Container className={styles['more']}>Área: {withinArea === null? '-': (withinArea?'Sim':'Não')} | Face: {photo?'Sim':'Não'}</Container>
			</Container>
			
		</Container>
	);
}

export default Detail;