import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';
import { fetchAllPointEvents } from '../../../../services/pointEvent.services';
import { Button, Loading, Row, Select, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';

import { uuidv4 } from '@optimuminterfaces/revex-react-components/dist/utils/DeveloperUtil';

import styles from './Change.module.scss';
import { start } from 'repl';
import { postPointRegistrationEventChange } from '../../../../services/pointRegistrationEvent.services';

interface ChangeProps{
	employeeId: string
	employeeName: string,
	employeeOccupation: string,
	startDate: string,
	endDate: String,
	handleChanges: Function,
	Container?: any
}
const Change = ({employeeId, 
				employeeName, 
				employeeOccupation, 
				startDate, 
				endDate, 
				handleChanges, 
				Container='div'}: ChangeProps) => {
					
	const [status, setStatus] = useState('waiting');
	const [confirmation, setConfirmation] = useState<boolean>(false);
	const [loading, setLoading] = useState<{pointEventFetch: boolean, pointRegistrationEventChange: boolean}>
				({ pointEventFetch: true, pointRegistrationEventChange: false });
	const [pointEventOptions, setPointEventOptions] = useState<{key: string, value: string, selected: boolean, type: string}[]>();
	const [pointEventOptionsCurrent, setPointEventOptionsCurrent] = useState<{key: string, value: string, selected: boolean, type: string}[]>();
	const [pointEventOptionsChange, setPointEventOptionsChange] = useState<{key: string, value: string, selected: boolean, type: string}[]>();
    const [pointEventCurrent, setPointEventCurrent] = useState<any>();
	const [pointEventChange, setPointEventChange] = useState<any>();
	const [pointEvents, setPointEvents] = useState();
	
	const [toastData, setToastData] = useState<any>([]);

	const buildSelectOptions = (events:any) => {

        let options: any = [];
        options = events.filter((e:any) => !e.system).map((e: any) => ({
            key: e.id, 
            value:e.name, 
            selected: false,
            type: e.pointEventType
        }));

        setPointEventOptionsCurrent(options);
		setPointEventOptions(options);
            
    }

	useEffect(() => {
        (async () => {
            setLoading({...loading, pointEventFetch: true});

            try{
                const jsonReturned =  await fetchAllPointEvents({systemDefault: false});
                if(jsonReturned){
                        setPointEvents(jsonReturned.pointEvents);
                        setLoading({...loading, pointEventFetch: false});
                        buildSelectOptions(jsonReturned.pointEvents);
                        setStatus('success');

                }else{
                    setStatus('error');
                    setLoading({...loading, pointEventFetch: false});
                }
            }catch(error){
                console.log(error);
                setStatus('error');
            }
        })();

    }, []);

	useEffect(() => {
		if(pointEventCurrent){
			let options = [];
			let types = ['DISCOUNT','ALLOWANCE','DECREMENT_DATABASE'];
			if(pointEventCurrent.type === 'DISCOUNT' || pointEventCurrent.type === 'ALLOWANCE' || pointEventCurrent.type ==='DECREMENT_DATABASE'){
				let x = pointEventOptions!.filter((v:any) =>types.indexOf(v.type) !== -1);
				setPointEventOptionsChange(x);
			}else{
				let x = pointEventOptions!.filter((v:any) =>types.indexOf(v.type) === -1);
				setPointEventOptionsChange(x);
			}
		}
	},[pointEventCurrent]);

	const setSelectOptionCurrent = (key: string, options: {key: string, value: string, selected: boolean, type: string}[], current: boolean ) => {
        let events = options;

        events.map(t =>{
            if(t.key===key){
                t.selected=true;
                current?setPointEventCurrent(t): setPointEventChange(t);
            }else{
                t.selected = false;
            }
        });

        current?setPointEventOptionsCurrent(events): setPointEventOptionsChange(events);
    }

	const internalAddToast = (toastDataParam:any, handleCloseCallback?:Function) => {
        toastDataParam.key = `toast-${uuidv4()}`;
        toastDataParam.closeChange = () => {
            setToastData((toastData: any[]) => toastData.filter(({ key }) => (key !== toastDataParam.key)));
            handleCloseCallback?.();
        };

        setToastData((toastData: any) => [ ...toastData, toastDataParam ]);
    };

	const internalHandleChanges = ({name, value }: {name: string, value?: any}) => {
        let pe = {};

        switch(name){

            case 'pointRegistrationEventTypeCurrent':
                pe= {...pe, pointRegistrationEventType : {id: value}};
                setSelectOptionCurrent(value, pointEventOptionsCurrent!, true);
                break;
            
			case 'pointRegistrationEventTypeChange':
                pe= {...pe, pointRegistrationEventType : {id: value}};
                setSelectOptionCurrent(value, pointEventOptionsChange!, false);
                break;
            
			case 'PointRegistrationEventChange.cancelConfirmation':
				setConfirmation(false);
				break;
            case 'PointRegistrationEventChange.cancel':
                {
                    handleChanges?.({ name, value });
                }
                break;
            case 'PointRegistrationEventChange.change':
                {

                    setLoading({ ...loading, pointEventFetch: false, pointRegistrationEventChange: true });

                    const callbackSuccess = (response: any) => {
                        if(response !== null && response.status === 'SUCCESS'){
                            internalAddToast({
                                show: true,
                                title: t('COMPONENTS.TOAST.TITLE.DATA_CHANGES_SAVE_SUCCESS'),
                                icon: 'save',
                                message: t('CABLE.TOAST.SUCCESS_POINT_EVENT_CHANGE', undefined, response.message),
                                success: true,
                                positionTopRight: true,
                                showTime: 5000
                            }, () => handleChanges?.({ name: 'PointRegistrationEventChange.change'}));
                        }else{

                            internalAddToast({
                                show: true,
                                title: t('COMPONENTS.TOAST.TITLE.SERVER_INTERNAL_ERROR'),
                                icon: 'exclamation-circle',
                                message: response.message? response.message: t('CABLE.TOAST.ERROR_POINT_EVENT_UPDATE'),
                                danger: true,
                                positionTopRight: true,
                                showTime: 5000
                            });

                            setLoading({...loading, pointEventFetch: false, pointRegistrationEventChange: false });
							setConfirmation(false);
                        }
                    };

                    const callbackErrors = (errors: any) => {
                        console.log(errors);
                        internalAddToast({
                            show: true,
                            title: t('COMPONENTS.TOAST.TITLE.SERVER_INTERNAL_ERROR'),
                            icon: 'exclamation-circle',
                            message: t('CABLE.TOAST.ERROR_POINT_EVENT_UPDATE'),
                            warning: true,
                            positionTopRight: true,
                            showTime: 5000
                        });
                        setLoading({ ...loading, pointEventFetch: false, pointRegistrationEventChange: false });
						setConfirmation(false);
                    };

					postPointRegistrationEventChange({employeeId: employeeId, 
													startDate: startDate, 
													endDate: endDate,
													currentPointEventId: pointEventCurrent.key,
													changePointEventId: pointEventChange.key})
							.then(callbackSuccess, callbackErrors);
                   
                }
                break;    

        }

    };

	return(
		<Container className={styles['point-registration-event-change']}>
			{status === 'waiting' &&
                <Row>
                    <Row.Item lg={12} md={12} sm={12}>
                        <Loading text={t('GENERIC.TEXT.LOADING')}/>
                    </Row.Item>
                </Row>
            }
            {(status === 'error') &&
                <Row>
                    <Row.Item lg={12} md={12} sm={12}>
                            <TextInformation
                                icon='exclamation-circle'
                                text={t('GENERIC.TEXT.UNABLE_RETURN')} />
                    </Row.Item>
                </Row>
            }
            {(status === 'success' && !loading.pointEventFetch && !confirmation) &&
               <Row>
					<Row.Item sm={12} md={12} lg={12}>
                        <Select 
                            name='pointRegistrationEventTypeCurrent'
                            fullWidth
                            title={t('GENERIC.TEXT.EVENT_TYPE')}
                            options={pointEventOptionsCurrent!}
                            value={pointEventCurrent?.value}
                            handleChange={(event) => internalHandleChanges({name: 'pointRegistrationEventTypeCurrent', value: event})}
                    	/>
                    </Row.Item>
					<Row.Item sm={12} md={12} lg={12} className={styles['icon']}>
						<FontAwesomeIcon icon='sync' size='3x' />
					</Row.Item>
					<Row.Item sm={12} md={12} lg={12}>
                        <Select 
                            name='pointRegistrationEventTypeChange'
                            fullWidth
                            title={t('GENERIC.TEXT.EVENT_TYPE_CHANGE')}
                            options={pointEventOptionsChange?pointEventOptionsChange:[]}
							disabled={!pointEventOptionsChange}
                            value={pointEventChange?.value}
                            handleChange={(event) => internalHandleChanges({name: 'pointRegistrationEventTypeChange', value: event})}
                    	/>
                    </Row.Item>
					<Row.Item sm={12} md={12} lg={12} className={styles['point-registration-event-footer']}>
						<Container className={ `${styles['point-event-footer']}`}>
							<Button title={t('GENERIC.BUTTON.CANCEL.TEXT')}
								outlined 
								loading={loading.pointRegistrationEventChange} 
								action={(e) => { 
									e?.preventDefault?.();
									internalHandleChanges({ name: 'PointRegistrationEventChange.cancel'});
								}} />

							<Button title={t('GENERIC.BUTTON.SAVE.TEXT')} 
								primary 
								icon='save' 
								loading={loading.pointRegistrationEventChange} 
								style={{ marginLeft: '5px' }} 
								disabled={!pointEventChange || !pointEventCurrent}
								action={(e) => {
									setConfirmation(true);
								}} />
						</Container>
					</Row.Item>
			   </Row>
			}
			{(status === 'success' && confirmation) && 
				<Container>
					<Row>
						<Row.Item sm={12} md={12} lg={12}>
							<TextInformation text={'Tem certeza que deseja trocar o evento do colaborador '
												+ employeeName +' do tipo '+
												pointEventCurrent.value+ ' ' +
												'para o evento '+
												pointEventChange.value
												+'?'} 
										icon="question-circle" />		
						</Row.Item>
						<Row.Item sm={12} md={12} lg={12} className={styles['point-registration-event-footer']}>
							<Container className={ `${styles['point-event-footer']}`}>
								<Button title={t('GENERIC.TEXT.NO')}
									outlined 
									loading={loading.pointRegistrationEventChange} 
									action={(e) => { 
										e?.preventDefault?.();
										internalHandleChanges({ name: 'PointRegistrationEventChange.cancelConfirmation'});
									}} />

								<Button title={t('GENERIC.TEXT.YES')} 
									primary 
									icon='save' 
									loading={loading.pointRegistrationEventChange} 
									style={{ marginLeft: '5px' }} 
									action={(e) => {
										setConfirmation(true);
										e?.preventDefault?.();
										internalHandleChanges({ name: 'PointRegistrationEventChange.change'});    
										
									}} />
							</Container>
						</Row.Item>
					</Row>
				</Container>
			}
			{toastData?.map?.((toastDataE:any) => (
                <Toast {...toastDataE} />
            ))}
		</Container>
	);
}

export default Change;