import { Button, DualList, GeoLocation, Input, Loading, MapView, PageTitle, Panel, Row, Select, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Employee } from '../../../../models/Employee';
import { PointLimitArea } from '../../../../models/PointLimitArea';
import { fetchAllEmployeeAvailable, fetchPointLimitAreaById, putPointLimitArea } from '../../../../services/pointLimitArea.services';

import styles from './Edit.module.scss';


import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

interface EditProps{
	Container?: any;
}
const Edit = ({Container='div'}: EditProps) => {

	const pointLimitAreaTypes = [
		{key:'limit', value:t('CABLE.AREA.NEW.LIMIT_AREA',undefined), selected: false},
		{key:'noRestriction', value:t('CABLE.AREA.NEW.UNRESTRICTED_AREA',undefined), selected: false},
	];

	let navigate = useNavigate();
	const [limitAreaStatus, setLimitAreaStatus] = useState<string>('waiting');
	const [employeeAvailableStatus, setEmployeeAvailableStatus] = useState<string>('waiting');
	const [limitArea, setLimitArea] = useState<PointLimitArea>();
	const [lat, setLat] = useState<number>();
	const [lng, setLng] = useState<number>();
	const [locationStatus, setLocationStatus] = useState<string>();
	const [radius, setRadius] = useState<number>(100);
	const [listTypes, setListTypes] = useState(pointLimitAreaTypes);
	const [typeValue, setTypeValue] = useState<any>();
	const [name, setName] = useState<string>('');
	const [dualListOptions, setDualListOptions] = useState<any[]>();
	const [dualListOptionsSelected, setDualListOptionsSelected] = useState<string[]>();
	const [loadingButtonSave, setLoadingButtonSave] = useState(false);
	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false})

	//Parametros da URL
	const [params, setParams] = useState(useParams());

	useEffect(() => {
		const buildDualListOptions = (employees: Employee[], employeesSelected: string[] ) => {
			let dualList: { key: string; selected: boolean; values: any[]; }[] = [];
			employees.forEach(employee => {
				dualList.push({key: employee.id, selected: employeesSelected?.indexOf(employee.id) !== -1, values: [employee.name,employee.occupation?.name]})
			});
			
			setDualListOptions(dualList);
		}
		// (async () => {
		// 	setEmployeeAvailableStatus('waiting');
		// 	try{
		// 		const jsonReturned =  await fetchAllEmployeeAvailable();
		// 		if(jsonReturned){
		// 			buildDualListOptions(jsonReturned.employees);
		// 			setEmployeeAvailableStatus('success');
		// 		}else{
		// 			setLimitAreaStatus('error');
		// 		}
		// 	}catch(error){
		// 		console.log(error);
		// 	}

		// })();

		(async () => {
			setLimitAreaStatus('waiting');
			try{
				const jsonReturned =  await fetchPointLimitAreaById(params.id!);
				if(jsonReturned){
					let pointLimitArea: PointLimitArea = jsonReturned.pointLimitAreas
					setLimitArea(pointLimitArea);
					setLat(pointLimitArea.lat?pointLimitArea.lat: lat);
					setLng(pointLimitArea.lng?pointLimitArea.lng: lng);
					setName(pointLimitArea.name);
					setRadius(pointLimitArea.radius? pointLimitArea.radius:0);
					setDualListOptionsSelected(pointLimitArea?.employees.split(','))
					listTypes.forEach(ty => {
						if(ty.key===pointLimitArea.limitAreaType){
							setTypeValue(ty);
						}
					});
					
					setLimitAreaStatus('success');

					setEmployeeAvailableStatus('waiting');
					//Chamada encadeada para evitar que a promise de employees termine primeiro do que 
					//os dados da area
					try{
						const jsonReturned =  await fetchAllEmployeeAvailable();
						if(jsonReturned){
							buildDualListOptions(jsonReturned.employees, pointLimitArea?.employees.split(','));
							setEmployeeAvailableStatus('success');
						}else{
							setLimitAreaStatus('error');
						}
					}catch(error){
						console.log(error);
					}
				}else{
					setLimitAreaStatus('error');
				}
			}catch(error){
				console.log(error);
			}

		})();
    }, []);

	// filtra todos colaboradores selecionados
	const handleChangeDualList = (returnOptions: any) => {
        let listReturn = returnOptions.filter((e:any) => e.selected === true);
        let listContains = listReturn.map((e:any) => e.key);

        setDualListOptionsSelected(listContains);
    };

	const updateLimitArea = async () => {
		if(!name || name ===''){
			setToastAlert({...toastAlert, danger: true, message:t('CABLE.AREA.NEW.INFORM_NAME',undefined),show: true});
			return;
		}
		if(!typeValue || typeValue ===''){
			setToastAlert({...toastAlert, danger: true, message:t('CABLE.AREA.NEW.INFORM_TYPE',undefined),show: true});
			return;
		}
		if(typeValue.key === 'limit' && !radius){
			setToastAlert({...toastAlert, danger: true, message:t('CABLE.AREA.EDIT.RADIO',undefined),show: true});
			return;
		}
		if(!dualListOptionsSelected || dualListOptionsSelected.length ===0){
			setToastAlert({...toastAlert, danger: true, message:t('CABLE.AREA.NEW.SELECT_EMPLOYEE',undefined),show: true});
			return;
		}
		setLoadingButtonSave(true);
		try{
			const jsonReturned =  await putPointLimitArea(limitArea!.id, {id: limitArea!.id, name: name, 
															radius: radius,
															instanceId: limitArea!.instanceId,
															lat: lat,
															lng: lng,
															limitAreaType: typeValue.key,
															employees: dualListOptionsSelected.join(',')});
			if(jsonReturned){
				if(jsonReturned.status==='ERROR'){
					setToastAlert({...toastAlert, danger: true, message:jsonReturned.message,show: true});
					setLoadingButtonSave(false);
				}else{
					setToastAlert({...toastAlert, danger: false, message:jsonReturned.message,show: true});
					setTimeout(() => { 
						setLoadingButtonSave(false); 
						navigate('/srepsMF/limitAreas') 
					}, 3000);
				}
			}else{
				//setLimitAreaStatus('error');
			}
		}catch(error){
			console.log(error);
		}
	}

	const changeSelectType = (key: string) => {
        let types = [...listTypes];

        for (var i = 0; i < types.length; i++) {
            if (types[i].key === key) {
                types[i].selected = true;
                setTypeValue(types[i]);
            } else {
                types[i].selected = false;
            }
        }

        setListTypes(types);

    }

	return(
		<Container className={styles['point-limit-area-edit-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CABLE.AREA.EDIT.TITLE',undefined)} />
				</Row.Item>
			</Row>
			{(limitAreaStatus === 'waiting' || employeeAvailableStatus === 'waiting') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING',undefined)}/>
						</Panel>
					</Row.Item>
				</Row>
			}

			{locationStatus !== 'success' && limitAreaStatus === 'success' && employeeAvailableStatus === 'success' && (
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Panel>
							<GeoLocation handleGeoLocation={(event: any) => {
								if(event.name==='retrive_location'){
									if(!lat && !lng){
										setLat(event.lat); 
										setLng(event.lng);
									}
									setLocationStatus('success');
								}
								if(event.name==='location'){
									setLocationStatus('location');
								}
								}} />
						</Panel>
					</Row.Item>
				</Row>
			)}

			{locationStatus === 'success' && limitAreaStatus === 'success' && employeeAvailableStatus === 'success' && (
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel>
						<Row>
							<Row.Item sm={12} md={12} lg={12}>
								<Input type="text" fullWidth
									title={t('CABLE.AREA.NEW.NAME',undefined)}
									name="closedPeriodName"
									value={name}
									//status={inputStatus}
									handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {setName(event.target.value)}} /> 
							</Row.Item>
							<Row.Item sm={10} md={10} lg={10}>
								<Select medium 
										fullWidth 
										className={styles['selectType']}
										name="selectType" 
										value={typeValue.value} 
										title={t('CABLE.AREA.NEW.PROCEED',undefined)} 
										options={pointLimitAreaTypes} 
										handleChange={(key: string) => {changeSelectType(key)}} />
							</Row.Item>
							<Row.Item sm={2} md={2} lg={2}>
								<Input type="number" fullWidth
									title={t('CABLE.AREA.NEW.RADIUS',undefined)}
									name="closedPeriodName"
									value={radius}
									//status={inputStatus}
									handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {setRadius(parseInt(event.target.value))}} /> 
							</Row.Item>
							<Row.Item sm={12} md={12} lg={12}>
								{typeValue.key==='limit' && <MapView lng={lng!} 
									lat={lat!} 
									radius={radius} 
									height='250px'
									enableSearch
									id='point-limit-area-new' 
									handleClick = {(event) => {setLat(event.lat); setLng(event.lng); console.log(event)}}
									enableZoom/> 
								}
							</Row.Item>
							<Row.Item sm={12} md={12} lg={12}>
								<h3>{t('CABLE.AREA.NEW.SELECTION',undefined)}</h3>
								<DualList 
									titleAvailable={t('CABLE.AREA.NEW.AVAILABLE_EMPLOYEES',undefined)}
									titleSelected={t('CABLE.AREA.NEW.SELECTED_EMPLOYEES',undefined)}
									options={dualListOptions!}
									firstIcon="user"
									secondIcon="tools"
									handleChange={handleChangeDualList} />
							</Row.Item>
							<Row.Item sm={12} md={12} lg={12}>
								<Container className={styles['actions']}>
									<Button danger md
										title={t('GENERIC.TEXT.CANCEL',undefined)}
										icon="times"
										className={styles['button-action']}
										action={() => { navigate('/srepsMF/limitAreas') }} />
									<Button primary md
										title={t('GENERIC.TEXT.UPDATE',undefined)}
										icon="save"
										//disabled={inputStatus !== "success" || optionsSelected.length === 0}
										loading={loadingButtonSave}
										className={styles['button-action']}
										action={() => { updateLimitArea() }} />
								</Container>
							</Row.Item>
						</Row>
						
					</Panel>
				</Row.Item>
			</Row>
			)}

			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION',undefined)}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}
		</Container>
	);
}

export default Edit;