import { calculateCycleOfEnvironment } from "./DeveloperUtil";
export const calculateSelectYear = () => {
	const currentYear = new Date().getFullYear();
	let year = [];

	for (let i = 3; i >= 0; i--) {
		year.push({ key: (currentYear - i).toString(), value: (currentYear - i).toString(), selected: i === 0 });
	}
	for (let i = 1; i < 5; i++) {
		year.push({ key: (currentYear + i).toString(), value: (currentYear + i).toString(), selected: false });
	}

	return year;
}

export const calculateSelectMonth = () => {

	let month: { key: string, value: string, selected: boolean, startDay: string, endDay: string }[] = [];

    let cycleOfEnvironment = calculateCycleOfEnvironment();

	const currentDate = new Date();
	let currentMonth = currentDate.getMonth();

    month = cycleOfEnvironment.map((cycle, index) => {
        return {...cycle, selected: index === currentMonth};
    });

	return month;
}