import React from 'react';
import { Row, BoxInformation } from '@optimuminterfaces/revex-react-components/dist/components/index';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';
const Dashboard = () => {
	return (
		<Row>
			<Row.Item xs={12} sm={4} md={4} lg={4}>
				<BoxInformation small title={t('GENERIC.TEXT.OVERTIME')} value='32:00' icon='plus'/>
			</Row.Item>
			<Row.Item xs={12} sm={4} md={4} lg={4}>
				<BoxInformation small title={t('GENERIC.TEXT.OVERTIME')} value='32:00' icon='plus'/>
			</Row.Item>
			<Row.Item xs={12} sm={4} md={4} lg={4}>
				<BoxInformation small title={t('GENERIC.TEXT.OVERTIME')} value='32:00' icon='plus'/>
			</Row.Item>
			<Row.Item xs={12} sm={4} md={4} lg={4}>
				<BoxInformation small title={t('GENERIC.TEXT.OVERTIME')} value='32:00' icon='plus'/>
			</Row.Item>
		</Row>
	)
}

export default Dashboard;