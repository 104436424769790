import React from 'react';

import { PointRegistrationCalculate } from '../../../../../models/PointRegistrationCalculate';
import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index';

import { Table } from '@optimuminterfaces/revex-react-components/dist/components';

import styles from './Calculate.module.scss';
import { convertMinutesToTimeStr, formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { convertFloatToTimeStr } from '../../../../../utils/DateTimeUtil';

interface CalculateProps{
	pointRegistrationCalculate: PointRegistrationCalculate[]
	Container?: any
}

const Calculate = ({pointRegistrationCalculate, Container='div'}:CalculateProps) => {
	const firstDay = pointRegistrationCalculate[0].pointCalculate;
	

	return (
		<Container className={styles['point-calculate-details-calculate']}>
			<Table primary responsible className={styles['point-registration-details-calculate-table']} striped>
				<Table.Header>
					<Table.HeaderItem>
						<Table.HeaderItem.Element>{t('GENERIC.TEXT.DATE')}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_JOURNEY']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_WORKING_HOURS']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_LATE_WORK']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_LATE']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_OVERTIME']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_COMPENSATION_TIME']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_HOURS_INTRAJOURNEY']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_ALLOWANCE']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_DECREMENT_DATABASE']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_INCREMENT_DATABASE']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_PAYMENT']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_DISCOUNT']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_HOLIDAY']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_OVERTIME_HOLIDAY']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_OVERTIME_OFF']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_LATE']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_DSR']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_OVERTIME_INTRAJOURNEY']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_NIGHT']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_ADDITIONL_NIGHT']['name']}</Table.HeaderItem.Element>
						{
							firstDay['SYSTEM_OVERTIME']['splitHours'].map((splitHour: any, index: number) => (
								<Table.HeaderItem.Element key={index}>
									{splitHour.typeTranslate}{splitHour.division !== null ? ' ' + splitHour.division + '%' : ''}
								</Table.HeaderItem.Element>
							))
						}
						<Table.HeaderItem.Element>{firstDay['SYSTEM_COMPENSATION_TIME']['name']}</Table.HeaderItem.Element>
						<Table.HeaderItem.Element>{firstDay['SYSTEM_COMPENSATION_TIME_BALANCE']['name']}</Table.HeaderItem.Element>
					</Table.HeaderItem>
				</Table.Header>
				<Table.Body>
					{pointRegistrationCalculate.map((pointRegistration,index) => 
						<Table.BodyItem key={index + pointRegistration.datePoint}> 
							<Table.BodyItem.Item className={styles['date']}>
								<div>{formatLocalDate(pointRegistration.datePoint, true, 'YYYY-MM-DD', 'DD/MM/YY')}</div>
								<div>{pointRegistration.datePointWeekDay.toUpperCase()}</div>
							</Table.BodyItem.Item>
							<Table.BodyItem.Item className={styles['journey']}>
								{
								pointRegistration.pointCalculate['SYSTEM_JOURNEY']['value'] !== 0 ?
									convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_JOURNEY']['value']) : '-'
								}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item>
								{pointRegistration.pointCalculate['SYSTEM_WORKING_HOURS']['value'] !== 0 ?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_WORKING_HOURS']['value']) : '-'}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item className={styles['late']}>
								{pointRegistration.pointCalculate['SYSTEM_LATE_WORK']['value'] !== 0 ?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_LATE_WORK']['value']) : '-'}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item className={styles['late']}>
								{pointRegistration.pointCalculate['SYSTEM_LATE']['value'] !== 0 ?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_LATE']['value']) : '-'}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item className={styles['overtime']}>
								{pointRegistration.pointCalculate['SYSTEM_OVERTIME']['value'] !== 0 ?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_OVERTIME']['value']) : '-'}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item>
								{pointRegistration.pointCalculate['SYSTEM_COMPENSATION_TIME']['type'] === 'positive' ? '+' : '-'}
								{convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_COMPENSATION_TIME']['value'])}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item>
								{pointRegistration.pointCalculate['SYSTEM_HOURS_INTRAJOURNEY']['value'] !== 0 ?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_HOURS_INTRAJOURNEY']['value']) : '-'}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item>
								{pointRegistration.pointCalculate['SYSTEM_ALLOWANCE']['value'] !== 0 ?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_ALLOWANCE']['value']) : '-'}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item>
								{pointRegistration.pointCalculate['SYSTEM_DECREMENT_DATABASE']['value'] !== 0 ?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_DECREMENT_DATABASE']['value']) : '-'}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item>
								{pointRegistration.pointCalculate['SYSTEM_INCREMENT_DATABASE']['value'] !== 0 ?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_INCREMENT_DATABASE']['value']) : '-'}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item>
								{pointRegistration.pointCalculate['SYSTEM_PAYMENT']['value'] !== 0 ?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_PAYMENT']['value']) : '-'}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item>
								{pointRegistration.pointCalculate['SYSTEM_DISCOUNT']['value'] !== 0 ?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_DISCOUNT']['value']) : '-'}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item>
								{
								pointRegistration.pointCalculate['SYSTEM_HOLIDAY']['value'] === true ? t('GENERIC.TEXT.YES') : t('GENERIC.TEXT.NO')
								}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item>
								{pointRegistration.pointCalculate['SYSTEM_OVERTIME_HOLIDAY']['value'] !== 0 ?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_OVERTIME_HOLIDAY']['value']) : '-'}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item>
								{pointRegistration.pointCalculate['SYSTEM_OVERTIME_OFF']['value'] !== 0 ?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_OVERTIME_OFF']['value']) : '-'}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item>
								{pointRegistration.pointCalculate['SYSTEM_LATE']['value'] !== 0 ?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_LATE']['value']) : '-'}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item>
								{pointRegistration.pointCalculate['SYSTEM_DSR']['value'] === true ?
									`${t('GENERIC.TEXT.YES')} 
											(${formatLocalDate(pointRegistration.pointCalculate['SYSTEM_DSR']['startDate'], true, 'YYYY-MM-DD', 'DD/MM/YY')} - 
											${formatLocalDate(pointRegistration.pointCalculate['SYSTEM_DSR']['endDate'], true, 'YYYY-MM-DD', 'DD/MM/YY')})`
									: t('GENERIC.TEXT.NO')}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item>
								{pointRegistration.pointCalculate['SYSTEM_OVERTIME_INTRAJOURNEY']['value'] !== 0 ?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_OVERTIME_INTRAJOURNEY']['value']) : '-'}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item>
								{pointRegistration.pointCalculate['SYSTEM_NIGHT']['value'] !== 0 ?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_NIGHT']['value']) : '-'}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item>
								{pointRegistration.pointCalculate['SYSTEM_ADDITIONL_NIGHT']['value'] !== 0 ?
								convertFloatToTimeStr(pointRegistration.pointCalculate['SYSTEM_ADDITIONL_NIGHT']['value']) : '-'}
							</Table.BodyItem.Item>
								{
								pointRegistration.pointCalculate['SYSTEM_OVERTIME']['splitHours'].map((splitHour: any, index: number) => (
									<Table.BodyItem.Item key={'splitHours'+index+pointRegistration.datePointWeekDay}>{splitHour.value !== 0 ? convertMinutesToTimeStr(splitHour.value) : '-'}</Table.BodyItem.Item>
								))
								}
							<Table.BodyItem.Item className={styles['compensation']}>
								{pointRegistration.pointCalculate['SYSTEM_COMPENSATION_TIME']['type'] === 'positive' ? '+' : '-'}
										{convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_COMPENSATION_TIME']['value'])}
							</Table.BodyItem.Item>
							<Table.BodyItem.Item className={styles['compensation']}>
								{pointRegistration.pointCalculate['SYSTEM_COMPENSATION_TIME_BALANCE']['type'] === 'positive' ? '+' : '-'}
										{convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_COMPENSATION_TIME_BALANCE']['value'])}
							</Table.BodyItem.Item>
						</Table.BodyItem>
					)}
					
				</Table.Body>
			</Table>
		</Container>
	);
}

export default Calculate;