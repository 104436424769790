import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BoxInformation, Loading, PageTitle, Panel, Row, Select, TextInformation } from '@optimuminterfaces/revex-react-components/dist/components';
import { fetchMyHourBankBalances } from '../../../../../services/hourBankBalances.services';
import { calculateSelectYear } from '../../../../../utils/SelectUtil';
import { convertMinutesToTimeStr } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';

import styles from './List.module.scss';
import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

interface ListProps{
	Container?: any,
	Span?: any
}
const List = ({ Container = 'div', Span = 'span' }: ListProps) => {
	const years = calculateSelectYear();

	let navigate = useNavigate();

	const [hourBankBalance, setHourBankBalance] = useState<any>();
    const [listYears, setListYears] = useState(years);
    const [yearValue, setYearValue] = useState(years[3]);
	const [hourBankBalanceStatus, setHourBankBalanceStatus] = useState('waiting');


	useEffect(() => {
		(async () => {
			setHourBankBalanceStatus('waiting');
			
			try{
				const jsonReturned =  await fetchMyHourBankBalances({year: yearValue.key});
				if(jsonReturned){
					setHourBankBalanceStatus('success');
					setHourBankBalance(jsonReturned.hourBankBalance);
				}else{
					setHourBankBalanceStatus('error');
				}
			}catch(error){
				console.log(error);
			}

		})();
	},[yearValue]);

	const changeSelectYear = (key: string, notFind: null | boolean) => {
        let years = [...listYears];

        for (var i = 0; i < years.length; i++) {
            if (years[i].key === key) {
                years[i].selected = true;
                setYearValue(years[i]);
            } else {
                years[i].selected = false;
            }
        }

        setListYears([...years]);

    };

	const renderTimeSignal = (minutes: number) => {
		return (
			(minutes >= 0? '+':'') + convertMinutesToTimeStr(minutes)
		)
	}

	return(
		<Container className={styles['hour-bank-balance-my-list-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('GENERIC.TEXT.HOUR_BANK_BALANCES')} />
				</Row.Item>
			</Row>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel title={t('GENERIC.TEXT.FILTERS')} noTitleBorder>
						<Row>
							<Row.Item lg={2} md={2} sm={12} offsets={{xs: 0, md: 10, lg: 10}}>
								<Select medium fullWidth
									title={t('GENERIC.TEXT.YEAR')}
									name="selectYear"
									value={yearValue.value}
									options={listYears}
									handleChange={changeSelectYear} />
							</Row.Item>
						</Row>
					</Panel>
				</Row.Item>
			</Row>

			{(hourBankBalanceStatus === 'waiting') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING')}/>
						</Panel>
					</Row.Item>
				</Row>
			}

			{(hourBankBalanceStatus === 'error') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation 
								icon="exclamation-circle"
								text={t('GENERIC.TEXT.UNABLE_RETURN')}/>
						</Panel>
					</Row.Item>
				</Row>
			}

			{(hourBankBalanceStatus === 'success' && hourBankBalance === null) &&
                <Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation
								icon='search'
								text={t('HOUR_BANK_BALANCES.TEXT.NOT_FOUND')} />
						</Panel>
					</Row.Item>
				</Row>
            }

			{(hourBankBalance && hourBankBalanceStatus === 'success' && hourBankBalance !== null) &&
                <Row>
					<Row.Item lg={12} md={12} sm={12}>
						<>
							<Row.Item lg={2} md={3} sm={12}>
								<BoxInformation title={t('GENERIC.MONTHS.JANUARY')}
									value={renderTimeSignal(hourBankBalance.january)}
									icon="clock"
									small
									danger={hourBankBalance.january < 0 ? true : false}
									detail={() => {  navigate(`/srepsMF/pointRegistrations/my?month=january&year=${yearValue.key}`) }} />
							</Row.Item>
							<Row.Item lg={2} md={3} sm={12}>
								<BoxInformation title={t('GENERIC.MONTHS.FEBRUARY')}
									value={renderTimeSignal(hourBankBalance.february)}
									icon="clock"
									small
									danger={hourBankBalance.february < 0 ? true : false}
									detail={() => { navigate(`/srepsMF/pointRegistrations/my?month=february&year=${yearValue.key}`) }} />
							</Row.Item>
							<Row.Item lg={2} md={3} sm={12}>
								<BoxInformation title={t('GENERIC.MONTHS.MARCH')}
									value={renderTimeSignal(hourBankBalance.march)}
									icon="clock"
									small
									danger={hourBankBalance.march < 0 ? true : false}
									detail={() => { navigate(`/srepsMF/pointRegistrations/my?month=march&year=${yearValue.key}`) }} />
							</Row.Item>
							<Row.Item lg={2} md={3} sm={12}>
								<BoxInformation title={t('GENERIC.MONTHS.APRIL')}
									value={renderTimeSignal(hourBankBalance.april)}
									icon="clock"
									small
									danger={hourBankBalance.april < 0 ? true : false}
									detail={() => { navigate(`/srepsMF/pointRegistrations/my?month=april&year=${yearValue.key}`) }} />
							</Row.Item>
							<Row.Item lg={2} md={3} sm={12}>
								<BoxInformation title={t('GENERIC.MONTHS.MAY')}
									value={renderTimeSignal(hourBankBalance.may)}
									icon="clock"
									small
									danger={hourBankBalance.may < 0 ? true : false}
									detail={() => { navigate(`/srepsMF/pointRegistrations/my?month=may&year=${yearValue.key}`) }} />
							</Row.Item>
							<Row.Item lg={2} md={3} sm={12}>
								<BoxInformation title={t('GENERIC.MONTHS.JUNE')}
									value={renderTimeSignal(hourBankBalance.june)}
									icon="clock"
									small
									danger={hourBankBalance.june < 0 ? true : false}
									detail={() => { navigate(`/srepsMF/pointRegistrations/my?month=june&year=${yearValue.key}`) }} />
							</Row.Item>
							<Row.Item lg={2} md={3} sm={12}>
								<BoxInformation title={t('GENERIC.MONTHS.JULY')}
									value={renderTimeSignal(hourBankBalance.july)}
									icon="clock"
									small
									danger={hourBankBalance.july < 0 ? true : false}
									detail={() => { navigate(`/srepsMF/pointRegistrations/my?month=july&year=${yearValue.key}`) }} />
							</Row.Item>
							<Row.Item lg={2} md={3} sm={12}>
								<BoxInformation title={t('GENERIC.MONTHS.AUGUST')}
									value={renderTimeSignal(hourBankBalance.august)}
									icon="clock"
									small
									danger={hourBankBalance.august < 0 ? true : false}
									detail={() => { navigate(`/srepsMF/pointRegistrations/my?month=august&year=${yearValue.key}`) }} />
							</Row.Item>
							<Row.Item lg={2} md={3} sm={12}>
								<BoxInformation title={t('GENERIC.MONTHS.SEPTEMBER')}
									value={renderTimeSignal(hourBankBalance.september)}
									icon="clock"
									small
									danger={hourBankBalance.september < 0 ? true : false}
									detail={() => { navigate(`/srepsMF/pointRegistrations/my?month=september&year=${yearValue.key}`) }} />
							</Row.Item>
							<Row.Item lg={2} md={3} sm={12}>
								<BoxInformation title={t('GENERIC.MONTHS.OCTOBER')}
									value={renderTimeSignal(hourBankBalance.october)}
									icon="clock"
									small
									danger={hourBankBalance.october < 0 ? true : false}
									detail={() => { navigate(`/srepsMF/pointRegistrations/my?month=october&year=${yearValue.key}`) }} />
							</Row.Item>
							<Row.Item lg={2} md={3} sm={12}>
								<BoxInformation title={t('GENERIC.MONTHS.NOVEMBER')}
									value={renderTimeSignal(hourBankBalance.november)}
									icon="clock"
									small
									danger={hourBankBalance.november < 0 ? true : false}
									detail={() => { navigate(`/srepsMF/pointRegistrations/my?month=november&year=${yearValue.key}`) }} />
							</Row.Item>
							<Row.Item lg={2} md={3} sm={12}>
								<BoxInformation title={t('GENERIC.MONTHS.DECEMBER')}
									value={renderTimeSignal(hourBankBalance.december)}
									icon="clock"
									small
									danger={hourBankBalance.december < 0 ? true : false}
									detail={() => { navigate(`/srepsMF/pointRegistrations/my?month=december&year=${yearValue.key}`) }} />
							</Row.Item>
						</>
					</Row.Item>
				</Row>
            }
		</Container>
	)
}

export default List;