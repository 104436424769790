export const translateStatus = (status:string) => {
	switch (status) {
		case 'AWAIT_PROCESSING':
			return 'Aguardando Processamento';
		case 'ERROR':
			return 'Erro';
		case 'PROCESSED':
			return 'Processado';
		default:
			return '-';
	}
}

export const translateType = (type: string) => {
	switch (type) {
		case 'point_events':
			return 'Eventos de ponto';
		default:
			return '-';
	}
}
