import React from 'react';

import { Time } from '@optimuminterfaces/revex-react-components/dist/components';

import styles from './DailyScaleHours.module.scss';

interface DailyScaleHoursProps {
    className?: string,
    dailyScaleDay?: string,
    primary?: boolean,
    warning?: boolean,
    danger?: boolean,
    success?: boolean,
    Container?: any
};

const DailyScaleHours = ({
    className,
    dailyScaleDay,
    primary,
    warning,
    danger,
    success,
    Container = 'div'
}: DailyScaleHoursProps) => {
    return (
        <Container className={`${styles['daily-scale-hours-rt']} ${className || ''}`.trim()}>
            {dailyScaleDay?.split('-').map(daily =>
                <Time className={styles['daily-scale-time']}
                    key={daily} 
                    time={daily} 
                    primary={primary} 
                    warning={warning} 
                    danger={danger} 
                    success={success} />)}
        </Container>
    );
};

export default DailyScaleHours; 
