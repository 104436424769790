import API, { formatDataToFormData } from './core';

export const fetchPointRegistrations = async (params: any) => {
	try{
		return API.get('/rest/points/pointRegistrations', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchMyPointRegistrations = async (params: any) => {
	try{
		return API.get('/rest/points/pointRegistrations/my', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchMyPointRegistrationsTodayInformation = async () => {
	try{
		return API.get('/rest/points/pointRegistrations/my/getTodayInformation')
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchAllComments = async () => {
	try{
		return API.get('/rest/points/pointRegistrations/getComments')
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const postMyPointRegistration = async (params?: any) => {
	try{
		return API.post('/rest/points/pointRegistrations/createMy', formatDataToFormData(params))
		.then(
			response => {
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchAllEmployeeAvailablePointRegistration = async (params?: any) => {
	try{
		return API.get('/rest/points/pointRegistrations/employeeAvailable', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const generateReportEmployeePointLeaf = async (params: any) => {
	try{
		return API.get('/rest/points/pointRegistrations/generateReportEmployeePointLeaf',{ params: params, headers: { 'Content-Type': 'application/json' }, 
		responseType: 'arraybuffer' })
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const generateReportEmployeePoint = async (params: any, headers?: any) => {
	try{
		const configs: object = { 
			params: params, 
			headers: { 
				...headers,
				'Content-Type': 'application/json' 
			}, 
			responseType: 'arraybuffer'
		};

		return API.get('/rest/points/pointRegistrations/generateReportEmployee', configs)
			.then(
				response => {
					if (response.data.status === 'ERROR') {
						return null;
					} else {
						return response.data;
					}

				}, error => {
					console.log(error);
					return null;
				}
			);
	}catch(error){
		return error;
	}
}

export const postMassUpdate = async (data?: any, params?: any) => {
	try{
		return API.post('/rest/points/pointRegistrations/massUpdate', JSON.stringify(data), {params: params,  headers: {'Content-Type': 'application/json'}})
		.then(
			response => {
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}
