import { Employee } from "../models/Employee";

import { formatLocalDate } from "@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil";
import { translation as t } from "@optimuminterfaces/revex-react-components/dist/translation";

export const buildListWarning = (listEmployees: Employee[],
    listEmployeeIdSelecteds: string[],
    startDateLastClosedPeriod: Date) => {

    let listWarning: string[] = [];

    listEmployees.forEach(employee => {
        if (!!employee.startDateLastClosedPeriod && listEmployeeIdSelecteds.includes(employee.id)) {
            let employeeStartDateLastClosedPeriod: Date = new Date(`${employee.startDateLastClosedPeriod}T00:00:00`);

            if (employeeStartDateLastClosedPeriod.getTime() < startDateLastClosedPeriod.getTime()) {
                let lastClosedPeriod: string = !!employee.startDateLastClosedPeriod ?
                    `${formatLocalDate(employee.startDateLastClosedPeriod, true, 'YYYY-MM-DD', 'DD/MM/YY')} - ${formatLocalDate(employee.endDateLastClosedPeriod, true, 'YYYY-MM-DD', 'DD/MM/YY')}` :
                    t('CLOSED_PERIODS.TEXT.NO_CLOSED_PERIOD');

                listWarning.push(`${employee.name} - (${t('CLOSED_PERIODS.TEXT.LAST_PERIOD_CLOSED')}: ${lastClosedPeriod})`);
            }
        }
    });

    return listWarning;
};

export const buildListError = (listEmployees: Employee[],
    listEmployeeIdSelecteds: string[],
    originalListEmployeeIdSelecteds: string[],
    actualStartDatePeriod: Date) => {

    let listErrors: string[] = [];
    let listEmployeeIdsRemoved: string[] = [];

    originalListEmployeeIdSelecteds.forEach(employeeId => {
        if (!listEmployeeIdSelecteds.includes(employeeId)) {
            listEmployeeIdsRemoved.push(employeeId);
        }
    });

    listEmployees.forEach(employee => {
        if (listEmployeeIdSelecteds.includes(employee.id)) {
            if (employee?.containsEventInPeriod === false) {
                listErrors.push(t('CLOSED_PERIODS.TEXT.EMPLOYEE_HAS_NO_EVENT', undefined, employee.name));
            }

            if (employee?.containsJustificationPendingInPeriod === true) {
                listErrors.push(t('CLOSED_PERIODS.TEXT.EMPLOYEE_WITH_PENDING_JUSTIFICATION', undefined, employee.name));
            }
        }

        if (!!employee.startDateLastClosedPeriod && listEmployeeIdsRemoved.includes(employee.id)) {
            let employeeStartDateLastClosedPeriod: Date = new Date(`${employee.startDateLastClosedPeriod}T00:00:00`);

            if (employeeStartDateLastClosedPeriod.getTime() > actualStartDatePeriod.getTime()) {
                let lastClosedPeriod: string = !!employee.startDateLastClosedPeriod ?
                    `${formatLocalDate(employee.startDateLastClosedPeriod, true, 'YYYY-MM-DD', 'DD/MM/YY')} - ${formatLocalDate(employee.endDateLastClosedPeriod, true, 'YYYY-MM-DD', 'DD/MM/YY')}` :
                    t('CLOSED_PERIODS.TEXT.NO_CLOSED_PERIOD');

                listErrors.push(t('CLOSED_PERIODS.TEXT.ERROR_EMPLOYEE_WITH_GAP', undefined, `${employee.name} - (${lastClosedPeriod})`));
            }
        }
    });

    return listErrors;
};