import React, { useEffect, useState } from 'react';
import ApplicationRouter from '../../../router';

import '../../../configs/awesomeFontLibrary';

import { Loading, Sidebar } from '@optimuminterfaces/revex-react-components/dist/components/index';

import './App.scss';
import styles from './App.module.scss';
import { fetchEnvironment, setEnvironment } from '../../../services/auth.services';
export interface AppProps {
    history?: any;
	showMenu?: boolean,
	Container?: any
}

const renderSidebar = () => {
	
	return (
		<Sidebar>
			<Sidebar.Menu name="Dashboard" icon="tachometer-alt" url="#/srepsMF"/>
			<Sidebar.Menu name="Ponto" icon="clock">
				<Sidebar.SubMenu 
					icon="clock" 
					url="#/srepsMF/pointRegistrations" 
					title="Registro de Marcações" 
					subtitle="Acompanhar pontos e calculos das horas dos colaboradores" />
				<Sidebar.SubMenu 
					icon="user-clock" 
					url="#/srepsMF/hourBankBalances" 
					title="Banco de Horas" 
					subtitle="Acompanhar situação do banco de horas dos colaboradores" />
				<Sidebar.SubMenu 
					icon="alarm-exclamation" 
					url="#/srepsMF/incidents" 
					title="Incidentes" 
					subtitle="Quer identificar os incidentes dos colaboradores? Utilize essa funcionalidade." />
				<Sidebar.SubMenu 
					icon="alarm-exclamation" 
					url="#/srepsMF/incidents/my" 
					title="Meus Incidentes" 
					subtitle="Quer visualizar seus incidentes? Utilize essa funcionalidade." />
				<Sidebar.SubMenu 
					icon="user-lock" 
					url="#/srepsMF/closedPeriods" 
					title="Fechamento Período" 
					subtitle="Fechamento do espelho de ponto para edição" />
				<Sidebar.SubMenu 
					icon="cloud-upload" 
					url="#/srepsMF/import" 
					title="Importação" 
					subtitle="Realize a importação de seus arquivos ADF e AFDT" />
				<Sidebar.SubMenu 
					icon="cloud-download" 
					url="#/srepsMF/export" 
					title="Exportação" 
					subtitle="Realize a exportação de seus arquivos ADF e AFDT" />
				<Sidebar.SubMenu 
					icon="map-marked-alt" 
					url="#/srepsMF/limitAreas" 
					title="Área Limite de Ponto" 
					subtitle="Configuração de limite geolocalizado (gps) para batimento de ponto" />
                <Sidebar.SubMenu 
					icon='file-exclamation'
					url="#/srepsMF/pointEvents" 
					title="Eventos SREP" 
					subtitle="Configuração dos eventos do ponto" />
				<Sidebar.SubMenu 
					icon="cogs" 
					url="#/srepsMF/settings" 
					title="Configurações" 
					subtitle="Customização do comportamente do sistema de ponto" />										
			</Sidebar.Menu>		

			<Sidebar.Menu name="Relatórios" icon="file-pdf" url="#/srepsMF/reports"/>
		</Sidebar>
	)
}

function App({history, showMenu, Container='div'}: AppProps) {
	const [environmentLoaded, setEnvironmentLoaded] = useState(false);

	useEffect(() => {
		(async () => {
			try{
				setEnvironmentLoaded(false);
				const jsonReturned =  await fetchEnvironment();
				if(jsonReturned){
					setEnvironment(jsonReturned);
					setEnvironmentLoaded(true);
				}else{
					setEnvironmentLoaded(true);
				}
			}catch(error){
				console.log(error);
			}

		})();
	},[]);
	
    return (
		<>
			<Container className={`${styles['app']} ${showMenu?styles['sidebar']:''} cable-app`}>
				{showMenu &&
					<Container className={styles['container-sidebar']}>
						{renderSidebar()}
					</Container>
				}
				{environmentLoaded === true &&
					<Container className={styles['container-body']}>
						<ApplicationRouter history={history}/>
					</Container>
				}
				{environmentLoaded === false &&
					<Loading text='Carregando configurações do módulo'/>
				}
			</Container>
		</>
    );
}

export default App;