import API, { formatDataToFormData } from './core';

export const fetchAllClosedPeriods = async (params?: any) => {
	try{
		return API.get('/rest/points/closedPeriods', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchClosedPeriod = async (id: string,params?: any) => {
	try{
		return API.get(`/rest/points/closedPeriods/${id}`, {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const deleteClosedPeriod = async (id: string) => {
	try{
		return API.delete(`/rest/points/closedPeriods/${id}`, {})
		.then(
			response => {
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const postClosedPeriod = async (params?: any) => {
	try{
		return API.post('/rest/points/closedPeriods', JSON.stringify(params), {headers: {'Content-Type': 'application/json'}})
		.then(
			response => {
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const putClosedPeriod = async (id:string, params?: any) => {
	try{
		return API.put(`/rest/points/closedPeriods/${id}`, formatDataToFormData(params), {headers: {'Content-Type': 'application/json'}})
		.then(
			response => {
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchAllEmployeeAvailable = async (params?: any) => {
	try{
		return API.get('/rest/points/closedPeriods/employeeAvailable', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchEndDateOfLastClosedPeriod = async (params?: any) => {
	try {
		return API.get('/rest/points/closedPeriods/getEndDateOfLastClosedPeriod', { params })
			.then(
				response => {
					return response.data;

				}, error => {
					console.log(error);
					return null;
				}
			);

	} catch (error) {
		console.log(error);
		return null;
	}
};

