import React, { useState } from 'react';

import { Button, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index';
import { convertMinutesToTimeStr, convertTimeStrToMinutes } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { uuidv4 } from '@optimuminterfaces/revex-react-components/dist/utils/DeveloperUtil';

import { deletePointRegistrationEvent, postPointRegistrationEvent } from '../../../../services/pointRegistrationEvent.services';
import { PointRegistrationEvent } from '../../../../models/PointRegistrationEvent';

import styles from './Delete.module.scss';

interface DeleteProps{
	pointRegistrationEvent: PointRegistrationEvent,
	employeeId: string,
	employeeName: string,
	employeeOccupation: string,
	handleChanges: Function,
	className?: string,
	Container?:any
}
const Delete = ({employeeName, employeeOccupation, employeeId, pointRegistrationEvent, handleChanges, className, Container='div'}: DeleteProps) => {
	const [loading, setLoading] = useState<{pointEventFetch: boolean, pointRegistrationEventDelete: boolean}>
			({ pointEventFetch: true, pointRegistrationEventDelete: false });
	const [toastData, setToastData] = useState<any>([]);

	const internalAddToast = (toastDataParam:any, handleCloseCallback?:Function) => {
		toastDataParam.key = `toast-${uuidv4()}`;
		toastDataParam.closeChange = () => {
			setToastData((toastData: any[]) => toastData.filter(({ key }) => (key !== toastDataParam.key)));
			handleCloseCallback?.();
		};

		setToastData((toastData: any) => [ ...toastData, toastDataParam ]);
	};

	const internalHandleChanges = ({name, value }: {name: string, value?: any}) => {
		let pe = {...pointRegistrationEvent}

		switch(name){

			case 'PointRegistrationEventDelete.cancel':
				{
					handleChanges?.({ name, value });
				}
				break;
			case 'PointRegistrationEventDelete.delete':
				{

					setLoading({ ...loading, pointEventFetch: false, pointRegistrationEventDelete: true });

					const callbackSuccess = (response: any) => {
						if(response !== null && response.status === 'SUCCESS'){
							internalAddToast({
								show: true,
								title: t('COMPONENTS.TOAST.TITLE.DATA_CHANGES_SAVE_SUCCESS'),
								icon: 'save',
								message: t('CABLE.TOAST.SUCCESS_POINT_EVENT_CHANGE', undefined, response.message),
								success: true,
								positionTopRight: true,
								showTime: 5000
							}, () => handleChanges?.({ name: 'PointRegistrationEventDelete.delete'}));
						}else{

							internalAddToast({
								show: true,
								title: t('COMPONENTS.TOAST.TITLE.SERVER_INTERNAL_ERROR'),
								icon: 'exclamation-circle',
								message: response.message? response.message: t('CABLE.TOAST.ERROR_POINT_EVENT_UPDATE'),
								danger: true,
								positionTopRight: true,
								showTime: 5000
							});

							setLoading({...loading, pointEventFetch: false, pointRegistrationEventDelete: false });
						}
					};

					const callbackErrors = (errors: any) => {
						console.log(errors);
						internalAddToast({
							show: true,
							title: t('COMPONENTS.TOAST.TITLE.SERVER_INTERNAL_ERROR'),
							icon: 'exclamation-circle',
							message: t('CABLE.TOAST.ERROR_POINT_EVENT_UPDATE'),
							warning: true,
							positionTopRight: true,
							showTime: 5000
						});
						setLoading({ ...loading, pointEventFetch: false, pointRegistrationEventDelete: false });
					};

					deletePointRegistrationEvent(pointRegistrationEvent.id).then(callbackSuccess, callbackErrors);
				   
				}
				break;

		}
	};

	return (
		<Container className={styles['point-registration-event-delete']+ ' '+ !!className}>
			<TextInformation text={'Tem certeza que deseja deletar o evento do colaborador '
												+ employeeName +' do tipo '+
												pointRegistrationEvent.pointRegistrationEventType.name+
												' com valor de '+
												convertMinutesToTimeStr(pointRegistrationEvent.pointRegistrationEventValue) +'' 
												+'?'} 
										icon="question-circle" />
						<Container style={{ textAlign: 'center' }}>
								<Button danger md
									title={t('GENERIC.TEXT.CANCEL', undefined)}
									icon="times"
									loading={loading.pointRegistrationEventDelete}
									action={() => { internalHandleChanges({name:'PointRegistrationEventDelete.cancel'}) }} />
								<Button primary md
									title={t('GENERIC.BUTTON.DELETE.TEXT', undefined)}
									icon="save"
									loading={loading.pointRegistrationEventDelete}
									action={() => { internalHandleChanges({name:'PointRegistrationEventDelete.delete'}) }} />
							</Container>
			{toastData?.map?.((toastDataE:any) => (
				<Toast {...toastDataE} />
			))}
		</Container>
	);
}

export default Delete;