export const translateStatus = (status:string) => {
	switch (status) {
		case 'OPENED':
			return 'Aguardando Justificativa';
		case 'WAITING_FOR_REVIEW':
			return 'Aguardando revisão';
		case 'WAITING':
			return 'Aguardando Aprovação';
		case 'SOLVED':
			return 'Justificativa Aprovada';
		case 'SOLVED_AUTO':
			return 'Justificativa Resolvida Automaticamente';
		case 'DISAPPROVED':
			return 'Justificativa Reprovada';
		default:
			return '-';
	}
}

export const translateType = (type: string, actualPointsNumber?: number, expectedPointsNumber?: number) => {
	switch (type) {
		case 'NONE':
			return 'Colaborador sem ponto registrado';
		case 'WORK_DAY_OFF':
			return 'Colaborador trabalhando em dia de folga';
		case 'DIVERGENCE':
			return ('Colaborador registrou ' + actualPointsNumber +
					' ponto(s), eram esperados ' + expectedPointsNumber + ' pontos');
		case 'AREA':
			return 'Colaborador registrou ponto fora da área limite';
		default:
			return 'Inconsistência no registro de pontos';
	}
}
