import { Button, DualList, GeoLocation, Input, Loading, MapView, PageTitle, Panel, Row, Select, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { types } from 'util';
import { Employee } from '../../../../models/Employee';
import { PointLimitArea } from '../../../../models/PointLimitArea';
import { fetchAllEmployeeAvailable, postPointLimitArea } from '../../../../services/pointLimitArea.services';

import styles from './New.module.scss';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

interface NewProps{
	Container?: any;
}

const New = ({Container='div'}: NewProps) => {
	
	const pointLimitAreaTypes = [
		{key:'limit', value:t('CABLE.AREA.NEW.LIMIT_AREA',undefined), selected: false},
		{key:'noRestriction', value:t('CABLE.AREA.NEW.UNRESTRICTED_AREA',undefined), selected: false},
	];

	let navigate = useNavigate();
	const [limitAreaStatus, setLimitAreaStatus] = useState<string>('waiting')
	const [lat, setLat] = useState<number>();
	const [lng, setLng] = useState<number>();
	const [locationStatus, setLocationStatus] = useState<string>();
	const [radius, setRadius] = useState<number>(100);
	const [listTypes, setListTypes] = useState(pointLimitAreaTypes);
	const [typeValue, setTypeValue] = useState<any>(pointLimitAreaTypes[0]);
	const [name, setName] = useState<string>();
	const [dualListOptions, setDualListOptions] = useState<any[]>();
	const [dualListOptionsSelected, setDualListOptionsSelected] = useState([]);
	const [loadingButtonSave, setLoadingButtonSave] = useState(false);
	const [toastAlert, setToastAlert] = useState<{show:boolean, message: string, danger: boolean}>({show: false, message: '', danger: false})

	useEffect(() => {
		const buildDualListOptions = (employees: Employee[]) => {
			let dualList: { key: string; selected: boolean; values: any[]; }[] = [];
			employees.forEach(employee => {
				dualList.push({key: employee.id, selected: false, values: [employee.name,employee.occupation?.name]})
			});
			
			setDualListOptions(dualList);
		}
		(async () => {
			setLimitAreaStatus('waiting');
			try{
				const jsonReturned =  await fetchAllEmployeeAvailable();
				if(jsonReturned){
					buildDualListOptions(jsonReturned.employees);
					setLimitAreaStatus('success');
				}else{
					setLimitAreaStatus('error');
				}
			}catch(error){
				console.log(error);
			}

		})();
    }, []);

	// filtra todos colaboradores selecionados
	const handleChangeDualList = (returnOptions: any) => {
        let listReturn = returnOptions.filter((e:any) => e.selected === true);
        let listContains = listReturn.map((e:any) => e.key);

        setDualListOptionsSelected(listContains);
    };

	const saveLimitArea = async () => {
		if(!name || name ===''){
			setToastAlert({...toastAlert, danger: true, message:t('CABLE.AREA.NEW.INFORM_NAME',undefined),show: true});
			return;
		}
		if(!typeValue || typeValue ===''){
			setToastAlert({...toastAlert, danger: true, message:t('CABLE.AREA.NEW.INFORM_TYPE',undefined),show: true});
			return;
		}
		if(!dualListOptionsSelected || dualListOptionsSelected.length ===0){
			setToastAlert({...toastAlert, danger: true, message:t('CABLE.AREA.NEW.SELECT_EMPLOYEE',undefined),show: true});
			return;
		}
		setLoadingButtonSave(true);
		try{
			const jsonReturned =  await postPointLimitArea({name: name, 
															radius: radius,
															lat: lat,
															lng: lng,
															limitAreaType: typeValue.key,
															employees: dualListOptionsSelected.join(',')});
			if(jsonReturned){
				if(jsonReturned.status==='ERROR'){
					setToastAlert({...toastAlert, danger: true, message:jsonReturned.message,show: true});
					setLoadingButtonSave(false);
				}else{
					setToastAlert({...toastAlert, danger: false, message:jsonReturned.message,show: true});
					setTimeout(() => { 
						setLoadingButtonSave(false); 
						navigate('/srepsMF/limitAreas') 
					}, 3000);
				}
			}else{
				//setLimitAreaStatus('error');
			}
		}catch(error){
			console.log(error);
		}
	}

	const changeSelectType = (key: string) => {
        let types = [...listTypes];

        for (var i = 0; i < types.length; i++) {
            if (types[i].key === key) {
                types[i].selected = true;
                setTypeValue(types[i]);
            } else {
                types[i].selected = false;
            }
        }

        setListTypes(types);

    }

	return(
		<Container className={styles['point-limit-area-new-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CABLE.AREA.NEW.TITLE',undefined)} />
				</Row.Item>
			</Row>
			{(limitAreaStatus === 'waiting') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING', undefined)}/>
						</Panel>
					</Row.Item>
				</Row>
			}

			{!lat && !lng && limitAreaStatus === 'success' && (
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Panel>
							<GeoLocation handleGeoLocation={(event: any) => {
								if(event.name==='retrive_location'){
									setLat(event.lat); 
									setLng(event.lng);
									setLocationStatus('success');
								}
								if(event.name==='location'){
									setLocationStatus('location');
								}
								}} />
						</Panel>
					</Row.Item>
				</Row>
			)}

			{lat && lng && locationStatus === 'success' && limitAreaStatus === 'success' && (
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel>
						<Row>
							<Row.Item sm={12} md={12} lg={12}>
								<Input type="text" fullWidth
									title={t('CABLE.AREA.NEW.NAME',undefined)}
									name="closedPeriodName"
									value={name}
									//status={inputStatus}
									handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {setName(event.target.value)}} /> 
							</Row.Item>
							<Row.Item sm={10} md={10} lg={10}>
								<Select medium 
										fullWidth 
										className={styles['selectType']}
										name="selectType" 
										value={typeValue.value} 
										title={t('CABLE.AREA.NEW.PROCEED',undefined)}
										options={pointLimitAreaTypes} 
										handleChange={(key: string) => {changeSelectType(key)}} />
							</Row.Item>
							<Row.Item sm={2} md={2} lg={2}>
								<Input type="number" fullWidth
									title= {t('CABLE.AREA.NEW.RADIUS',undefined)+' (M)'} 
									name="closedPeriodName"
									value={radius}
									//status={inputStatus}
									handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {setRadius(parseInt(event.target.value))}} /> 
							</Row.Item>
							<Row.Item sm={12} md={12} lg={12}>
								{typeValue.key==='limit' && <MapView lng={lng} 
									lat={lat} 
									radius={radius} 
									height='250px' 
									enableSearch
									id='point-limit-area-new' 
									handleClick = {(event) => {setLat(event.lat); setLng(event.lng);}}
									enableZoom/> 
								}
							</Row.Item>
							<Row.Item sm={12} md={12} lg={12}>
								<h3>{t('CABLE.AREA.NEW.SELECTION',undefined)}</h3>
								<DualList 
									titleAvailable={t('CABLE.AREA.NEW.AVAILABLE_EMPLOYEES',undefined)}
									titleSelected={t('CABLE.AREA.NEW.SELECTED_EMPLOYEES',undefined)}
									options={dualListOptions!}
									firstIcon="user"
									secondIcon="tools"
									handleChange={handleChangeDualList} />
							</Row.Item>
							<Row.Item sm={12} md={12} lg={12}>
								<Container className={styles['actions']}>
									<Button danger md
										title={t('GENERIC.TEXT.CANCEL',undefined)}
										icon="times"
										className={styles['button-action']}
										action={() => { navigate('/srepsMF/limitAreas') }} />
									<Button primary md
										title={t('GENERIC.BUTTON.SAVE.TEXT',undefined)}
										icon="save"
										//disabled={inputStatus !== "success" || optionsSelected.length === 0}
										loading={loadingButtonSave}
										className={styles['button-action']}
										action={() => { saveLimitArea() }} />
								</Container>
							</Row.Item>
						</Row>
						
					</Panel>
				</Row.Item>
			</Row>
			)}

			{toastAlert.show &&
				<Toast 
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION',undefined)}
					icon={toastAlert.danger?'exclamation-circle':'check'}
					positionTopRight
					closeChange={() => setToastAlert({...toastAlert, show: false})}
					message={toastAlert.message} 
					showTime={5000}/>
			}
		</Container>
	);
}

export default New;