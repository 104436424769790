import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { PointEvent as PointEventModel } from '../../../models/PointEvent';
import { isObjectEmpty } from '@optimuminterfaces/revex-react-components/dist/utils/DeveloperUtil';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

import styles from './PointEvent.module.scss';
import { Button, Input, Row, Select, TextInformation } from '@optimuminterfaces/revex-react-components/dist/components';
import { colors } from '@optimuminterfaces/revex-react-components/dist/utils/ColorUtil';
interface PointEventProps{
    pointEventRaw?: PointEventModel,
    pointEventTypesRaw: any,
    buttonSaveText?: string,
    buttonCancelText?: string,
    loading: boolean,
    handleChanges: Function,
    errors: any,
    className?: string,
    Container?: any,
}
const PointEvent = ({pointEventRaw,
                    pointEventTypesRaw, 
                    buttonSaveText, 
                    buttonCancelText,
                    loading, 
                    handleChanges,  
                    errors, 
                    className,
                    Container='div'}: PointEventProps) => {
    const [pointEvent, setPointEvent] = useState<PointEventModel>(pointEventRaw || {name: '', description: '', systemDefault: false, pointEventType: '', code: '', isActive: true });

    const [pointEventDataValidation, setPointEventDataValidation] = useState(null);
    const [pointEventTypes, setPointEventTypes] = useState(pointEventTypesRaw);

    const [pointEventTypesOptions, setPointEventTypesOptions] = useState<[{key: string, value: string, selected: boolean}]>();
    const [typeValue, setTypeValue] = useState<any>();

    const setSelectOption = (key: string, options?: [{key: string, value: string, selected: boolean}] ) => {
        let types = options || pointEventTypesOptions!;

        types.map(t =>{
            if(t.key===key){
                t.selected=true;
                setTypeValue(t);
            }else{
                t.selected = false;
            }
        });

        setPointEventTypesOptions(types);
    }

    const buildSelectOptions = () => {

        let options: any = [];
        options = pointEventTypesRaw.filter((s:any) => !s.system).map((s: any) => ({
            key: s.code, 
            value:s.name, 
            selected: pointEvent? pointEvent.pointEventType === s.code: false
        }));

        setPointEventTypesOptions(options);
        setSelectOption(pointEvent.pointEventType, options);
            
    }

    const internalHandleChanges = ({name, value }: {name: string, value?: any}) => {
        let pe = {...pointEvent}
        console.log(name, value)
        switch(name){
            case 'name':
            case 'description':
            case 'code':
                pe = {...pe, [name]: value};
                break;

            case 'pointEventType':
                pe= {...pe, pointEventType : value};
                setSelectOption(value);
                break;

            case 'PointEvent.cancel':
                {
                    handleChanges?.({ name, value });
                }
                break;
            case 'PointEvent.save':
                {

                    // clean or trim name value
                    if(!value?.name){
                        value.name = null;
                    }else{
                        value.name = value.name.trim();
                    }

                    if(value.close===false){
                        value.legend = true;
                    }


                    handleChanges?.({ name, value });
                }
                break;    

        }
        if(pointEventDataValidation !== null){
            dataValidation({...pe});
        }
        setPointEvent({...pe});
    };

    const dataValidation = (pe?: PointEventModel) => {
        let pEvent = pe || {...pointEvent}
        let validationErrors: any = {};
        if( !pEvent.name || pEvent.name === ''){
            validationErrors = {...validationErrors, 'name': 'deve ser informado'}
        }

        if( !pEvent.description || pEvent.description === ''){
            validationErrors = {...validationErrors, 'description': 'deve ser informado'}
        }

        if( !pEvent.code || pEvent.code === '' ){
            validationErrors = {...validationErrors, 'code': 'deve ser informado'}
        }

		if(pEvent.code.length > 4){
			validationErrors = {...validationErrors, 'code': 'deve ser menos que 4 caracters'}
		}

        if( !pEvent.pointEventType || pEvent.pointEventType === ''){
            validationErrors = {...validationErrors, 'pointEventType': 'deve ser informado'}
        }
        if(isObjectEmpty(validationErrors)){
            setPointEventDataValidation(null);
            return true;
        }else{
            setPointEventDataValidation({...validationErrors});
            return false;
        }
    }

    useEffect( () => {
        if(pointEventTypesRaw){
            buildSelectOptions();
        }
    }, [pointEventTypesRaw]);


    const buildMessageError = () => {
        let message = '';
        if(pointEventDataValidation !== null){
            Object.keys(pointEventDataValidation)
                .forEach(
                    i => message += '<br />'+t('GENERIC.FIELD_VALIDATION.REQUIRED_FIELD', undefined, 
                        i.toLocaleUpperCase()==='POINTEVENTTYPE'? t('CABLE.POINT_EVENT.NEW.POINT_EVENT_TYPE') : t('GENERIC.TEXT.'+i.toLocaleUpperCase())))
        }

        if(errors){
            errors.forEach((e:any) => {
                message += '<br/>'+e['defaultMessage'];
            })
        }
        return message;
    }
    return <>
        <Container className={` ${styles['point-event-rt']} ${className}`.trim()}>
            {(pointEventDataValidation !== null || errors) && 
                <Container className={ `${styles['point-event-feedback']}`}>
                    <TextInformation 
                        iconColor={colors['red-color-light']} 
                        icon='exclamation-triangle' 
                        text='Validação'
                        subText = { buildMessageError()} />
                </Container>
            }
            {pointEventTypesOptions &&
                <Container className={ `${styles['point-event-data']}`}>
                    <Row>
                        <Row.Item sm={12} md={8} lg={8}>

                            <Input name='name'
                                id='point-event-name'
                                fullWidth
                                title={t('GENERIC.TEXT.NAME')}
                                value={pointEvent?.name}
                                hasFeedback
                                status={(pointEventDataValidation?.['name'])?'error':''} 
                                dataTooltip={pointEventDataValidation?.['name']} 
                                handleChange={(e) => {internalHandleChanges({name: e.target.name, value:e.target.value})}} />

                        </Row.Item>
                        <Row.Item sm={12} md={4} lg={4}>

                            <Input name='code' 
                                id='point-event-code'
                                fullWidth
                                title={t('GENERIC.TEXT.CODE') + ' (Max 4 caracters)'}
                                value={pointEvent?.code}
                                hasFeedback
								placeholder={'Máximo de 4 caracteres'}
								maxLength={4}
                                status={(pointEventDataValidation?.['code'])?'error':''} 
                                dataTooltip={pointEventDataValidation?.['code']} 
                                handleChange={(e) => {internalHandleChanges({name: e.target.name, value:e.target.value})}} />

                        </Row.Item>
                        <Row.Item sm={12} md={12} lg={12}>

                            <Input name='description'
                                id='point-event-description'
                                fullWidth
                                title={t('GENERIC.TEXT.DESCRIPTION')}
                                value={pointEvent?.description}
                                hasFeedback
                                status={(pointEventDataValidation?.['description'])?'error':''} 
                                dataTooltip={pointEventDataValidation?.['description']} 
                                handleChange={(e) => {internalHandleChanges({name: e.target.name, value:e.target.value})}} />

                        </Row.Item>

                        <Row.Item sm={12} md={12} lg={12}>
                            {!pointEvent.systemDefault && 
                            <Select medium
                                value={typeValue?.value}
                                name='pointEventType'
                                title={t('CABLE.POINT_EVENT.NEW.POINT_EVENT_TYPE')}
                                placeholder={t('CABLE.POINT_EVENT.NEW.POINT_EVENT_TYPE')} 
                                options={pointEventTypesOptions!} 
                                handleChange={(event) => internalHandleChanges({name: 'pointEventType', value: event})} fullWidth/>
                            }

                        </Row.Item>
                    </Row>
                </Container>
            }

            <Container className={ `${styles['point-event-footer']}`}>
                <Button title={buttonCancelText || t('GENERIC.BUTTON.CANCEL.TEXT')}
                    outlined 
                    loading={loading} 
                    action={(e) => { 
                        e?.preventDefault?.();
                        internalHandleChanges({ name: 'PointEvent.cancel'});
                    }} />

                <Button title={buttonSaveText || t('GENERIC.BUTTON.SAVE.TEXT')} 
                    primary 
                    icon='save' 
                    loading={loading} 
                    style={{ marginLeft: '5px' }} 
                    disabled={pointEventDataValidation !== null && !! errors}
                    action={(e) => {
                        
                        e?.preventDefault?.();
                        if(dataValidation()){
                            internalHandleChanges({ name: 'PointEvent.save', value: { ...pointEvent }});    
                        }
                        
                    }} />
            </Container>
            
        </Container>
    </>
}

PointEvent.propTypes = {
    className: PropTypes.string,
    pointEvent: PropTypes.object,
    errors: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired
    })),
}
export default PointEvent;
