import React, { useEffect, useState } from 'react';
import { ClosedPeriod } from '../../../../models/ClosedPeriod';

import { Button, Input, Loading, Modal, PageTitle, Panel, Row, Select, Table, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';

import styles from './List.module.scss';
import { deleteClosedPeriod, fetchAllClosedPeriods } from '../../../../services/closedPeriod.services';
import { calculateSelectMonth, calculateSelectYear } from '../../../../utils/SelectUtil';
import { useNavigate } from 'react-router-dom';
import { copyObjectJSON } from '@optimuminterfaces/revex-react-components/dist/utils/DeveloperUtil';
import { formatLocalDate } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';

import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation/index';

interface ListProps {
	Container?: any
}
const List = ({ Container = 'div' }: ListProps) => {

	let navigate = useNavigate();

	// Pega o período anterior ao atual
	const currentDate = new Date();
	currentDate.setMonth(currentDate.getMonth() - 1);

	const years = calculateSelectYear();
	const months = calculateSelectMonth();

	let currentMonth = months.filter(m => m.selected == true)[0];

	const [closedPeriods, setClosedPeriods] = useState<ClosedPeriod[]>();
	const [closedPeriodStatus, setClosedPeriodStatus] = useState<string>();
	const [yearValue, setYearValue] = useState<string>(currentDate.getFullYear().toString());
	const [monthValue, setMonthValue] = useState<any>(currentMonth);
	const [listYears, setListYears] = useState(years);
	const [listMonths, setListMonths] = useState(months);
	const [inputValue, setInputValue] = useState<string>();
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [deleteId, setDeleteId] = useState<string>();
	const [filters, setFilters] = useState<{ year: string, month: string, name?: string }>({ year: yearValue, month: monthValue.key });
	const [loadingButtonDelete, setLoadingButtonDelete] = useState(false);
	const [toastAlert, setToastAlert] = useState<{ show: boolean, message: string, danger: boolean }>({ show: false, message: '', danger: false })

	useEffect(() => {
		(async () => {
			setClosedPeriodStatus('waiting');
			try {
				const jsonReturned = await fetchAllClosedPeriods({ 'year': filters?.year, 'month': filters?.month, 'name': filters?.name });
				if (jsonReturned) {
					setClosedPeriods(jsonReturned.closedPeriods);
					setClosedPeriodStatus('success');
				} else {
					setClosedPeriodStatus('error');
				}
			} catch (error) {
				console.log(error);
			}

		})();
	}, [filters]);


	const inputHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.value.length >= 1) {
			setInputValue(event.target.value);
		}
	};

	const inputHandleBlur = () => {
		setFilters({ ...filters, name: inputValue });
		//findClosedPeriods(parseInt(yearValue), monthKey, inputValue);
	};

	const newClosedPeriod = () => {
		navigate('/srepsMF/closedPeriods/new');
	};

	const changeSelectMonth = (key: string) => {
		let months = [...listMonths];

		for (var i = 0; i < months.length; i++) {
			if (months[i].key === key) {
				months[i].selected = true;
				setMonthValue(months[i]);
				setFilters({ ...filters, month: months[i].key })
			} else {
				months[i].selected = false;
			}
		}

		setListMonths(months);

	}

	const changeSelectYear = (key: string) => {
		let years = [...listYears];

		for (var i = 0; i < years.length; i++) {
			if (years[i].key === key) {
				years[i].selected = true;
				setYearValue(years[i].key);
				setFilters({ ...filters, year: years[i].key })
			} else {
				years[i].selected = false;
			}
		}

		setListYears(years);

	}

	const renderClosedPeriod = () => {
		return closedPeriods?.map(closedPeriod => {
			return (
				<Table.BodyItem className={styles['table-item']} key={`closed-period-${closedPeriod.id}`}>
					<Table.BodyItem.Item>{closedPeriod.name}</Table.BodyItem.Item>
					<Table.BodyItem.Item>{closedPeriod.employeesId.split(',').length}</Table.BodyItem.Item>
					<Table.BodyItem.Item>{formatLocalDate(closedPeriod.created, true, 'yyyy-MM-DDTHH:mm:ss.sss', 'DD/MM/yyyy HH:mm:ss')}</Table.BodyItem.Item>
					<Table.BodyItem.Item>{formatLocalDate(closedPeriod.modified, true, 'yyyy-MM-DDTHH:mm:ss.sss', 'DD/MM/yyyy HH:mm:ss')}</Table.BodyItem.Item>
					<Table.BodyItem.Item>
						<Button primary md
							title={t('GENERIC.TEXT.VIEW', undefined)}
							icon="eye"
							className={styles['table-item-action']}
							action={() => { navigate(`/srepsMF/closedPeriods/${closedPeriod.id}/edit`) }} />
						<Button danger md
							title={t('GENERIC.BUTTON.DELETE.TEXT', undefined)}
							icon="trash"
							className={styles['table-item-action']}
							action={() => {
								setDeleteId(closedPeriod.id);
								setShowModalDelete(true);
							}} />
					</Table.BodyItem.Item>
				</Table.BodyItem>
			);
		})
	};

	const deleteById = async (id: string) => {
		setLoadingButtonDelete(true);

		try {
			const jsonReturned = await deleteClosedPeriod(id);

			if (jsonReturned) {
				if (jsonReturned.status === 'ERROR') {
					setToastAlert({ show: true, message: jsonReturned.message, danger: true });
					setLoadingButtonDelete(false);

				} else {
					removeListById(id);
					setToastAlert({ ...toastAlert, message: t('CLOSED_PERIODS.TEXT.PERIOD_SUCESSFULLY_DELETED', undefined), show: true });
				}

			} else {
				setClosedPeriodStatus('error');
			}

			setShowModalDelete(false);
		} catch (error) {
			console.log(error);
		}
	};

	const closeModalDelete = () => {
		setShowModalDelete(false);
	};

	const removeListById = (id: string) => {
		let newList = [];

		for (let i = 0; i < closedPeriods!.length; i++) {
			if (closedPeriods![i].id !== id) {
				newList.push(closedPeriods![i]);
			}
		}

		setClosedPeriods(copyObjectJSON(newList));
		setShowModalDelete(false);
		setLoadingButtonDelete(false);
	};

	return (
		<Container className={styles['closed-period-list-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CLOSED_PERIODS.TEXT.CLOSED_PERIODS', undefined)} />
				</Row.Item>
			</Row>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<Panel title={t('GENERIC.TEXT.FILTERS', undefined)} noTitleBorder>
						<Row>
							<Row.Item lg={12} md={12} sm={12}>
								<Container className={styles['group-buttons']}>
									<Button primary md
										title={t('CLOSED_PERIODS.TEXT.NEW_CLOSURE', undefined)}
										icon="plus"
										action={() => { newClosedPeriod() }} />
								</Container>
							</Row.Item>
							<Row.Item lg={6} md={6} sm={12}>
								<Input type="text" fullWidth
									title={t('CLOSED_PERIODS.TEXT.NAME_OF_CLOSED_PERIOD', undefined)}
									name="closedPeriodName"
									handleChange={inputHandleChange}
									handleBlur={inputHandleBlur} />
							</Row.Item>
							<Row.Item lg={3} md={3} sm={6}>
								<Select medium fullWidth name="selectMonth" value={monthValue.value} title={t('GENERIC.TEXT.PERIOD', undefined)} options={listMonths} handleChange={changeSelectMonth} />
							</Row.Item>
							<Row.Item lg={3} md={3} sm={6}>
								<Select medium fullWidth name="selectYear" value={yearValue.toString()} title={t('GENERIC.TEXT.YEAR', undefined)} options={listYears} handleChange={changeSelectYear} />
							</Row.Item>
						</Row>
					</Panel>
				</Row.Item>
			</Row>

			{(!closedPeriods && closedPeriodStatus === 'waiting') &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING', undefined)} />
						</Panel>
					</Row.Item>
				</Row>
			}
			{(!closedPeriods && closedPeriodStatus === 'error') &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation
								icon="exclamation-circle"
								text={t('GENERIC.TEXT.UNABLE_RETURN', undefined)} />
						</Panel>
					</Row.Item>
				</Row>
			}

			{closedPeriods && closedPeriodStatus === 'success' && closedPeriods.length === 0 &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel noPadding>
							<TextInformation
								icon="search"
								text={t('CLOSED_PERIODS.TEXT.NO_CLOSED_PERIODS', undefined)} />
						</Panel>
					</Row.Item>
				</Row>
			}

			{closedPeriods && closedPeriodStatus === 'success' && closedPeriods.length > 0 &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel title={`${monthValue.value}/${yearValue}`} noPadding noTitleBorder>
							<Table striped primary>
								<Table.Header>
									<Table.HeaderItem>
										<Table.HeaderItem.Item>{t('CLOSED_PERIODS.TEXT.NAME_OF_CLOSED_PERIOD', undefined)}</Table.HeaderItem.Item>
										<Table.HeaderItem.Item>{t('CLOSED_PERIODS.TEXT.NUMBER_OF_EMPLOYEES', undefined)}</Table.HeaderItem.Item>
										<Table.HeaderItem.Item>{t('GENERIC.TEXT.CREATION', undefined)}</Table.HeaderItem.Item>
										<Table.HeaderItem.Item>{t('GENERIC.TEXT.LAST_EDITION', undefined)}</Table.HeaderItem.Item>
										<Table.HeaderItem.Item>{t('GENERIC.TEXT.ACTIONS', undefined)}</Table.HeaderItem.Item>
									</Table.HeaderItem>
								</Table.Header>
								<Table.Body>
									{renderClosedPeriod()}
								</Table.Body>
							</Table>
						</Panel>
					</Row.Item>
				</Row>
			}

			{showModalDelete &&
				<Modal title={t('GENERIC.TEXT.CONFIRMATION', undefined)} showClose xs handleClose={() => { closeModalDelete() }}>
					<Container className={styles['modal-delete']}>
						<p>{t('CLOSED_PERIODS.TEXT.DELETE')}</p>
						<Button primary md
							title={t('GENERIC.TEXT.KEEP', undefined)}
							icon="undo"
							className={styles['modal-delete-action']}
							action={() => { closeModalDelete() }} />
						<Button danger md
							title={t('GENERIC.BUTTON.DELETE.TEXT', undefined)}
							icon="trash"
							className={styles['modal-delete-action']}
							loading={loadingButtonDelete}
							action={() => { deleteById(deleteId!) }} />
					</Container>
				</Modal>
			}

			{toastAlert.show &&
				<Toast
					success={!toastAlert.danger}
					danger={toastAlert.danger}
					title={t('GENERIC.TEXT.OPERATION', undefined)}
					icon={toastAlert.danger ? 'exclamation-circle' : 'check'}
					positionTopRight
					closeChange={() => setToastAlert({ ...toastAlert, show: false })}
					message={toastAlert.message}
					showTime={5000} />
			}
		</Container>
	)
}

export default List;