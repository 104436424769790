import React, { useEffect, useState } from 'react';

import { Animate, Avatar, Button, Clock, GeoLocation, Loading, MapView, Modal, PageTitle, Panel, Row, TextInformation } from '@optimuminterfaces/revex-react-components/dist/components';

import styles from  './New.module.scss';
import PointRegistrationDetail from '../../../../organism/PointRegistration/Detail/Detail';
import { fetchMyPointRegistrationsTodayInformation, postMyPointRegistration } from '../../../../../services/pointRegistrations.services';
import { Employee } from '../../../../../models/Employee';
import { formatLocalTime } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';

import imageUser from '../../../../../assets/images/avatar.png';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

interface NewProps {
	Container?: any
}

interface TodayInformationModel{
	points: any[],
	scale?: {times: string[], isWindowLunch: boolean, minutesLunch: number | null},
	employee: Employee,
	employeeName: string
}

const New = ({Container='div'}: NewProps) => {

	const URL_MICRO_FRONT = process.env.REACT_APP_URL;

	const [lat, setLat] = useState<number>();
	const [lng, setLng] = useState<number>();
	const [locationStatus, setLocationStatus] = useState<string>();
	const [time, setTime] = useState<string>();
	const [pointRegistrationsStatus, setPointRegistrationsStatus] = useState<string>('waiting');
	const [todayInformation, setTodayInformation] = useState<TodayInformationModel>();
	const [showModalPointRegistration, setShowModalPointRegistration] = useState<boolean>(false);
	const [showModalPointRegistrationMessage, setShowModalPointRegistrationMessage] = useState<string>();
	const [pointRegistrationNewStatus, setPointRegistrationNewStatus] = useState<string>('waiting');

	useEffect(() => {
		(async () => {
			setPointRegistrationsStatus('waiting');
			try{
				const jsonReturned =  await fetchMyPointRegistrationsTodayInformation();
				if(jsonReturned){
					setPointRegistrationsStatus('success');
					setTodayInformation(jsonReturned);
				}else{
					setPointRegistrationsStatus('error');
				}
			}catch(error){
				console.log(error);
			}

		})();
	},[]);

	const createNew = async () => {
		setShowModalPointRegistration(true);
		setPointRegistrationNewStatus('waiting');
		try{
			const jsonReturned =  await postMyPointRegistration({ time: time, lat: lat, lng: lng, datePoint:  new Date().toISOString().split('T')[0]});
			if(jsonReturned){
				if(jsonReturned.status==='ERROR'){
					setShowModalPointRegistrationMessage(jsonReturned.message);
					setPointRegistrationNewStatus('error');
				}else{
					setShowModalPointRegistrationMessage(t('CABLE.POINT_REGISTRATION.TEXT.TIME_CLOCK_SUCCESSFULLY'));
					setPointRegistrationNewStatus('success');

					//Adiciona a lista de ponto o novo cadastrado
					let points = [...todayInformation?.points!];
					points.push(jsonReturned.data.point);
					setTodayInformation({...todayInformation!, points: points})
				}
				//setClosedPeriods(jsonReturned.closedPeriods);
			}else{

			}
			
		}catch(error){
			console.log(error);
		}

	}

	return(
		<Container className={styles['point-registration-my-new-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('GENERIC.TEXT.POINT_REGISTRATION')} />
				</Row.Item>
			</Row>

			{(!todayInformation && pointRegistrationsStatus === 'waiting' && locationStatus==='success') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING')} />
						</Panel>
					</Row.Item>
				</Row>
			}
			{(!todayInformation && pointRegistrationsStatus === 'error'  && locationStatus==='success') && 
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<TextInformation 
								icon="exclamation-circle"
								text={t('GENERIC.TEXT.UNABLE_RETURN')} />
						</Panel>
					</Row.Item>
				</Row>
			}

			{!lat && !lng && (
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Panel>
							<GeoLocation handleGeoLocation={(event: any) => {
								if(event.name==='retrive_location'){
									setLat(event.lat); 
									setLng(event.lng);
									setLocationStatus('success');
								}
								if(event.name==='location'){
									setLocationStatus('location');
								}
								}} />
						</Panel>
					</Row.Item>
				</Row>
			)}
			{lat && lng && locationStatus==='success' && todayInformation && pointRegistrationsStatus === 'success' && (
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Panel>
							<Container>
								<MapView lng={lng} 
										lat={lat} 
										height='250px' 
										id='point-registration-my-new'
										/> 
								<Container className={styles['point-registration-details']}>
									<Container className={styles['point-registration-avatar']}>
										<Avatar size='l' image={`${URL_MICRO_FRONT}${imageUser}`} />
									</Container>
									<Container className={styles['point-registration-employee']}>{todayInformation?.employeeName}</Container>
									<Container className={styles['point-registration-planned']}>
										<span style={{fontWeight: 'bold'}}>{t('CABLE.POINT_REGISTRATION.TEXT.PLANNED_SCALE')}: </span> 
										<span>{todayInformation.scale?.times?.map((time, index) => time.substring(0,5)+ (todayInformation.scale!.times.length-1===index?'' :' - '))}</span></Container>
									<Container className={styles['point-registration-realized']}>
										<span style={{fontWeight: 'bold'}}>{t('CABLE.POINT_REGISTRATION.TEXT.PERFORMED')}: </span>
										{todayInformation.points.map((point, index) => (
											<span key={point.id}>{formatLocalTime(point.time)} {(todayInformation.points.length-1===index?'' :' - ') } </span>
										))}
									</Container>
									<Container className={styles['point-registration-clock']}>
										<Clock white big showIcon clockChange={(event) => {setTime(event.value)}} />
									</Container>
									<Container className={styles['point-registration-action']}>
										<Button big title={t('CABLE.POINT_REGISTRATION.TEXT.REGISTER_TIMECLOCK')}
											primary className={styles['button-register']}
											action={() => createNew()}/>
									</Container>
								</Container>
								<Container className={styles['point-registration-last-registers']}>
									<h3>{t('CABLE.POINT_REGISTRATION.TEXT.LATEST_RECORDS')}</h3>
									<Container className={styles['points-last']}>
										{todayInformation.points.map(point => (
											<PointRegistrationDetail key={point.id} time={formatLocalTime(point.time)} lng={point.lng} lat={point.lat} withinArea={point.area}/>
										))}
										{todayInformation.points.length===0 && <h4>{t('CABLE.POINT_REGISTRATION.TEXT.NO_RECORDS')}</h4>}
			
									</Container>
								</Container>
							</Container>
						</Panel>
					</Row.Item>
				</Row>
			)}

			{ showModalPointRegistration &&
                <Modal title={t('CABLE.POINT_REGISTRATION.TEXT.REGISTERING_RECORDS')} xs >
                    <Container className={styles['modal-new-point']}>
						<Animate>
							{pointRegistrationNewStatus === 'waiting' &&
								<Loading text={t('CABLE.POINT_REGISTRATION.TEXT.RECORD_BEING_REGISTERED')} />
							}
							{pointRegistrationNewStatus === 'success' &&
								<TextInformation 
									icon="check"
									iconColor='#3AA76D'
									text={showModalPointRegistrationMessage!} />
							}
							{pointRegistrationNewStatus === 'error' &&
								<TextInformation 
									icon="times"
									iconColor='#D4644D'
									text={showModalPointRegistrationMessage!} />
								
							}

							{(pointRegistrationNewStatus === 'error' || pointRegistrationNewStatus==='success') &&
								<Button primary md
									title="Ok"
									className={styles['modal-point-registration-action']}
									action={() => { setShowModalPointRegistration(false) }} />
							}
						</Animate>
                    </Container>                   
                </Modal>
            }
		</Container>
	);
}

export default New;