import API, { formatDataToFormData } from './core';

export const fetchAllImportFiles = async (params?: any) => {
	try{
		return API.get('/rest/points/importFiles', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const downloadFile = async (id: string, params?: any) => {
	try{
		return API.get(`/rest/points/importFiles/${id}/download`, {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const postImportFile = async (file: File, importType: string) => {
	try{
		return API.post('rest/points/importFiles/upload', formatDataToFormData({file,importType}), {headers: {'Content-Type': 'multipart/form-data'}})
		.then(
			response => {
				return response.data;
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

