import API, { HEADER_JSON } from './core';

export const fetchAllPointEvents = async (params?: any) => {
	try{
		return API.get('/rest/points/pointEvents', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchPointEvent = async (id: string, params?: any) => {
	try{
		return API.get(`/rest/points/pointEvents/${id}`, {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchAllPointEventTypes = async (params?: any) => {
	try{
		return API.get('/rest/points/pointEvents/getTypes', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const postPointEvent = async (params?: any) => {
	try{
		return API.post('/rest/points/pointEvents', JSON.stringify(params), HEADER_JSON)
		.then(
			response => {
                if(response.data.status === 'ERROR'){
                    return null
                }else{ 
                    return response.data;
                }
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const putPointEvent = async (id:string, data:any) => {
	try{
		return API.put(`/rest/points/pointEvents/${id}`, JSON.stringify(data), {headers: {'Content-Type': 'application/json'}})
			.then(
				response => {
					if(response.data.status === 'ERROR'){
						return null
					}else{ 
						return response.data;
					}
					
				}, error => {
					console.log(error);
					return null
				}
			);
	}catch(error){
		return error;
	}
}

export const downloadReportCsv = async (data: any, params: any) => {
    try {
        return API.post('/rest/points/pointEvents/downloadReportCsv', JSON.stringify(data), { params: params, ...HEADER_JSON, responseType: 'arraybuffer' })
            .then(
                response => {
                    if (String(response.data.status).toLowerCase() === 'error') {
                        return null;
                    } else {
                        return response.data;
                    }
                }, error => {
                    console.log(error);
                    return null;
                }
            );
    } catch (error) {
        return error;
    }
};

