import API, { formatDataToFormData } from './core';

export const fetchAllPointLimitAreas = async (params?: any) => {
	try{
		return API.get('/rest/points/pointLimitAreas', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const deleteLimitArea = async (id: string) => {
	try{
		return API.delete(`/rest/points/pointLimitAreas/${id}`, {})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchAllEmployeeAvailable = async (params?: any) => {
	try{
		return API.get('/rest/points/pointLimitAreas/employeeAvailable', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const postPointLimitArea = async (params?: any) => {
	try{
		return API.post('/rest/points/pointLimitAreas', JSON.stringify(params), {headers: {'Content-Type': 'application/json'}})
		.then(
			response => {
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const putPointLimitArea = async (id: string, params?: any) => {
	try{
		return API.put(`/rest/points/pointLimitAreas/${id}`, JSON.stringify(params), {headers: {'Content-Type': 'application/json'}})
		.then(
			response => {
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchPointLimitAreaById = async (id:string) => {
	try{
		return API.get(`/rest/points/pointLimitAreas/${id}`)
			.then(
				response => {
					if(response.data.status === 'ERROR'){
						return null
					}else{ 
						return response.data.data;
					}
					
				}, error => {
					console.log(error);
					return null
				}
			);
	}catch(error){
		return error;
	}
}
