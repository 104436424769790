import API, { formatDataToFormData } from './core';

export const fetchSrepParams = async (params?:any) => {
	try{
		return API.get('/rest/points/srepParams', {params})
			.then(
				response => {
					if(response.data.status === 'ERROR'){
						return null
					}else{ 
						return response.data.data;
					}
					
				}, error => {
					console.log(error);
					return null
				}
			);
	}catch(error){
		return error;
	}
}
export const fetchSrepParamsById = async (id:string) => {
	try{
		return API.get(`/rest/points/srepParams/${id}`)
			.then(
				response => {
					if(response.data.status === 'ERROR'){
						return null
					}else{ 
						return response.data.data;
					}
					
				}, error => {
					console.log(error);
					return null
				}
			);
	}catch(error){
		return error;
	}
}
export const postNewSrepParams = async (params:any) => {
	try{
	return API.post('/rest/points/srepParams', formatDataToFormData(params))
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}
export const postUpdateSrepParams = async (id:string, data:any) => {
	try{
		return API.post(`/rest/points/srepParams/${id}`, JSON.stringify(data), {headers: {'Content-Type': 'application/json'}})
			.then(
				response => {
					if(response.data.status === 'ERROR'){
						return null
					}else{ 
						return response.data;
					}
					
				}, error => {
					console.log(error);
					return null
				}
			);
	}catch(error){
		return error;
	}
}
export const deleteSrepParams = async (id:string) => {
	try{
		return API.delete(`/rest/points/srepParams/${id}`, {})
			.then(
				response => {
					if(response.data.status === 'ERROR'){
						return null
					}else{ 
						return response.data;
					}
					
				}, error => {
					console.log(error);
					return null
				}
			);
	}catch(error){
		return error;
	}
}
export const postNewSrepSplitHour = async (params:any) => {
	try{
		return API.post('/rest/points/srepSplitHours', formatDataToFormData(params))
			.then(
				response => {
					if(response.data.status === 'ERROR'){
						return null
					}else{ 
						return response.data;
					}
					
				}, error => {
					console.log(error);
					return null
				}
			);
	}catch(error){
		return error;
	}
}
export const deleteSrepSplitHour = async (id:string) => {
	try{
		return API.delete(`/rest/points/srepSplitHours/${id}`, {})
			.then(
				response => {
					if(response.data.status === 'ERROR'){
						return null
					}else{ 
						return response.data;
					}
					
				}, error => {
					console.log(error);
					return null
				}
			);
	}catch(error){
		return error;
	}
}