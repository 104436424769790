import React from 'react';
import { Route, HashRouter as Router, Routes } from 'react-router-dom';
import { createHashHistory, HashHistory } from 'history';

import Dashboard from './components/pages/Dashboard/Dashboard';
import {default as SrepConfigurationList} from './components/pages/SrepConfiguration/List/List';
import {default as SrepConfigurationEdit} from './components/pages/SrepConfiguration/Edit/Edit';
import {default as SrepConfigurationNew} from './components/pages/SrepConfiguration/New/New';

import {default as HourBankBalanceList} from './components/pages/HourBankBalance/List/List';
import {default as HourBankBalanceMyList} from './components/pages/HourBankBalance/My/List/List';

import {default as PointRegistrationList} from './components/pages/PointRegistration/List/List';
import {default as PointRegistrationMyList} from './components/pages/PointRegistration/My/List/List';
import {default as PointRegistrationMyNew} from './components/pages/PointRegistration/My/New/New';

import {default as ImportFileList} from './components/pages/ImportFile/List/List';

import {default as ClosedPeriodList} from './components/pages/ClosedPeriod/List/List';
import {default as ClosedPeriodNew} from './components/pages/ClosedPeriod/New/New';
import {default as ClosedPeriodEdit} from './components/pages/ClosedPeriod/Edit/Edit';

import {default as LimitAreaList} from './components/pages/LimitArea/List/List';
import {default as LimitAreaNew} from './components/pages/LimitArea/New/New';
import {default as LimitAreaEdit} from './components/pages/LimitArea/Edit/Edit';

import {default as IncidentList} from './components/pages/PointIncident/List/List';
import {default as IncidentMyList} from './components/pages/PointIncident/My/List/List';

import {default as ExportPointFile} from './components/pages/ExportFile/ExportFile';

import {default as ReportList} from './components/pages/Report/List/List';

import {default as PointEventList} from './components/pages/PointEvent/List/List';
import {default as PointEventNewEdit} from './components/pages/PointEvent/NewEdit/NewEdit';

const defaultHistory = createHashHistory();

interface ApplicationRouterProps {
	history : HashHistory
}

const ApplicationRouter = ({ history = defaultHistory }: ApplicationRouterProps) => {
    return(
        <Router >
			<Routes>
				<Route path="/srepsMF" element={<Dashboard />} /> 
					
				<Route path="/srepsMF/settings"  element={<SrepConfigurationList />} /> 
					
				<Route path="/srepsMF/settings/:id/edit" element={<SrepConfigurationEdit />} /> 
					
				<Route path="/srepsMF/settings/new" element={<SrepConfigurationNew />} /> 
					
				<Route path="/srepsMF/hourBankBalances" element={<HourBankBalanceList />} /> 
					
				<Route path="/srepsMF/hourBankBalances/my" element={<HourBankBalanceMyList /> } /> 

				<Route path="/srepsMF/closedPeriods" element={<ClosedPeriodList />} /> 
				<Route path="/srepsMF/closedPeriods/new" element={<ClosedPeriodNew /> } /> 
				<Route path="/srepsMF/closedPeriods/:id/edit" element={<ClosedPeriodEdit /> } /> 

				<Route path="/srepsMF/pointRegistrations" element={<PointRegistrationList /> } /> 
				<Route path="/srepsMF/pointRegistrations/my" element={<PointRegistrationMyList />} /> 
				<Route path="/srepsMF/pointRegistrations/my/new" element={<PointRegistrationMyNew />} /> 

				<Route path="/srepsMF/import" element={<ImportFileList />} /> 

				<Route path="/srepsMF/limitAreas" element={<LimitAreaList />} /> 
				<Route path="/srepsMF/limitAreas/:id/edit" element={<LimitAreaEdit />} /> 
				<Route path="/srepsMF/limitAreas/new" element={<LimitAreaNew /> } /> 

				<Route path="/srepsMF/incidents" element={<IncidentList /> } /> 
				<Route path="/srepsMF/incidents/my" element={<IncidentMyList />} /> 

				<Route path="/srepsMF/export" element={<ExportPointFile />} /> 

				<Route path="/srepsMF/reports" element={<ReportList /> } /> 

                <Route path="/srepsMF/pointEvents" element={<PointEventList /> } /> 
                <Route path="/srepsMF/pointEvents/new" element={<PointEventNewEdit /> } /> 
                <Route path="/srepsMF/pointEvents/:id" element={<PointEventNewEdit /> } /> 

				{process.env.NODE_ENV === 'development' &&
					<Route path="/auth" element={<>Auth</>} /> 
				}
			</Routes>
        </Router>
    );
}

export default ApplicationRouter;