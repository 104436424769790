import API, { formatDataToFormData } from './core';

const DEFAULT_POINT_INCIDENT_URL = '/rest/points/pointIncidents';

export const fetchAllPointIncidents = async (params?: any) => {
	try{
		return API.get('/rest/points/pointIncidents', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchAllMyPointIncidents = async (params?: any) => {
	try{
		return API.get('/rest/points/pointIncidents/my', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const postPointIncidentJustification = async (params?: any) => {
	try{
		return API.post('/rest/points/pointIncidents/newJustification', formatDataToFormData(params))
		.then(
			response => {
				return response.data;
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
};

export const postPointIncidentJustificationValidate = async (params?: any) => {
	try{
		return API.post('/rest/points/pointIncidents/newJustification/validate', formatDataToFormData(params))
		.then(
			response => {
				return response.data;
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
};

export const postPointIncidentApproveOrReject = async (params?: any) => {
	try{
		return API.post('/rest/points/pointIncidents/approve', formatDataToFormData(params))
		.then(
			response => {
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchExportFileCSV = async (params: any) => {
	try{
		return API.get('/rest/points/pointIncidents/exportCSV', { params: params, headers: { 'Content-Type': 'application/json' }, 
		responseType: 'arraybuffer' })
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const fetchDataToBuildPointIncident = async (params?: any) => {
	try {
		return API.get(`${DEFAULT_POINT_INCIDENT_URL}/getDataToBuildPointIncident`, { params })
			.then(
				response => {
					return response.data;

				}, error => {
					console.log(error);
					return null;
				}
			);

	} catch (error) {
		console.log(error);
		return null;
	}
};

export const postCreateByEmployee = async (params?: any) => {
	try {
		return API.post('/rest/points/pointIncidents/createByEmployee', formatDataToFormData(params))
			.then(
				response => {
					return response.data;

				}, error => {
					console.log(error);
					return null
				}
			);
	} catch (error) {
		return error;
	}
};
