import API, { formatDataToFormData } from './core';

export const fetchHourBankBalances = async (params: any) => {
	try{
		return API.get('/rest/points/hourBankBalances', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}
export const downloadCsvHourBankBalances = async (params: any) => {
	try{
		return API.get('/rest/points/hourBankBalances/downloadReportCsvHourBankBalances', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}
export const fetchMyHourBankBalances = async (params?: any) => {
	 try{
		return API.get('/rest/points/hourBankBalances/my', {params})
		.then(
			response => {
				if(response.data.status === 'ERROR'){
					return null
				}else{ 
					return response.data.data;
				}
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}
export const putHourBankBalance = async (id:string, params?: any) => {
	try{
		return API.put(`/rest/points/hourBankBalances/${id}`, JSON.stringify(params), {headers: {'Content-Type': 'application/json'}})
		.then(
			response => {
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}
