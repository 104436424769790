import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Button, Loading, Message, PageTitle, Panel, Row, Select, Table, TextInformation, Time } from "@optimuminterfaces/revex-react-components/dist/components";
import PointRegistrationDetail from "../../../../organism/PointRegistration/Detail/Detail";
import { convertMinutesToTimeStr, formatLocalDate, formatLocalTime } from "@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil";
import { convertFloatToTimeStr } from '../../../../../utils/DateTimeUtil';

import { fetchMyPointRegistrations } from "../../../../../services/pointRegistrations.services";
import { calculateSelectMonth, calculateSelectYear } from "../../../../../utils/SelectUtil";

import { getEnvironment } from "../../../../../services/auth.services";

import { PointRegistrationCalculate } from "../../../../../models/PointRegistrationCalculate";

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

import styles from './List.module.scss';
interface PointIncidentModel{
	id: string
}

interface ListProps{
	Container?: any
}

const List = ({Container='div'} : ListProps) => {

	const years = calculateSelectYear();
	const months = calculateSelectMonth();

	let query = new URLSearchParams(useLocation().search);
	let year = query.get("year");
	let month = query.get("month");

	const [filters, setFilters] = useState<{
									year: string, 
									month: string
								}>();
	const [pointRegistrationsStatus,setPointRegistrationsStatus] = useState<string>('wainting');
	const [pointRegistrations,setPointRegistrations] = useState<PointRegistrationCalculate[]>();
	const [listYears, setListYears] = useState<any[]>(years);
	const [listMonths, setListMonths] = useState<any[]>(months);
	const [listEmployees, setListEmployees] = useState<any[]>([]);
	const [monthValue, setMonthValue] = useState<any>(months[new Date().getMonth()]);
	const [yearValue, setYearValue] = useState<any>(years[3]);

	const [showModalEditPoint, setShowModalEditPoint] = useState<boolean>(false);

	useEffect(() => {
		if(year && month){
			setFilters({year, month});
			months.forEach(m => {
				if(m.key===month){
					setMonthValue(m);
				}
			})
		}else{
			setFilters({year: yearValue.key, month: monthValue.key});
		}
	},[])
	
	useEffect(() => {
		(async () => {
			if(filters){
				setPointRegistrationsStatus('waiting');

				try{
					const jsonReturned =  await fetchMyPointRegistrations(filters);

					if(jsonReturned){
						setPointRegistrationsStatus('success');
						setPointRegistrations(jsonReturned.pointRegistrations);
					}else{
						setPointRegistrationsStatus('error');
					}
				}catch(error){
					console.log(error);
				}
			}

		})();
	},[filters]);

	const changeSelectMonth = (key: string) => {
        let months = [...listMonths];

        for (var i = 0; i < months.length; i++) {
            if (months[i].key === key) {
                months[i].selected = true;
                setMonthValue(months[i]);
            } else {
                months[i].selected = false;
            }
        }

        setListMonths(months);

    }

	const changeSelectYear = (key: string) => {
        let years = [...listYears];

        for (var i = 0; i < years.length; i++) {
            if (years[i].key === key) {
                years[i].selected = true;
                setYearValue(years[i]);
            } else {
                years[i].selected = false;
            }
        }

        setListYears(years);

    }

	const updateFilters = () => {
		setFilters({month: monthValue.key, year: yearValue.key});
	}

	const renderTablePointRegistration = () => {
		return (
			<Table primary className={styles['point-registration-table']} striped>
				<Table.Header>
					<Table.HeaderItem>
						<Table.HeaderItem.Item>{t('GENERIC.TEXT.DATE')}</Table.HeaderItem.Item>
						<Table.HeaderItem.Item>{t('EMPLOYEE.TEXT.CURRENT_SCALE')}</Table.HeaderItem.Item>
						<Table.HeaderItem.Item>{t('CABLE.POINT_REGISTRATION.TEXT.RECORDS')}</Table.HeaderItem.Item>
						<Table.HeaderItem.Item>{t('CABLE.POINT_REGISTRATION.TEXT.TIME_SHEET')}</Table.HeaderItem.Item>
					</Table.HeaderItem>
				</Table.Header>
				<Table.Body>
					{pointRegistrations?.map((point,index) => {
						return(
							<Table.BodyItem key={index+point.datePoint}>
								<Table.BodyItem.Item>{formatLocalDate(point.datePoint,true, 'YYYY-MM-DD', undefined)} - {point.datePointWeekDay.toUpperCase()}</Table.BodyItem.Item>
								<Table.BodyItem.Item>
									{renderDailyScale(point.scales.times, point.scales.isWindowLunch, point.scales.minutesLunch, point.scales.isAbsence)}
								</Table.BodyItem.Item>
								<Table.BodyItem.Item>
									{renderPoint(point.points, point.datePoint)}
								</Table.BodyItem.Item>
								
								<Table.BodyItem.Item>
									{renderPointMirror(point, false)}
								</Table.BodyItem.Item>
							</Table.BodyItem>
						)
					})}
					
				</Table.Body>
			</Table>
		);
	}
	const renderDailyScale = (scales:any[], isWindowLunch: boolean, minutesLunch: number, isAbsence: boolean) => {
		if(isAbsence){
			return <Container><Message message={t('CABLE.POINT_REGISTRATION.TEXT.EMP_ABSENCE')} warning /> </Container>
		}else{
			if(scales.length===0){
				return <Container><Message message={t('SCALE.TEXT.LEGEND.DAY_OFF')} danger /> </Container>
			}else if(scales.length===1 && scales[0] ==='?'){
				return <Container><Message message={t('SCALE.TEXT.LEGEND.NO_SCALE')} /> </Container>
			}else{
				return scales.map((scale,index) => {
					return <Container key={index}>
						{index===1 && isWindowLunch === true && <span className={styles['point-window-title']}>Janela de Intervalo</span>}
						<Time primary time={scale.substring(0,5)} className={styles[index === 1|| index === 2?isWindowLunch?'point-window': 'point':'point']}/>
						{index===1 && isWindowLunch === true && <Time className={`${styles['point-window']} ${styles['point-window-duration']}`} time={convertMinutesToTimeStr(minutesLunch)} /> }
						{index===2 && isWindowLunch === true && <span className={styles['point-window-title']}>Janela de Intervalo</span>}
					</Container>
				});
			}
		}
	}
	const renderPoint = (points:any[], pointDate?: any) => {
		return (
			<Container className={styles['point-realized-container']}>
				{points?.filter(point => point.isDeleted===false).map(point => {
					//return <Time key={point.id} time={point.time.substring(0,5)} warning />
					return <PointRegistrationDetail key={point.id} 
										time={formatLocalTime(point.time)} 
										lng={point.lng} 
										lat={point.lat} 
										withinArea={point.area}
										className='point-detail'/>
				})}			
			</Container>
		)
	}

	const renderPointMirror = (pointRegistration: PointRegistrationCalculate, simplified: boolean) => {
		return (
			<dl className={`${styles['point-mirror']} ${!simplified ? styles['striped']:''} `} key={pointRegistration.datePoint+ pointRegistration.workTime}>
				{/* NEW */}
				<dt>{pointRegistration.pointCalculate['SYSTEM_JOURNEY']['name']}:</dt>
				<dd>{pointRegistration.pointCalculate['SYSTEM_JOURNEY']['value'] !== 0?
						convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_JOURNEY']['value']):'-'}
				</dd>
				<dt>{pointRegistration.pointCalculate['SYSTEM_WORKING_HOURS']['name']}:</dt>
				<dd>{pointRegistration.pointCalculate['SYSTEM_WORKING_HOURS']['value'] !== 0?
						convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_WORKING_HOURS']['value']):'-'}
				</dd>
				<dt>{pointRegistration.pointCalculate['SYSTEM_LATE_WORK']['name']}:</dt>
				<dd>{pointRegistration.pointCalculate['SYSTEM_LATE_WORK']['value'] !== 0?
						convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_LATE_WORK']['value']):'-'}
				</dd>
				<dt>{pointRegistration.pointCalculate['SYSTEM_OVERTIME']['name']}:</dt>
				<dd>{pointRegistration.pointCalculate['SYSTEM_OVERTIME']['value'] !== 0?
						convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_OVERTIME']['value']):'-'}
				</dd>
				{(simplified && pointRegistration.database && pointRegistration.database !== null ) &&
				<>
					<dt>{pointRegistration.pointCalculate['SYSTEM_COMPENSATION_TIME']['name']}:</dt>
					<dd>{pointRegistration.pointCalculate['SYSTEM_COMPENSATION_TIME']['type']==='positive'?'+':'-'} 
							{convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_COMPENSATION_TIME']['value'])}
					</dd>
				</>
				}
				{!simplified && 
					<>
						<dt>{pointRegistration.pointCalculate['SYSTEM_HOURS_INTRAJOURNEY']['name']}:</dt>
						<dd>{pointRegistration.pointCalculate['SYSTEM_HOURS_INTRAJOURNEY']['value'] !== 0?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_HOURS_INTRAJOURNEY']['value']):'-'}
						</dd>
						<dt>{pointRegistration.pointCalculate['SYSTEM_ALLOWANCE']['name']}:</dt>
						<dd>{pointRegistration.pointCalculate['SYSTEM_ALLOWANCE']['value'] !== 0?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_ALLOWANCE']['value']):'-'}
						</dd>
						<dt>{pointRegistration.pointCalculate['SYSTEM_DECREMENT_DATABASE']['name']}:</dt>
						<dd>{pointRegistration.pointCalculate['SYSTEM_DECREMENT_DATABASE']['value'] !== 0?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_DECREMENT_DATABASE']['value']):'-'}
						</dd>
						<dt>{pointRegistration.pointCalculate['SYSTEM_INCREMENT_DATABASE']['name']}:</dt>
						<dd>{pointRegistration.pointCalculate['SYSTEM_INCREMENT_DATABASE']['value'] !== 0?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_INCREMENT_DATABASE']['value']):'-'}
						</dd>
						<dt>{pointRegistration.pointCalculate['SYSTEM_PAYMENT']['name']}:</dt>
						<dd>{pointRegistration.pointCalculate['SYSTEM_PAYMENT']['value'] !== 0?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_PAYMENT']['value']):'-'}
						</dd>

						<dt>{pointRegistration.pointCalculate['SYSTEM_DISCOUNT']['name']}:</dt>
						<dd>{pointRegistration.pointCalculate['SYSTEM_DISCOUNT']['value'] !== 0?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_DISCOUNT']['value']):'-'}
						</dd>

						<dt>{pointRegistration.pointCalculate['SYSTEM_HOLIDAY']['name']}:</dt>
						<dd>{
								pointRegistration.pointCalculate['SYSTEM_HOLIDAY']['value']===true?t('GENERIC.TEXT.YES'):t('GENERIC.TEXT.NO')
							}
						</dd>

						<dt>{pointRegistration.pointCalculate['SYSTEM_OVERTIME_HOLIDAY']['name']}:</dt>
						<dd>{pointRegistration.pointCalculate['SYSTEM_OVERTIME_HOLIDAY']['value'] !== 0?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_OVERTIME_HOLIDAY']['value']):'-'}
						</dd>
						<dt>{pointRegistration.pointCalculate['SYSTEM_OVERTIME_OFF']['name']}:</dt>
						<dd>{pointRegistration.pointCalculate['SYSTEM_OVERTIME_OFF']['value'] !== 0?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_OVERTIME_OFF']['value']):'-'}
						</dd>
						<dt>{pointRegistration.pointCalculate['SYSTEM_LATE']['name']}:</dt>
						<dd>{pointRegistration.pointCalculate['SYSTEM_LATE']['value'] !== 0?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_LATE']['value']):'-'}
						</dd>

						{getEnvironment().country==='BR' && 
							<>
							<dt>{pointRegistration.pointCalculate['SYSTEM_DSR']['name']}:</dt>
							<dd style={{ overflowX: 'auto'}}>{pointRegistration.pointCalculate['SYSTEM_DSR']['value'] === true ?
								`${t('GENERIC.TEXT.YES')} 
											(${formatLocalDate(pointRegistration.pointCalculate['SYSTEM_DSR']['startDate'], true, 'YYYY-MM-DD', 'DD/MM/YY')} - 
											${formatLocalDate(pointRegistration.pointCalculate['SYSTEM_DSR']['endDate'], true, 'YYYY-MM-DD', 'DD/MM/YY')})`
								: t('GENERIC.TEXT.NO')}
							</dd>
							</>
						}
						<dt>{pointRegistration.pointCalculate['SYSTEM_OVERTIME_INTRAJOURNEY']['name']}:</dt>
						<dd>{pointRegistration.pointCalculate['SYSTEM_OVERTIME_INTRAJOURNEY']['values'] !== 0?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_OVERTIME_INTRAJOURNEY']['value']):'-'}
						</dd>
						<dt>{pointRegistration.pointCalculate['SYSTEM_NIGHT']['name']}:</dt>
						<dd>{pointRegistration.pointCalculate['SYSTEM_NIGHT']['value'] !== 0?
								convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_NIGHT']['value']):'-'}
						</dd>
						<dt>{pointRegistration.pointCalculate['SYSTEM_ADDITIONL_NIGHT']['name']}:</dt>
						<dd>{pointRegistration.pointCalculate['SYSTEM_ADDITIONL_NIGHT']['value'] !== 0?
								convertFloatToTimeStr(pointRegistration.pointCalculate['SYSTEM_ADDITIONL_NIGHT']['value']):'-'}
						</dd>

						{
							pointRegistration.pointCalculate['SYSTEM_OVERTIME']['splitHours'].map((splitHour:any,index:number) => (
								<Container key={index}>
									<dt>{splitHour.typeTranslate}{splitHour.division !== null ? ' ' + splitHour.division + '%' : ''}:</dt>
									<dd>{splitHour.value !== 0 ? convertMinutesToTimeStr(splitHour.value) : '-'}</dd>
								</Container>
							))
						}

						{
							pointRegistration.database && pointRegistration.database !== null && (
								<>
									<dt>{pointRegistration.pointCalculate['SYSTEM_COMPENSATION_TIME']['name']}:</dt>
									<dd>{pointRegistration.pointCalculate['SYSTEM_COMPENSATION_TIME']['type']==='positive'?'+':'-'} 
									{convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_COMPENSATION_TIME']['value'])}</dd>

									<dt>{pointRegistration.pointCalculate['SYSTEM_COMPENSATION_TIME_BALANCE']['name']}:</dt>
									<dd>{pointRegistration.pointCalculate['SYSTEM_COMPENSATION_TIME_BALANCE']['type']==='positive'?'+':'-'} 
									{convertMinutesToTimeStr(pointRegistration.pointCalculate['SYSTEM_COMPENSATION_TIME_BALANCE']['value'])}</dd>
								</>
							)
						}
					</>
				}
			</dl>
		);
	};

	return (
		<Container className={styles['point-registration-my-list-rt']}>
			<Row>
				<Row.Item sm={12} md={12} lg={12}>
					<PageTitle.Small title={t('CABLE.POINT_REGISTRATION.TEXT.ATTENDANCE_CONTROL')}/>
				</Row.Item>
			</Row>
			{pointRegistrationsStatus === 'opening' &&
				<Row>
					<Row.Item lg={12} md={12} sm={12}>
						<Panel>
							<Loading text={t('GENERIC.TEXT.LOADING')}/>
						</Panel>
					</Row.Item>
				</Row>
			}
			{pointRegistrationsStatus !== 'opening' &&
			
			<>
				<Row>
					<Row.Item sm={12} md={12} lg={12}>
						<Panel title={t('GENERIC.TEXT.FILTERS')} className={styles['point-registration-filters']} noTitleBorder>
							<Row>
								<Row.Item sm={3} md={3} lg={3} offsets={{sm:4, md: 4, lg: 4 }}>
									<Select 
										title={t('GENERIC.TEXT.PERIOD')} 
										value={monthValue.value}
										options={listMonths} 
										name='period' 
										handleChange={changeSelectMonth} 
										fullWidth/>
								</Row.Item>
								<Row.Item sm={3} md={3} lg={3}>
									<Select 
										title={t('GENERIC.TEXT.YEAR')} 
										value={yearValue.value}
										options={listYears}
										name='year' 
										handleChange={changeSelectYear} 
										fullWidth/>
								</Row.Item>
								<Row.Item sm={2} md={2} lg={2} >
									<Button 
										title={t('GENERIC.BUTTON.SEARCH.TEXT')} 
										action={() => {
											updateFilters()
										}}
										primary/>
								</Row.Item>
							</Row>
						</Panel>
					</Row.Item>
				</Row>

				{(pointRegistrationsStatus === 'waiting') && 
					<Row>
						<Row.Item lg={12} md={12} sm={12}>
							<Panel>
								<Loading text={t('CABLE.POINT_REGISTRATION.TEXT.SEEKING_INFORMATION')}/>
							</Panel>
						</Row.Item>
					</Row>
				}
				{(pointRegistrationsStatus === 'error') && 
					<Row>
						<Row.Item lg={12} md={12} sm={12}>
							<Panel>
								<TextInformation 
									icon="exclamation-circle"
									text={t('GENERIC.TEXT.UNABLE_RETURN')} />
							</Panel>
						</Row.Item>
					</Row>
				}
				{pointRegistrations && pointRegistrationsStatus === 'success' &&
					<Row>
						<Row.Item sm={12} md={12} lg={12}>
							<Panel title={t('CABLE.POINT_REGISTRATION.TEXT.TIME_RECORDS')}noPadding noTitleBorder>
								<>
									{renderTablePointRegistration()}
								</>
							</Panel>
						</Row.Item>
					</Row>
				}
			</>
			}
		</Container>
	)
}

export default List;