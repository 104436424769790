import API, { formatDataToFormData } from './core';


export const postPointRegistrationEvent = async (params?: any) => {
	try{
		return API.post('/rest/points/pointRegistrationEvents', JSON.stringify(params))
		.then(
			response => {
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}

export const deletePointRegistrationEvent = async (id:string) => {
	try{
		return API.delete(`/rest/points/pointRegistrationEvents/${id}`, {})
			.then(
				response => {
					if(response.data.status === 'ERROR'){
						return null
					}else{ 
						return response.data;
					}
					
				}, error => {
					console.log(error);
					return null
				}
			);
	}catch(error){
		return error;
	}
}

export const postPointRegistrationEventChange = async (params?: any) => {
	try{
		return API.post('/rest/points/pointRegistrationEvents/change', JSON.stringify(params))
		.then(
			response => {
				return response.data;
				
			}, error => {
				console.log(error);
				return null
			}
		);
	}catch(error){
		return error;
	}
}