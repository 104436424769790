import { convertMinutesToTimeStr } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';

export const buildScaleDaily = (dailyTimes: string[], absenceTimes: string[]) => {
	let scaleDaily: { isAbsence: boolean, hour: string }[] = [];

	if (absenceTimes.length > 0) {
		let listDaily: Date[] = buildListHours(dailyTimes);
		let listAbsence: Date[] = buildListHours(absenceTimes);
		let listResult: { isAbsence: boolean, hour: Date }[] = mergeList(listDaily, listAbsence);

		listResult.forEach(d => {
			scaleDaily.push({
				isAbsence: d.isAbsence,
				hour: convertMinutesToTimeStr(d.hour.getHours() * 60 + d.hour.getMinutes())
			})
		});

	} else {
		dailyTimes.forEach(d => {
			scaleDaily.push({
				isAbsence: false,
				hour: d
			});
		});
	}

	return scaleDaily;
};

const buildListHours = (daily: string[]) => {
	var list: Date[] = [];
	var firstHour: Date | null = null;

	if (!!daily && daily.length > 0) {
		daily.forEach(d => {
			let splitHour: string[] = d.split(':');

			let date = new Date();

			date.setHours(Number(splitHour[0]));
			date.setMinutes(Number(splitHour[1]));
			date.setSeconds(0);

			if (firstHour != null && date < firstHour) {
				date.setDate(date.getDate() + 1);
			}

			list.push(date);

			if (firstHour === null) {
				firstHour = date;
			}
		});
	}

	return list;
};

const mergeList = (listDaily: Date[], listAbsence: Date[]) => {
	var listToDelete: Set<number> = new Set();

	var hours: { isAbsence: boolean, hour: Date }[] = [];
	listDaily.forEach(hour => {
		hours.push({
			isAbsence: false,
			hour: hour
		});
	});

	var listResult = hours.slice();
	var firstPosition = 0;
	var lastPosition = hours.length - 1;
	for (var i = 0; i < listAbsence.length; i += 2) {
		// Fim da ausência tem que ser maior que o início da jornada e início da ausência tem que ser menor que o fim da jornada
		if (listAbsence[i + 1] > hours[0].hour && listAbsence[i] < hours[lastPosition].hour) {
			// Primeiro horário da jornada está entre o início e fim da ausência
			if (listAbsence[i] <= hours[0].hour && listAbsence[i + 1] >= hours[0].hour) {
				firstPosition++;
				listResult[0] = { ...listResult[0], isAbsence: true };
				// Adiciona fim da ausência
				listResult.push({
					isAbsence: true,
					hour: listAbsence[i + 1]
				});

				// Último horário da jornada está entre o início e fim da ausência
			} else if (listAbsence[i] <= hours[hours.length - 1].hour && listAbsence[i + 1] >= hours[hours.length - 1].hour) {
				lastPosition--;
				listResult[hours.length - 1] = { ...listResult[hours.length - 1], isAbsence: true };
				// Adiciona início da ausência
				listResult.push({
					isAbsence: true,
					hour: listAbsence[i]
				});

			} else {
				for (var j = firstPosition; j <= lastPosition; j++) {
					// Horário no meio da jornada está entre o início e fim da ausência, sinaliza para remoção ou horário igual ao da ausência
					if ((listAbsence[i] === hours[j].hour) || (listAbsence[i + 1] === hours[j].hour) ||
						(listAbsence[i] <= hours[j].hour && listAbsence[i + 1] >= hours[j].hour)) {
						listToDelete.add(j);
					}
				}

				// Início da ausência menos 1 minuto
				let absenceStart = new Date(listAbsence[i]);
				absenceStart.setMinutes(listAbsence[i].getMinutes() - 1);

				// Adiciona início da ausência, caso última ausência adicionada não termine no início da atual
				if (i === 0 || listAbsence[i - 1].getTime() !== absenceStart.getTime()) {
					listResult.push({
						isAbsence: true,
						hour: listAbsence[i]
					});

					// Remove final da última ausência
				} else {
					listToDelete.add(listResult.length - 1);
				}

				// Adiciona fim da ausência
				listResult.push({
					isAbsence: true,
					hour: listAbsence[i + 1]
				});
			}
		}
	}

	// Deleta horários desnecessários
	var arrayDelete: number[] = Array.from(listToDelete);
	arrayDelete.reverse();
	arrayDelete.forEach(index => listResult.splice(index, 1));

	// Ordena lista
	listResult.sort((a, b) => a.hour > b.hour ? 1 : -1);

	return listResult;
};