import React, { useEffect, useState } from "react";
import { Panel, Input, DateTimePicker, Switch, Tooltip, Select, Button} from '@optimuminterfaces/revex-react-components/dist/components/index';

import styles from './New.module.scss';

import {translation as t} from '@optimuminterfaces/revex-react-components/dist/translation/index';

interface NewProps {
	Container?: any,
	noPanel?: boolean,
	cancelAction?: any,
	saveAction?: any,
	listSrepConfigurations?: any[]
}
const New = ({ Container = 'div', noPanel, cancelAction, saveAction, listSrepConfigurations }: NewProps) => {
	const [list, setList] = useState<{ key: any; value: any; selected: boolean; }[]>();
    const [selectValue, setSelectValue] = useState('');
    const [srepParamId, setSrepParamId] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [dateValue, setDateValue] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [switchStatus, setSwitchStatus] = useState(false);

	useEffect(() => {
        if (!!listSrepConfigurations && listSrepConfigurations.length > 0) {
            let newList: { key: any; value: any; selected: boolean; }[] = [];

            listSrepConfigurations.map(srepParam => {
                newList.push({
                    key: srepParam.id,
                    value: srepParam.name,
                    selected: false
                });
            });

            setList(newList);
        }
    }, []);

    const save = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setButtonLoading(true);
        saveAction(inputValue, dateValue, srepParamId);
    }

    const changeSelect = (key:string) => {
        for (var i = 0; i < list!.length; i++) {
            if (list![i].key === key) {
                list![i].selected = true;
                setSelectValue(list![i].value);
                setSrepParamId(list![i].key);
            } else {
                list![i].selected = false;
            }
        }
    };

    const changeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        validateForm(e.target.value, dateValue);
    };

    const changeDateTimePicker = (e: any) => {
        setDateValue(e);
        validateForm(inputValue, e);
    };

    const validateForm = (input:string, date:any) => {
        if (!!input && !!date && input.length >= 1 && input.length <= 150) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    };

    const updateSwitchStatus = (status:boolean) => {
        setSwitchStatus(status);
        setSelectValue("");
        setSrepParamId(null);
    }

    const renderForm = () => {
        return (
            <form onSubmit={e => e.preventDefault()} className={styles['form-point-new']}>
                <Input
                    title={t('SREP.LIST.CONFIGURATION_NAME')}
                    medium
                    placeholder="Nome"
                    name="name"
                    handleChange={changeInput}
                    fullWidth />

                <DateTimePicker
                    title={t('GENERIC.TEXT.START_DATE')}
                    medium
                    name="startDate"
                    type="date"
					noSeconds
					forceOpenPicker={false}
					timeInverseColor
                    mask
                    //fullWidth
                    handleChange={(e) => changeDateTimePicker(e)} />

                {list && list!.length > 0 &&
                    <Container>
                        <Container className={styles['copy-srep-configuration']}>
                            <Switch
                                value={switchStatus}
                                iconOff="times"
								index
                                name="switchStatus"
                                iconOn="check"
                                colorOn="#3b51ed"
                                handleChange={() => updateSwitchStatus(!switchStatus)}
                                text={t('SREP.LIST.COPY_SETTINGS')}
                                sm />        

                            <Tooltip text={t('SREP.LIST.COPY_SETTINGS_INFO')}/>
                        </Container>

                        {switchStatus && 
                            <Select
                                title={t('GENERIC.TEXT.SETTINGS')}
                                name="Configurações"
                                medium
                                fullWidth
                                value={selectValue}
                                options={list!}
                                selectedItemNameLength={40}
                                handleChange={changeSelect} />
                        }

                    </Container>
                }


                <Container className={styles['form-point-new-actions']}>
                    <Button
                        danger
                        title={t('GENERIC.BUTTON.CANCEL.TEXT')}
                        outlined
						className={styles['btn-rt']}
                        action={(e) => {
                            e.preventDefault();
                            cancelAction();
                        }} />

                    <Button
                        success
                        title={t('GENERIC.TEXT.REGISTER')}
                        disabled={buttonDisabled}
                        loading={buttonLoading}
                        icon="save"
						className={styles['btn-rt']}
                        action={(e) => save(e)} />

                </Container>
            </form>
        );
    }

    return (
        <Container className={styles['page-point-new-rt']}>

            {/* { !noPanel && (<Panel title="Cadastro de Nova Configuração"> {renderForm()} </Panel>)} */}

            { noPanel && renderForm()}

        </Container>
    );
}

export default New;